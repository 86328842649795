import React, { useEffect, useState } from "react";
import { Card, Row, Col, CardFooter, CardImg } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getPlantIdentificationImages } from "../../../redux/actions/plants";
import PaginationComponent from "react-reactstrap-pagination";
import { URL_API } from "../../../path";
import noImage from "../../../assets/img/no-image.jpg";

/*
 * IdentificationImages Component for viewing all existing identification images for a plant
 */
function IdentificationImages(props) {
  const [identificationImages, setIdentificationImages] = useState([
    {
      image: { path: "" },
    },
  ]);
  const [offset, setOffset] = useState(0);
  const [limit] = useState(50);

  /**
   * function that handles selecting a page
   * gets identification images from backend with new offset
   */
  const handlePageSelect = (selectedPage) => {
    setOffset(selectedPage - 1);
    props.getPlantIdentificationImages(props.plantId, selectedPage - 1, limit);
  };

  /**
   * useEffect that gets identification images
   * offset starts at 0 and number of identification images per page is limited is 50
   */
  useEffect(() => {
    props.getPlantIdentificationImages(props.plantId, offset, limit);
  }, []);

  useEffect(() => {
    if (props.identificationImages) {
      setIdentificationImages(props.identificationImages);
    }
  }, [props.identificationImages]);

  return (
    <>
      <div>
        <hr style={{ marginTop: "0" }} />
        <Row>
          {identificationImages.length > 0 &&
            identificationImages.map((plant, index) => {
              return (
                <Col md="3" className="text-right" key={index}>
                  <Card>
                    <Link
                      to={{
                        pathname: `/plants/${props.plantId}/identification-images/${plant._id}`,
                        plant: plant,
                      }}
                    >
                      <CardImg
                        top
                        width="100%"
                        style={{ height: "320px", objectFit: "cover" }}
                        src={
                          plant.hasOwnProperty("image")
                            ? `${URL_API}${plant.image.path}`
                            : noImage
                        }
                        alt="תמונה ראשית"
                      />
                    </Link>
                    <CardFooter>
                      <Link
                        to={`/plants/${props.plantId}/identification-images/${plant._id}`}
                        style={{
                          color: "rgb(62,199,175)",
                          fontWeight: "bold",
                        }}
                      >
                        מידע נוסף
                      </Link>
                    </CardFooter>
                  </Card>
                </Col>
              );
            })}
        </Row>

        {identificationImages.length === 0 && !props.loading && (
          <div className="text-center">
            <br />
            <h3 style={{ color: "#3ec7af" }}>לא נמצאו תמונות</h3>
          </div>
        )}
      </div>

      <div
        style={{
          position: "absolute",
          left: "0",
          right: "0",
          bottom: "0",
          overflow: "hidden",
        }}
      >
        {identificationImages.length > 0 && (
          <Row>
            <Card
              style={{
                backgroundColor: "transparent",
                boxShadow: "none",
              }}
            >
              <Col md="12" className="d-flex justify-content-center">
                <PaginationComponent
                  totalItems={props.totalImages}
                  className="pagination"
                  pageSize={limit}
                  onSelect={handlePageSelect}
                  firstPageText="ראשון"
                  previousPageText="הקודם"
                  nextPageText="הבא"
                  lastPageText="אחרון"
                />
              </Col>
            </Card>
          </Row>
        )}
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  identificationImages: state.plants.identificationImages,
  totalImages: state.plants.totalImages,
  loading: state.plants.loading,
});

export default connect(mapStateToProps, { getPlantIdentificationImages })(
  IdentificationImages
);
