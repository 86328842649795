/*
 * action types
 */
export const USER_LOADING = "USER_LOADING";
export const SEND_OTP_SUCCESS = "SEND_OTP_SUCCESS";
export const SEND_OTP_FAIL = "SEND_OTP_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const RESET_EMAIL = "RESET_EMAIL";
export const AUTH_VERIFIED_SUCCESS = "AUTH_VERIFIED_SUCCESS";
export const AUTH_VERIFIED_FAIL = "AUTH_VERIFIED_FAIL";
export const LOGOUT = "LOGOUT";
