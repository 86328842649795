import React, {useEffect, useState} from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import {connect} from "react-redux";
import LoadingModal from "views/components/LoadingModal";
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    FormGroup,
    Input,
    Row,
    Col,
} from "reactstrap";
import TextareaAutosize from "react-textarea-autosize";
import {createNotification} from "../../redux/actions/message";

/**
 * This page allows admins to send a system notifications to all client users
 */
const CreateNotification = (props) => {
    const [notificationTitle, setNotificationTitle] = useState('');
    const [notificationBody, setNotificationBody] = useState('');

    const [alert, setAlert] = useState(null);

    const showAlert = () => {
        setAlert(
            <ReactBSAlert
                warning
                style={{display: "block", marginTop: "-100px"}}
                title="האם את/ה בטוח/ה?"
                onConfirm={async () => {
                    await props.createNotification(notificationTitle, notificationBody);
                    setAlert(
                        <ReactBSAlert
                            style={{display: "block", marginTop: "-100px"}}
                            title="ההתראה נשלחה בהצלחה"
                            onConfirm={() => setAlert(null)}
                            onCancel={() => setAlert(null)}
                            confirmBtnBsStyle="success"
                            confirmBtnText="אישור"
                            btnSize=""
                        >
                            הפעולה הבאה תשלח התראה ללקוחות המערכת
                        </ReactBSAlert>
                    );
                }}
                onCancel={() => setAlert(null)}
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                confirmBtnText="אישור"
                cancelBtnText="ביטול"
                showCancel
                btnSize=""
            >
                הפעולה הבאה תשלח התראה ללקוחות המערכת
            </ReactBSAlert>
        );
    };

    /**
     * function that handles a creating a new admin profile
     */
    const createSystemNotification = (e) => {
        e.preventDefault();
        showAlert();
    };

    return (
        <>
            {alert}
            <div className="content">
                <LoadingModal loading={props.sendingRequest}/>

                <h2 className="text-center">שליחת התראת מערכת ללקוחות</h2>
                <Row>
                    <Col md="12">
                        <div>
                            <Card className="text-right">
                                <CardBody>
                                    <Row>
                                        <Col className="pr-mr-1" md="6">
                                            <FormGroup>
                                                <h4>כותרת ההתראה</h4>
                                                <Input
                                                    name="name"
                                                    value={notificationTitle}
                                                    type="text"
                                                    minLength="1"
                                                    maxLength="1000"
                                                    onChange={(e) => setNotificationTitle(e.target.value)}
                                                    required
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col className="px-mx-1" md="6">
                                            <FormGroup>
                                                <h4>גוף ההתראה</h4>
                                                <TextareaAutosize
                                                    className="autosize-textarea"
                                                    required
                                                    value={notificationBody}
                                                    name="description"
                                                    minLength="1"
                                                    maxLength="1000"
                                                    onChange={(e) => setNotificationBody(e.target.value)}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter>
                                    <Button
                                        onClick={createSystemNotification}
                                        className="btn-fill"
                                        color="primary"
                                        disabled={!notificationTitle || !notificationBody}
                                    >
                                        שלח התראה ללקוחות
                                    </Button>
                                </CardFooter>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    sendingRequest: state.admins.sendingRequest,
});

export default connect(mapStateToProps, {createNotification})(CreateNotification);
