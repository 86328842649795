import React from "react";

import { connect } from "react-redux";
import { CSVLink } from "react-csv";

import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
} from "reactstrap";
import {exportReceipts, getStatistics} from "../../redux/actions/dashboard";
import { exportArticles } from "../../redux/actions/articles";
import { exportRecipes } from "../../redux/actions/recipes";
import { exportPlantSpots } from "../../redux/actions/plants-spots";
import { exportPlants } from "../../redux/actions/plants";
import { exportClients } from "../../redux/actions/clients";
import { verifySession } from "../../redux/actions/auth";

/**
 * Component for viewing statistics for all entities and exporting to csv
 */
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      statistics: {
        clients: {
          active: 0,
          unverified: 0,
          profileNotCreated: 0,
          deleted: 0,
        },
        subscribers: {
          active: 0,
          cancelled: 0,
        },
        plants: { active: 0, inactive: 0 },
        plantSpots: { active: 0, historic: 0, pendingApproval: 0, rejected: 0 },
        recipes: { active: 0, inactive: 0 },
        articles: { active: 0, inactive: 0 },
        files: { files: 0, totalSize: 0 },
      },
    };
  }

  componentDidMount() {
    this.props.verifySession();
    this.props.getStatistics();
  }

  componentDidUpdate(prevProps) {
    if (this.props.statistics !== prevProps.statistics) {
      this.setState({ statistics: this.props.statistics });
    }
  }

  /**
   * functions for getting data for each entity
   */
  handleExcelExportClients = () => {
    this.props.exportClients();
  };

  handleExcelExportReceipts = () => {
    this.props.exportReceipts();
  };

  handleExcelExportPlants = () => {
    this.props.exportPlants();
  };

  handleExcelExportPlantSpots = () => {
    this.props.exportPlantSpots();
  };

  handleExcelExportRecipes = () => {
    this.props.exportRecipes();
  };

  handleExcelExportArticles = () => {
    this.props.exportArticles();
  };

  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };

  render() {
    const sizeMB = this.state.statistics.files.totalSize / 1000000;
    return (
      <div className="content">
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <h4
            className="text-center"
            style={{ marginTop: "10px", marginBottom: "0", padding: "0" }}
          >
            יצוא נתונים לאקסל
          </h4>
          <ModalBody>
            <Row>
              <Col md="6" className="d-flex justify-content-start">
                {this.props.csvPlantsData ? (
                  <CSVLink
                    filename={"plants.csv"}
                    data={this.props.csvPlantsData}
                  >
                    <Button style={{ width: "200px" }}>הורדת קובץ</Button>
                  </CSVLink>
                ) : (
                  <Button
                    className="btn-fill mx-1"
                    color="primary"
                    type="submit"
                    onClick={this.handleExcelExportPlants}
                    style={{ width: "200px" }}
                  >
                    צמחים
                  </Button>
                )}
              </Col>
              <Col md="6" className="d-flex justify-content-start">
                {this.props.csvPlantSpotsData ? (
                  <CSVLink
                    filename={"plantSpots.csv"}
                    data={this.props.csvPlantSpotsData}
                  >
                    <Button style={{ width: "200px" }}>הורדת קובץ</Button>
                  </CSVLink>
                ) : (
                  <Button
                    className="btn-fill mx-1"
                    color="primary"
                    type="submit"
                    onClick={this.handleExcelExportPlantSpots}
                    style={{ width: "200px" }}
                  >
                    ספוטים
                  </Button>
                )}
              </Col>
            </Row>
            <Row>
              <Col md="6" className="d-flex justify-content-start">
                {this.props.csvRecipeData ? (
                  <CSVLink
                    filename={"recipes.csv"}
                    data={this.props.csvRecipeData}
                  >
                    <Button style={{ width: "200px" }}>הורדת קובץ</Button>
                  </CSVLink>
                ) : (
                  <Button
                    className="btn-fill mx-1"
                    color="primary"
                    type="submit"
                    onClick={this.handleExcelExportRecipes}
                    style={{ width: "200px" }}
                  >
                    מתכונים
                  </Button>
                )}
              </Col>
              <Col md="6" className="d-flex justify-content-start">
                {this.props.csvArticleData ? (
                  <CSVLink
                    filename={"articles.csv"}
                    data={this.props.csvArticleData}
                  >
                    <Button style={{ width: "200px" }}>הורדת קובץ</Button>
                  </CSVLink>
                ) : (
                  <Button
                    className="btn-fill mx-1"
                    color="primary"
                    type="submit"
                    onClick={this.handleExcelExportArticles}
                    style={{ width: "200px" }}
                  >
                    מאמרים
                  </Button>
                )}
              </Col>
            </Row>

            <Row>
              <Col md="6" className="d-flex justify-content-start">
                {this.props.csvClients ? (
                  <CSVLink
                    filename={"clients.csv"}
                    data={this.props.csvClients}
                  >
                    <Button style={{ width: "200px" }}>הורדת קובץ</Button>
                  </CSVLink>
                ) : (
                  <Button
                    className="btn-fill mx-1"
                    color="primary"
                    type="submit"
                    onClick={this.handleExcelExportClients}
                    style={{ width: "200px" }}
                  >
                    לקוחות
                  </Button>
                )}
              </Col>

              <Col md="6" className="d-flex justify-content-start"></Col>
            </Row>

            <Row>
              <Col md="6" className="d-flex justify-content-start">
                {this.props.csvReceipts ? (
                  <CSVLink
                    filename={"receipts.csv"}
                    data={this.props.csvReceipts}
                  >
                    <Button style={{ width: "200px" }}>הורדת קובץ</Button>
                  </CSVLink>
                ) : (
                  <Button
                    className="btn-fill mx-1"
                    color="primary"
                    type="submit"
                    onClick={this.handleExcelExportReceipts}
                    style={{ width: "200px" }}
                  >
                    חשבוניות
                  </Button>
                )}
              </Col>

              <Col md="6" className="d-flex justify-content-start"></Col>
            </Row>
          </ModalBody>
        </Modal>

        <Row>
          <Col lg="3" md="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col xs="9">
                    <div className="numbers">
                      <CardTitle tag="h3">לקוחות</CardTitle>
                    </div>
                  </Col>
                </Row>
                <hr style={{ backgroundColor: "#3ec7af" }} />
                <Row>
                  <Col xs="5">
                    <div className="numbers">
                      <p className="card-category">לקוחות פעילים</p>
                      <CardTitle tag="h3">
                        {this.state.statistics.clients.active}
                      </CardTitle>
                    </div>
                  </Col>
                  <Col xs="5">
                    <div className="numbers">
                      <p className="card-category">לקוחות שלא אומתו</p>
                      <CardTitle tag="h3">
                        {this.state.statistics.clients.unverified}
                      </CardTitle>
                    </div>
                  </Col>
                  <Col xs="5">
                    <div className="numbers">
                      <p className="card-category">לקוחות שנמחקו</p>
                      <CardTitle tag="h3">
                        {this.state.statistics.clients.deleted}
                      </CardTitle>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">לקוחות שלא סיימו הרשמה</p>
                      <CardTitle tag="h3">
                        {this.state.statistics.clients.profileNotCreated}
                      </CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>

              <CardFooter
                style={{ backgroundColor: "#1de9b6", height: "8px" }}
              ></CardFooter>
            </Card>
          </Col>
          <Col lg="3" md="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col xs="9">
                    <div className="numbers">
                      <CardTitle tag="h3">מנויים</CardTitle>
                    </div>
                  </Col>
                </Row>
                <hr style={{ backgroundColor: "#3ec7af" }} />
                <Row>
                  <Col xs="12">
                    <div className="numbers">
                      <p className="card-category">מנויים פעילים</p>
                      <CardTitle tag="h3">
                        {this.state.statistics.subscribers.active}
                      </CardTitle>
                    </div>
                  </Col>
                  <Col xs="12">
                    <div className="numbers">
                      <p className="card-category">מנויים שבוטלו</p>
                      <CardTitle tag="h3">
                        {this.state.statistics.subscribers.cancelled}
                      </CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter
                style={{ backgroundColor: "#1de9b6", height: "8px" }}
              ></CardFooter>
            </Card>
          </Col>

          <Col lg="3" md="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col xs="9">
                    <div className="numbers">
                      <CardTitle tag="h3">צמחים</CardTitle>
                    </div>
                  </Col>
                </Row>
                <hr style={{ backgroundColor: "#3ec7af" }} />
                <Row>
                  <Col xs="12">
                    <div className="numbers">
                      <p className="card-category">צמחים פעילים</p>
                      <CardTitle tag="h3">
                        {this.state.statistics.plants.active}
                      </CardTitle>
                    </div>
                  </Col>
                  <Col xs="12">
                    <div className="numbers">
                      <p className="card-category">צמחים לא פעילים</p>
                      <CardTitle tag="h3">
                        {this.state.statistics.plants.inactive}
                      </CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter
                style={{ backgroundColor: "#1de9b6", height: "8px" }}
              ></CardFooter>
            </Card>
          </Col>
          <Col lg="3" md="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col xs="9">
                    <div className="numbers">
                      <CardTitle tag="h3">ספוטים של צמחים</CardTitle>
                    </div>
                  </Col>
                </Row>
                <hr style={{ backgroundColor: "#3ec7af" }} />
                <Row>
                  <Col xs="5">
                    <div className="numbers">
                      <p className="card-category">ספוטים פעילים</p>
                      <CardTitle tag="h3">
                        {this.state.statistics.plantSpots.active}
                      </CardTitle>
                    </div>
                  </Col>
                  <Col xs="5">
                    <div className="numbers">
                      <p className="card-category">ספוטים היסטוריים</p>
                      <CardTitle tag="h3">
                        {this.state.statistics.plantSpots.historic}
                      </CardTitle>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">ספוטים שממתינים לאישור</p>
                      <CardTitle tag="h3" style={{ color: "red" }}>
                        {this.state.statistics.plantSpots.pendingApproval}
                      </CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter
                style={{ backgroundColor: "#1de9b6", height: "8px" }}
              ></CardFooter>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="3" md="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col xs="9">
                    <div className="numbers">
                      <CardTitle tag="h3">מתכונים</CardTitle>
                    </div>
                  </Col>
                </Row>
                <hr style={{ backgroundColor: "#3ec7af" }} />
                <Row>
                  <Col xs="12">
                    <div className="numbers">
                      <p className="card-category">מתכונים פעילים</p>
                      <CardTitle tag="h3">
                        {this.state.statistics.recipes.active}
                      </CardTitle>
                    </div>
                  </Col>
                  <Col xs="12">
                    <div className="numbers">
                      <p className="card-category">מתכונים לא פעילים</p>
                      <CardTitle tag="h3">
                        {this.state.statistics.recipes.inactive}
                      </CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter
                style={{ backgroundColor: "#1de9b6", height: "8px" }}
              ></CardFooter>
            </Card>
          </Col>
          <Col lg="3" md="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col xs="9">
                    <div className="numbers">
                      <CardTitle tag="h3">מאמרים</CardTitle>
                    </div>
                  </Col>
                </Row>
                <hr style={{ backgroundColor: "#3ec7af" }} />
                <Row>
                  <Col xs="12">
                    <div className="numbers">
                      <p className="card-category">מאמרים פעילים</p>
                      <CardTitle tag="h3">
                        {this.state.statistics.articles.active}
                      </CardTitle>
                    </div>
                  </Col>
                  <Col xs="12">
                    <div className="numbers">
                      <p className="card-category">מאמרים לא פעילים</p>
                      <CardTitle tag="h3">
                        {this.state.statistics.articles.inactive}
                      </CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter
                style={{ backgroundColor: "#1de9b6", height: "8px" }}
              ></CardFooter>
            </Card>
          </Col>
          <Col lg="3" md="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col xs="9">
                    <div className="numbers">
                      <CardTitle tag="h3">קבצים</CardTitle>
                    </div>
                  </Col>
                </Row>
                <hr style={{ backgroundColor: "#3ec7af" }} />
                <Row>
                  <Col xs="12">
                    <div className="numbers">
                      <p className="card-category">קבצים במערכת</p>
                      <CardTitle tag="h3">
                        {this.state.statistics.files.files}
                      </CardTitle>
                    </div>
                  </Col>
                  <Col xs="12">
                    <div className="numbers">
                      <p className="card-category">שימוש באחסון MB</p>
                      <CardTitle tag="h3">{sizeMB.toFixed(2)}</CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>

              <CardFooter
                style={{ backgroundColor: "#1de9b6", height: "8px" }}
              ></CardFooter>
            </Card>
          </Col>
        </Row>

        <hr />
        <Row>
          <Col md="12" className="d-flex justify-content-start">
            <Button
              className="btn-fill mx-1"
              color="primary"
              type="submit"
              style={{ width: "250px" }}
              onClick={this.toggle}
            >
              יצוא נתונים לאקסל
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  statistics: state.dashboard.statistics,
  csvArticleData: state.articles.csvArticleData,
  csvRecipeData: state.recipes.csvRecipeData,
  csvPlantSpotsData: state.plantSpots.csvPlantSpotsData,
  csvPlantsData: state.plants.csvPlantsData,
  csvClients: state.clients.csvClients,
  csvReceipts: state.dashboard.csvReceipts,
});

export default connect(mapStateToProps, {
  getStatistics,
  exportArticles,
  exportRecipes,
  exportPlantSpots,
  exportPlants,
  exportClients,
  exportReceipts,
  verifySession,
})(Dashboard);
