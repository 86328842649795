export const DATA_LOADING = "DATA_LOADING";

export const SENDING_DATA = "SENDING_DATA";

export const RECIPES_LOADED = "RECIPES_LOADED";
export const RECIPES_LOAD_FAIL = "RECIPES_LOAD_FAIL";

export const RECIPE_LOADED = "RECIPE_LOADED";
export const RECIPE_LOAD_FAIL = "RECIPE_LOAD_FAIL";

export const RECIPE_CREATED = "RECIPE_CREATED";
export const RECIPE_CREATE_FAIL = "RECIPE_CREATE_FAIL";

export const RECIPE_UPDATED = "RECIPE_UPDATED";
export const RECIPE_UPDATE_FAIL = "RECIPE_UPDATE_FAIL";

export const RECIPE_ACTIVATED = "RECIPE_ACTIVATED";
export const RECIPE_ACTIVATE_FAIL = "RECIPE_ACTIVATE_FAIL";

export const RECIPE_DEACTIVATED = "RECIPE_DEACTIVATED";
export const RECIPE_DEACTIVATE_FAIL = "RECIPE_DEACTIVATE_FAIL";

export const RECIPE_CATEGORIES = "RECIPE_CATEGORIES";
export const RECIPE_CATEGORIES_FAIL = "RECIPE_CATEGORIES_FAIL";

export const GALLERY_DELETED = "GALLERY_DELETED";
export const GALLERY_DELETE_FAIL = "GALLERY_DELETE_FAIL";

export const VIDEO_DELETED = "VIDEO_DELETED";
export const VIDEO_DELETE_FAIL = "VIDEO_DELETE_FAIL";

export const RECIPES_EXPORTED = "RECIPES_EXPORTED";
export const RECIPES_EXPORT_FAIL = "RECIPES_EXPORT_FAIL";
