import React, { useState } from "react";
import { Link } from "react-router-dom";
import ReactBSAlert from "react-bootstrap-sweetalert";
import ToastError, { REQUIRED_FIELD } from "../../components/Errors";
import { connect } from "react-redux";
import { createAdmin } from "../../../redux/actions/admins";
import LoadingModal from "views/components/LoadingModal";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  FormGroup,
  Input,
  Row,
  Col,
  Modal,
  ModalBody,
} from "reactstrap";

/**
 * CreateAdmin Component for creating a new admin
 */
const CreateAdmin = (props) => {
  const [admin, setAdmin] = useState({
    name: "",
    email: "",
  });

  const [errorName, setErrorName] = useState(null);
  const [errorEmail, setErrorEmail] = useState(null);

  const handleFormChange = (e) => {
    setAdmin({ ...admin, [e.target.name]: e.target.value });
  };

  const [alert, setAlert] = useState(null);

  const showAlert = () => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="האם את/ה בטוח/ה?"
        onConfirm={() => {
          props.createAdmin(admin.name, admin.email, props);
          setAlert(null);
        }}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText="אישור"
        cancelBtnText="ביטול"
        showCancel
        btnSize=""
      >
        הפעולה הבאה תיצור מנהל מערכת
      </ReactBSAlert>
    );
  };

  /**
   * function that verifies email input is in correct email format
   */
  const verifyEmail = (value) => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };

  /**
   * method that validates full name input is only english letters
   */
  const validateFullName = (value) => {
    var letters = /^[A-Za-z\s]+$/;
    if (value.match(letters)) {
      return true;
    }
    return false;
  };

  /**
   * function that handles a creating a new admin profile
   */
  const handleAdminProfileCreate = (e) => {
    e.preventDefault();
    let createAdmin = true;

    if (admin.name === "") {
      createAdmin = false;
      setErrorName(<ToastError error={REQUIRED_FIELD} />);
    } else {
      setErrorName(null);
      if (!validateFullName(admin.name)) {
        createAdmin = false;
        setErrorName(
          <ToastError error="שם מלא חייב להכיל אותיות באנגלית בלבד" />
        );
      }
    }

    if (admin.email === "") {
      createAdmin = false;
      setErrorEmail(<ToastError error={REQUIRED_FIELD} />);
    } else {
      setErrorEmail(null);
      if (!verifyEmail(admin.email)) {
        createAdmin = false;
        setErrorEmail(<ToastError error="כתובת אימייל אינה תקינה" />);
      }
    }

    if (createAdmin) {
      setErrorName(null);
      setErrorEmail(null);
      showAlert();
    }
  };

  return (
    <>
      {alert}
      <div className="content">
        <LoadingModal loading={props.sendingRequest} />

        <h2 className="text-center">יצירת מנהל מערכת</h2>
        <Row>
          <Col xs={12} className="text-right">
            <Link to={"/admins"}>
              <Button
                className="btn-fill mx-1"
                color="primary"
                type="submit"
                style={{ width: "250px" }}
              >
                חזרה{" "}
              </Button>
            </Link>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <div>
              <Card className="text-right">
                <CardBody>
                  <Row>
                    <Col className="pr-mr-1" md="6">
                      <FormGroup>
                        <h4>שם מלא</h4>
                        <Input
                          name="name"
                          value={admin.name}
                          type="text"
                          onChange={handleFormChange}
                          placeholder="שם מלא"
                          required
                        />
                        {errorName}
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col className="px-mx-1" md="6">
                      <FormGroup>
                        <h4>כתובת אימייל</h4>
                        <Input
                          name="email"
                          value={admin.email}
                          type="email"
                          onChange={handleFormChange}
                          placeholder="כתובת אימייל"
                          required
                        />
                        {errorEmail}
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <Button
                    onClick={handleAdminProfileCreate}
                    className="btn-fill"
                    color="primary"
                  >
                    צור מנהל מערכת
                  </Button>
                </CardFooter>
              </Card>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  sendingRequest: state.admins.sendingRequest,
});

export default connect(mapStateToProps, { createAdmin })(CreateAdmin);
