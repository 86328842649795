import axios from 'axios';
// import store from './store/store';
// import { createMessage } from './redux/actions/message';

// All supported http methods of the system
export const supportedRequestMethods = {
	POST: 'post',
	PUT: 'put',
	PATCH: 'patch',
	GET: 'get',
	DELETE: 'delete',
};

/**
 * A wrapper function for all requests
 *
 * @param headers - custom headers if required.
 * @param method - The http method that will be used for the request
 * @param endpoint - The endpoint that the request is aimed at
 * @param body - The request body
 * @param withCredentials - Specifies if the request request credentials. Required for the cookies to be passed!
 * @returns A response object that always contains an indication if the request was successful, a single message and potentially full request data for the caller to play around with if needed.
 */
export async function requestWrapper({ headers, method, endpoint, body }) {
	let response;
	// Craft a configuration for all Axios requests

	let API = axios.create({
		baseURL: process.env.REACT_APP_API_URL,
		headers: {
			Accept: 'application/json',
			...headers,
		},
		withCredentials: true,
	});

	try {
		response = await API[method](
			endpoint,
			method === 'delete' ? { data: body } : body
		); // special wrapper for
		response.success = true; // Indicator for the caller to know that the request was processed successfully.
	} catch (err) {
		response = err.response; // pulls the response from the error
		if (response) response.success = false; // Indicator for the caller to know that the request wasn't processed successfully.
	}
	if (response) response.message = response.data.message; // Pull the error message out of the response in order to make the life of the caller easier

	// Log the response for debugging purposes in non-production environments
	if (process.env.REACT_APP_ENV !== 'production') {
		console.log(response);
	}

	return response;
}
