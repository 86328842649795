import React, {useEffect, useState} from "react";
import ReactTable, {usePagination, useTable} from "react-table";
import {connect} from "react-redux";
import ReactBSAlert from "react-bootstrap-sweetalert";
import LoadingModal from "views/components/LoadingModal";
import {verifySession} from "../../../redux/actions/auth";

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    Modal,
    ModalBody, Input,
} from "reactstrap";
import {Link} from "react-router-dom";

import {
    getAdmins,
    deleteAdmin,
    crownAdmin,
} from "../../../redux/actions/admins";
import Spinner from "components/Spinner";

/**
 * AdminsList Component for viewing all existing admins
 * Actions - delete admin / add admin / crown admin
 */
const AdminsList = (props) => {
    const {} = props;
    const [state, setState] = useState({data: []});
    const [offset, setOffset] = useState(props.offset);
    const [limit] = useState(50);
    const [alert, setAlert] = useState(null);
    const [modalSuccess, setModalSuccess] = useState(false);
    const [timer, setTimer] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');

    /**
     * function that handles deleting an admin
     */
    const showDeleteAlert = (id) => {
        setAlert(
            <ReactBSAlert
                warning
                style={{display: "block", marginTop: "-100px"}}
                title="האם את/ה בטוח/ה?"
                onConfirm={() => {
                    props.deleteAdmin(id, props);
                    setAlert(null);
                }}
                onCancel={() => setAlert(null)}
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                confirmBtnText="אישור"
                cancelBtnText="ביטול"
                showCancel
                btnSize=""
            >
                הפעולה הבאה תמחק את מנהל המערכת
            </ReactBSAlert>
        );
    };

    /**
     * function that handles crown an admin
     */
    const showCrownAlert = (id) => {
        setAlert(
            <ReactBSAlert
                warning
                style={{display: "block", marginTop: "-100px"}}
                title="האם את/ה בטוח/ה?"
                onConfirm={() => {
                    props.crownAdmin(id, props);
                    setAlert(null);
                }}
                onCancel={() => setAlert(null)}
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                confirmBtnText="אישור"
                cancelBtnText="ביטול"
                showCancel
                btnSize=""
            ></ReactBSAlert>
        );
    };

    /**
     * function that handles search recipes
     * using timeout for optimization, sending request with search value only after user stops typing
     */
    const handleSearchInput = (e) => {
        let inputVal = e.target.value;
        setSearchQuery(inputVal);
        if (timer) {
            clearTimeout(timer);
        }
        setOffset(0)
        setTimer(
            setTimeout(() => {
                setTimer(null);
                props.getAdmins(0, limit, inputVal);
            }, 500)
        );
    };

    /**
     * function that handles selecting a page
     * gets admins from backend with new offset
     */
    const handlePageChange = (pageNumber) => {
        setOffset(pageNumber);
        props.getAdmins(pageNumber, limit, searchQuery);
    };

    /**
     * useEffect that gets admins
     * offset starts at 0 and number of admins per page is limited is 50
     */
    useEffect(() => {
        props.verifySession();
        props.getAdmins(offset, limit);
    }, []);

    useEffect(() => {
        if (props.newAdmin) {
            props.getAdmins(offset, limit);
        }
    }, [props.newAdmin]);

    useEffect(() => {
        if (props.updateAdmin) {
            setModalSuccess(!modalSuccess);
        }
    }, [props.updateAdmin]);

    /**
     * useEffect that sets admins list state
     * mappping admins list to data - needed for react-table
     */
    useEffect(() => {
        setState({
            data: props.adminsList.map((prop, key) => {
                return {
                    id: key,
                    name: prop.name,

                    creationDate: new Date(prop.created_at).toLocaleDateString("en-GB"),
                    _id: prop._id,
                    email: prop.email,
                    crowned: prop.crowned ? "true" : "false",
                    actions: !prop.crowned && (
                        <>
                            <Button
                                onClick={() => {
                                    showDeleteAlert(prop._id);
                                }}
                                className="btn-fill mx-1"
                                color="primary"
                                type="submit"
                                style={{padding: "4px", margin: "0"}}
                            >
                                מחק מנהל מערכת
                            </Button>
                            {!props.crowned && (
                                <Button
                                    onClick={() => {
                                        showCrownAlert(prop._id);
                                    }}
                                    className="btn-fill mx-1"
                                    color="primary"
                                    type="submit"
                                    style={{padding: "4px", margin: "0"}}
                                >
                                    הפוך לראשי
                                </Button>
                            )}
                        </>
                    ),
                };
            }),
        });
    }, [props.adminsList]);

    const columns = React.useMemo(
        () => [
            {
                Header: "שם מלא",
                accessor: "name",
                headerClassName: "text-right",
                className: "text-right",
            },
            {
                Header: "אימייל",
                accessor: "email",
                headerClassName: "text-right",
                className: "text-right",
                sortable: false,
                filterable: false,
            },
            {
                Header: "תאריך יצירה",
                accessor: "creationDate",
                headerClassName: "text-right",
                className: "text-right",
                sortable: false,
                filterable: false,
            },
            {
                Header: "מנהל מערכת ראשי",
                accessor: "crowned",
                headerClassName: "text-right",
                className: "text-right",
                sortable: false,
                filterable: false,
            },
            {
                Header: "פעולות",
                accessor: "actions",
                headerClassName: "text-right",
                className: "text-right",
                sortable: false,
                filterable: false,
                // width: 400,
            },
        ],
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
    } = useTable(
        {
            columns,
            data: state.data,
            initialState: {pageIndex: 0, pageSize: limit},
        }, usePagination
    )

    return (
        <>
            <Modal isOpen={modalSuccess} className="text-center">
                <ModalBody>
                    <h4>הפעולה בוצעה בהצלחה</h4>
                    <Row>
                        <Col md="12" className="d-flex justify-content-center">
                            <Button
                                onClick={() => {
                                    setModalSuccess(!modalSuccess);
                                    props.getAdmins(props.offset, limit);
                                }}
                            >
                                אישור
                            </Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>

            {props.loading ? (
                <Spinner/>
            ) : (
                <div className="content rtl">
                    {alert}

                    <LoadingModal loading={props.sendingRequest}/>

                    <Row>
                        <Col xs={12} className="text-right">
                            <Link to={"/admins/create"}>
                                <Button
                                    className="btn-fill mx-1"
                                    color="primary"
                                    type="submit"
                                    style={{width: "250px"}}
                                >
                                    צור מנהל מערכת
                                </Button>
                            </Link>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4" className="text-right">
                                        רשימת מנהלי מערכת
                                    </CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <div
                                        className="pagination"
                                        style={{justifyContent: 'space-between', alignItems: 'center'}}
                                    >
                                        <button
                                            onClick={() => {
                                                handlePageChange(offset - 1)
                                            }}
                                            disabled={offset === 0}
                                            className="btn-fill mx-1 btn btn-primary"
                                            style={{width: '50%'}}
                                        >
                                            {'הקודם'}
                                        </button>
                                        <span
                                            style={{width: '100%', textAlign: 'center'}}
                                        >
                                        {`עמוד ${offset + 1} מתוך ${Math.ceil(props.totalAdmins / limit)}`}
                                    </span>
                                        <button
                                            onClick={() => {
                                                handlePageChange(offset + 1)
                                            }}
                                            disabled={offset >= Math.floor(props.totalAdmins / limit)}
                                            className="btn-fill mx-1 btn btn-primary"
                                            style={{width: '50%'}}
                                        >
                                            {'הבא'}
                                        </button>
                                    </div>
                                    <div
                                        style={{
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        <Input
                                            style={{
                                                height: "40px",
                                                width: '25%'
                                            }}
                                            placeholder="סינון לפי שם משתמש"
                                            value={searchQuery}
                                            onChange={handleSearchInput}
                                        />
                                    </div>
                                    <table {...getTableProps()} style={{width: '100%'}}>
                                        <thead>
                                        {headerGroups.map(headerGroup => (
                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                {headerGroup.headers.map(column => (
                                                    <th
                                                        {...column.getHeaderProps()}
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        {column.render('Header')}
                                                    </th>
                                                ))}
                                            </tr>
                                        ))}
                                        </thead>
                                        <tbody {...getTableBodyProps()}>
                                        {page.map((row, i) => {
                                            prepareRow(row)
                                            return (
                                                <tr {...row.getRowProps()}>
                                                    {row.cells.map(cell => {
                                                        return (
                                                            <td
                                                                {...cell.getCellProps()}
                                                                style={{
                                                                    padding: '10px',
                                                                    border: 'solid 1px gray',
                                                                    textAlign: 'right',
                                                                }}
                                                            >
                                                                {cell.render('Cell')}
                                                            </td>
                                                        )
                                                    })}
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </table>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            )}
        </>
    );
};

const mapStateToProps = (state) => ({
    adminsList: state.admins.adminsList,
    offset: state.admins.offset,
    totalAdmins: state.admins.totalAdmins,
    newAdmin: state.admins.newAdmin,
    sendingRequest: state.admins.sendingRequest,
    updateAdmin: state.admins.updateAdmin,
    loading: state.admins.loading,
});

export default connect(mapStateToProps, {
    getAdmins,
    deleteAdmin,
    crownAdmin,
    verifySession,
})(AdminsList);
