import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import ReactBSAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import StyledDropzone from "../../components/Dropzone";
import chroma from 'chroma-js';

import {uploadPlantIdentificationImage} from "../../../redux/actions/plants";
import {getPlantIdentificationImageCharacteristics} from "../../../redux/actions/shared";

import {
    Button,
    Card,
    CardBody,
    Row,
    Col,
    CardFooter,
    FormGroup,
    Modal,
    ModalBody,
} from "reactstrap";

import ToastError, {REQUIRED_FIELD} from "../../components/Errors";
import LoadingModal from "views/components/LoadingModal";

const colorNameMap = {
    white: 'White',
    black: 'Black',
    pink: 'Pink',
    '#97DEA1': 'Green',
    '#FF3D57': 'Pastel pink',
    '#BA9866': 'Brown',
    '#C95A5A': 'Brick pink',
    '#8B0749': 'Magenta pink',
    '#FFC664': 'Orange',
    '#F8F897': 'Yellow',
    '#2970FF': 'Caribbean blue',
    '#8D4683': 'Eggplant purple',
}

const PICmap = {
    habitat: {
        index: 0,
    },
    growthState: {
        index: 1,
    },
    leafStructure: {
        index: 2,
    },
    leafShape: {
        index: 3,
    },
    leafTips: {
        index: 4,
    },
    leafBase: {
        index: 5,
    },
    leafContour: {
        index: 6,
    },
    leafSurface: {
        index: 7,
    },
    leafUniqueFeatures: {
        index: 8,
    },
    leafArrangement: {
        index: 9,
    },
    inflorescence: {
        index: 10,
    },
    inflorescenceColor: {
        index: 11,
    },
    fruitType: {
        index: 12,
    },
    fruitColor: {
        index: 13,
    },
};


/**
 * Component for creating an new identification image
 */
const CreateIdentificationImage = (props) => {
    const animatedComponents = makeAnimated();

    const [plantCharacteristics, setPlantCharacteristics] = useState([]);
    const [characteristics, setCharacteristics] = useState({});
    // const [identificationMethod, setIdentificationMethod] = useState({

    // });
    const [image, setImage] = useState(null);
    const [errorImage, setErrorImage] = useState(null);
    const [errorHabitats, setErrorHabitats] = useState(null);
    const [alert, setAlert] = useState(null);
    const [modalFail, setModalFail] = useState(false);

    /**
     * function that handles plant habitats selections
     */
    const handleSelectHabitats = (selected) => {
        if (selected) {
            let habitatIds = selected.map((habitat) => {
                return habitat.id;
            });

            setCharacteristics({...characteristics, habitat: habitatIds});
        } else {
            delete characteristics.habitat;
        }
    };

    /**
     * function that handles plant growth state selections
     */
    const handleSelectGrowthState = (selected) => {
        if (selected) {
            let growthIds = selected.map((growth) => {
                return growth.id;
            });

            setCharacteristics({
                ...characteristics,
                growthState: growthIds,
            });
        } else {
            delete characteristics.growthState;
        }
    };

    /**
     * function that handles leaf structure selections
     */
    const handleSelectLeafStructure = (selected) => {
        if (selected) {
            let structureIds = selected.map((structure) => {
                return structure.id;
            });

            setCharacteristics({
                ...characteristics,
                leafStructure: structureIds,
            });
        } else {
            delete characteristics.leafStructure;
        }
    };

    /**
     * function that handles leaf shape selections
     */
    const handleSelectLeafShape = (selected) => {
        if (selected) {
            let shapeIds = selected.map((shape) => {
                return shape.id;
            });

            setCharacteristics({
                ...characteristics,
                leafShape: shapeIds,
            });
        } else {
            delete characteristics.leafShape;
        }
    };

    /**
     * function that handles leaf tips selections
     */
    const handleSelectLeafTips = (selected) => {
        if (selected) {
            let tipsIds = selected.map((tip) => {
                return tip.id;
            });

            setCharacteristics({
                ...characteristics,
                leafTips: tipsIds,
            });
        } else {
            delete characteristics.leafTips;
        }
    };

    /**
     * function that handles leaf base selections
     */
    const handleSelectLeafBase = (selected) => {
        if (selected) {
            let baseIds = selected.map((base) => {
                return base.id;
            });

            setCharacteristics({
                ...characteristics,
                leafBase: baseIds,
            });
        } else {
            delete characteristics.leafBase;
        }
    };

    /**
     * function that handles leaf contour selections
     */
    const handleSelectLeafContour = (selected) => {
        if (selected) {
            let contourIds = selected.map((contour) => {
                return contour.id;
            });

            setCharacteristics({
                ...characteristics,
                leafContour: contourIds,
            });
        } else {
            delete characteristics.leafContour;
        }
    };

    /**
     * function that handles leaf surface selections
     */
    const handleSelectLeafSurface = (selected) => {
        if (selected) {
            let surfaceIds = selected.map((surface) => {
                return surface.id;
            });

            setCharacteristics({
                ...characteristics,
                leafSurface: surfaceIds,
            });
        } else {
            delete characteristics.leafSurface;
        }
    };

    /**
     * function that handles leaf unique features selections
     */
    const handleSelectLeafUniqueFeatures = (selected) => {
        if (selected) {
            let featureIds = selected.map((feature) => {
                return feature.id;
            });

            setCharacteristics({
                ...characteristics,
                leafUniqueFeatures: featureIds,
            });
        } else {
            delete characteristics.leafUniqueFeatures;
        }
    };

    /**
     * function that handles leaf arrangement selections
     */
    const handleSelectLeafArrangement = (selected) => {
        if (selected) {
            let arrangementIds = selected.map((arrangement) => {
                return arrangement.id;
            });

            setCharacteristics({
                ...characteristics,
                leafArrangement: arrangementIds,
            });
        } else {
            delete characteristics.leafArrangement;
        }
    };

    /**
     * function that handles inflorescence selections
     */
    const handleSelectInflorescence = (selected) => {
        if (selected) {
            let inflorescenceIds = selected.map((inflorescence) => {
                return inflorescence.id;
            });

            setCharacteristics({
                ...characteristics,
                inflorescence: inflorescenceIds,
            });
        } else {
            delete characteristics.inflorescence;
        }
    };

    /**
     * function that handles fruit type selections
     */
    const handleSelectFruitType = (selected) => {
        if (selected) {
            let typeIds = selected.map((type) => {
                return type.id;
            });

            setCharacteristics({
                ...characteristics,
                fruitType: typeIds,
            });
        } else {
            delete characteristics.fruitType;
        }
    };

    /**
     * function that handles fruit color selections
     */
    const handleSelectFruitColor = (selected) => {
        if (selected) {
            let fruitColorIds = selected.map((color) => {
                return color.id;
            });

            setCharacteristics({
                ...characteristics,
                fruitColor: fruitColorIds,
            });
        } else {
            delete characteristics.fruitColor;
        }
    };

    /**
     * function that handles inflorescence color selections
     */
    const handleSelectInflorescenceColor = (selected) => {
        if (selected) {
            let inflorescenceColorIds = selected.map((color) => {
                return color.id;
            });

            setCharacteristics({
                ...characteristics,
                inflorescenceColor: inflorescenceColorIds,
            });
        } else {
            delete characteristics.inflorescenceColor;
        }
    };

    /**
     * function that handles selecting an image
     */
    const handleSelectImage = (file) => {
        setImage(file[0]);
    };

    /**
     * function that handles deleting an image
     */
    const handleDeleteImage = () => {
        setImage(null);
    };

    /**
     * function that handles creating an identification image
     */
    const showAlert = (toCreate) => {
        setAlert(
            <ReactBSAlert
                warning
                style={{display: "block", marginTop: "-100px"}}
                title="האם את/ה בטוח/ה?"
                onConfirm={() => {
                    props.uploadPlantIdentificationImage(
                        toCreate,
                        props.match.params.id,
                        props
                    );
                    setAlert(null);
                }}
                onCancel={() => setAlert(null)}
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                confirmBtnText="אישור"
                cancelBtnText="ביטול"
                showCancel
                btnSize=""
            >
                הפעולה הבאה תיצור תמונה חדשה לזיהוי הצמח במערכת
            </ReactBSAlert>
        );
    };

    /**
     * function that shows error alert for required fields
     */
    const showErrorAlert = () => {
        setAlert(
            <ReactBSAlert
                warning
                style={{display: "block", marginTop: "-100px"}}
                title="נא למלא את כל שדות החובה"
                onConfirm={() => {
                    setAlert(null);
                }}
                confirmBtnBsStyle="error"
                confirmBtnText="אישור"
                btnSize=""
            >
                שדות חובה מסומנים בכוכבית
            </ReactBSAlert>
        );
    };

    /**
     * function to reset all error messages
     */
    const resetErrorMessages = () => {
        setErrorImage(null);
        setErrorHabitats(null);
    };

    /**
     * functions that handles creating a new identification image
     */
    const handleCreateImage = () => {
        let toCreateFlag = true;
        let toCreate = {};
        console.log(characteristics);

        resetErrorMessages();

        if (image === null) {
            toCreateFlag = false;
            setErrorImage(<ToastError error={REQUIRED_FIELD}/>);
        }

        if (!characteristics.hasOwnProperty("habitat")) {
            setErrorHabitats(<ToastError error={REQUIRED_FIELD}/>);
            toCreateFlag = false;
        }

        if (toCreateFlag) {
            toCreate = {...toCreate, image: image};
            toCreate = {...toCreate, characteristics: characteristics};
            resetErrorMessages();
            // console.log(toCreate);
            showAlert(toCreate);
        } else {
            showErrorAlert();
        }
    };

    /**
     * useEffect for getting plant characteristics and mapping them to id, value, label
     * needed for react-select components
     */
    useEffect(() => {
        if (props.plantCharacteristics) {
            const characteristics = props.plantCharacteristics;
            const PIC = Object.entries(characteristics);
            let sortedCharacteristicsMapping = [];

            // For each of the known characteristics, create an array sorted by the mapping above the screen
            Object.entries(PICmap).forEach(entry => {
                sortedCharacteristicsMapping[entry[1].index] = PIC.find(element => element[0] === entry[0]);
            });

            console.log(sortedCharacteristicsMapping);

            let characteristicsList = [];

            console.log(characteristics);

            sortedCharacteristicsMapping.forEach(characteristic => {
                let current = characteristic[1];

                if (current[0].characteristicType === 'fruitColor' || current[0].characteristicType === 'inflorescenceColor') {
                    current.forEach(color => color.color = color.characteristic)
                }

                characteristicsList = [
                    ...characteristicsList,
                    current.map((char) => {
                        return {
                            id: char._id,
                            value: char.color ? colorNameMap[char.characteristic] : char.characteristic,
                            label: char.color ? colorNameMap[char.characteristic] : char.characteristic,
                            color: char.color
                        };
                    }),
                ];
            });

            setPlantCharacteristics(characteristicsList);
        } else {
            props.getPlantIdentificationImageCharacteristics();
        }
    }, [props.plantCharacteristics]);

    useEffect(() => {
        if (props.failedIdentImage) {
            setModalFail(props.failedIdentImage);
        }
    }, [props.failedIdentImage]);

    const colourStyles = {
        control: styles => ({...styles, backgroundColor: 'white'}),
        option: (styles, {data, isDisabled, isFocused, isSelected}) => {
            const color = chroma(data.color);
            return {
                ...styles,
                backgroundColor: isDisabled
                    ? null
                    : isSelected
                        ? data.color
                        : isFocused
                            ? color.alpha(0.1).css()
                            : null,
                cursor: isDisabled ? 'not-allowed' : 'default',

                ':active': {
                    ...styles[':active'],
                    backgroundColor: !isDisabled && (isSelected ? data.value : color.alpha(0.3).css()),
                },
            };
        },
        multiValue: (styles, {data}) => {
            const color = chroma(data.color);
            return {
                ...styles,
                backgroundColor: color.alpha(0.1).css(),
            };
        },
        multiValueLabel: (styles, {data}) => ({
            ...styles,
            borderWidth: '1px solid ',
        }),
    };

    return (
        <>
            <div className="content">
                {alert}
                <LoadingModal loading={props.sendingRequest}/>

                <Modal isOpen={modalFail} className="text-center">
                    <ModalBody>
                        <h4>הפעולה נכשלה</h4>
                        <Row>
                            <Col md="12" className="d-flex justify-content-center">
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        setModalFail(!modalFail);
                                    }}
                                >
                                    אישור
                                </Button>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>

                <h2 className="text-center">יצירת תמונה לזיהוי צמח</h2>

                <Row>
                    <Col xs={12} className="d-flex justify-content-start">
                        <Link
                            to={{
                                pathname: `/plants/${props.match.params.id}`,
                                data: true,
                            }}
                        >
                            <Button
                                className="btn-fill mx-1"
                                color="primary"
                                type="submit"
                                style={{width: "250px"}}
                            >
                                חזרה{" "}
                            </Button>
                        </Link>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} className="text-right">
                        <Card
                            style={{
                                padding: "10px",
                            }}
                        >
                            <CardBody>
                                <Row>
                                    <Col className="pr-md-1 d-flex justify-content-start" md="12">
                                        <FormGroup>
                                            <h4 style={{margin: "0"}}>תמונת הצמח *</h4>
                                            {/* <label style={{ fontSize: "14px" }}>{errorPrimary}</label> */}
                                            {errorImage}
                                            <label>ניתן להעלות קבצים מסוג: png, jpg, jpeg</label>
                                            <br/>
                                            <label>גודל קובץ עד 5MB</label>
                                            <StyledDropzone
                                                handleFileSave={handleSelectImage}
                                                handleRemoveFile={handleDeleteImage}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <br/>

                                <Row>
                                    <Col className="pr-md-1" md="6">
                                        <FormGroup>
                                            <h4>סביבה *</h4>
                                            <Select
                                                closeMenuOnSelect={false}
                                                isClearable={true}
                                                isMulti
                                                components={animatedComponents}
                                                options={plantCharacteristics[0]}
                                                placeholder="בחר/י סביבה"
                                                onChange={handleSelectHabitats}
                                            />
                                            {errorHabitats}
                                        </FormGroup>
                                    </Col>
                                    <Col className="pr-md-1" md="6">
                                        <FormGroup>
                                            <h4>סוג הצמח</h4>
                                            <Select
                                                isMulti
                                                closeMenuOnSelect={false}
                                                isClearable={true}
                                                components={animatedComponents}
                                                options={plantCharacteristics[1]}
                                                placeholder="בחר/י סוג צמח"
                                                onChange={handleSelectGrowthState}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <br/>
                                <Row>
                                    <Col className="pr-md-1" md="12">
                                        <FormGroup>
                                            <h4>פרטים לזיהוי העלה</h4>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="pr-md-1" md="6">
                                        <FormGroup>
                                            <label style={{fontSize: "14px", fontWeight: "bold"}}>
                                                מבנה העלה
                                            </label>
                                            <Select
                                                isMulti
                                                isClearable={true}
                                                closeMenuOnSelect={false}
                                                components={animatedComponents}
                                                options={plantCharacteristics[2]}
                                                placeholder="בחר/י את מבנה העלה"
                                                onChange={handleSelectLeafStructure}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col className="pr-md-1" md="6">
                                        <FormGroup>
                                            <label style={{fontSize: "14px", fontWeight: "bold"}}>
                                                צורת העלה
                                            </label>
                                            <Select
                                                isMulti
                                                isClearable={true}
                                                closeMenuOnSelect={false}
                                                components={animatedComponents}
                                                options={plantCharacteristics[3]}
                                                placeholder="בחר/י את צורת העלה"
                                                onChange={handleSelectLeafShape}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="pr-md-1" md="6">
                                        <FormGroup>
                                            <label style={{fontSize: "14px", fontWeight: "bold"}}>
                                                קצה העלה
                                            </label>
                                            <Select
                                                isMulti
                                                isClearable={true}
                                                closeMenuOnSelect={false}
                                                isClearable={true}
                                                components={animatedComponents}
                                                options={plantCharacteristics[4]}
                                                placeholder="בחר/י את קצה העלה"
                                                onChange={handleSelectLeafTips}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col className="pr-md-1" md="6">
                                        <FormGroup>
                                            <label style={{fontSize: "14px", fontWeight: "bold"}}>
                                                בסיס העלה
                                            </label>
                                            <Select
                                                isMulti
                                                isClearable={true}
                                                closeMenuOnSelect={false}
                                                components={animatedComponents}
                                                options={plantCharacteristics[5]}
                                                placeholder="בחר/י את בסיס העלה"
                                                onChange={handleSelectLeafBase}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col className="pr-md-1" md="6">
                                        <FormGroup>
                                            <label style={{fontSize: "14px", fontWeight: "bold"}}>
                                                מתאר העלה
                                            </label>
                                            <Select
                                                isMulti
                                                isClearable={true}
                                                closeMenuOnSelect={false}
                                                components={animatedComponents}
                                                options={plantCharacteristics[6]}
                                                placeholder="בחר/י את מתאר העלה"
                                                onChange={handleSelectLeafContour}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col className="pr-md-1" md="6">
                                        <FormGroup>
                                            <label style={{fontSize: "14px", fontWeight: "bold"}}>
                                                משטח העלה
                                            </label>
                                            <Select
                                                isMulti
                                                closeMenuOnSelect={false}
                                                isClearable={true}
                                                components={animatedComponents}
                                                options={plantCharacteristics[7]}
                                                placeholder="בחר/י את משטח העלה"
                                                onChange={handleSelectLeafSurface}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col className="pr-md-1" md="6">
                                        <FormGroup>
                                            <label style={{fontSize: "14px", fontWeight: "bold"}}>
                                                מאפיינים מיוחדים
                                            </label>
                                            <Select
                                                isMulti
                                                closeMenuOnSelect={false}
                                                isClearable={true}
                                                components={animatedComponents}
                                                options={plantCharacteristics[8]}
                                                placeholder="בחר/י מאפיינים מיוחדים"
                                                onChange={handleSelectLeafUniqueFeatures}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col className="pr-md-1" md="6">
                                        <FormGroup>
                                            <label style={{fontSize: "14px", fontWeight: "bold"}}>
                                                סידור העלים
                                            </label>
                                            <Select
                                                isMulti
                                                closeMenuOnSelect={false}
                                                isClearable={true}
                                                components={animatedComponents}
                                                options={plantCharacteristics[9]}
                                                placeholder="בחר/י סידור עלים"
                                                onChange={handleSelectLeafArrangement}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col className="pr-md-1" md="6">
                                        <FormGroup>
                                            <label style={{fontSize: "14px", fontWeight: "bold"}}>
                                                תפרחת
                                            </label>
                                            <Select
                                                isMulti
                                                closeMenuOnSelect={false}
                                                isClearable={true}
                                                components={animatedComponents}
                                                options={plantCharacteristics[10]}
                                                placeholder="בחר/י תפרחת"
                                                onChange={handleSelectInflorescence}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col className="pr-md-1" md="6">
                                        <FormGroup>
                                            <label style={{fontSize: "14px", fontWeight: "bold"}}>
                                                צבע התפרחת
                                            </label>
                                            <Select
                                                styles={colourStyles}
                                                isClearable={true}
                                                closeMenuOnSelect={false}
                                                components={animatedComponents}
                                                isMulti
                                                options={plantCharacteristics[11]}
                                                placeholder="בחר/י את צבע התפרחת"
                                                onChange={handleSelectInflorescenceColor}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <br/>

                                <Row>
                                    <Col className="pr-md-1" md="12">
                                        <FormGroup>
                                            <h4>פרטים לזיהוי הפרי</h4>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="pr-md-1" md="6">
                                        <FormGroup>
                                            <label style={{fontSize: "14px", fontWeight: "bold"}}>
                                                סוג הפרי
                                            </label>
                                            <Select
                                                isMulti
                                                isClearable={true}
                                                closeMenuOnSelect={false}
                                                components={animatedComponents}
                                                options={plantCharacteristics[12]}
                                                placeholder="בחר/י את סוג הפרי"
                                                onChange={handleSelectFruitType}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col className="pr-md-1" md="6">
                                        <FormGroup>
                                            <label style={{fontSize: "14px", fontWeight: "bold"}}>
                                                צבע הפרי
                                            </label>
                                            <Select
                                                styles={colourStyles}
                                                isClearable={true}
                                                closeMenuOnSelect={false}
                                                components={animatedComponents}
                                                isMulti
                                                options={plantCharacteristics[13]}
                                                placeholder="בחר/י את צבע הפרי"
                                                onChange={handleSelectFruitColor}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <Row>
                                    <Col className="pr-md-1 d-flex justify-content-start" md="8">
                                        <Button
                                            onClick={handleCreateImage}
                                            className="btn-fill mx-1"
                                            type="submit"
                                            style={{width: "7cm"}}
                                        >
                                            צור תמונה לזיהוי
                                        </Button>
                                    </Col>
                                </Row>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    plantCharacteristics: state.shared.plantCharacteristics,
    sendingRequest: state.articles.sendingRequest,
    failedIdentImage: state.plants.failedIdentImage,
});

export default connect(mapStateToProps, {
    uploadPlantIdentificationImage,
    getPlantIdentificationImageCharacteristics,
})(CreateIdentificationImage);
