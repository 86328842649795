import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { URL_API } from "../../../path";
import StyledDropzone from "../../components/Dropzone";
import YoutubePlayer from "react-player/youtube";
import VimeoPlayer from "react-player/vimeo";
import { getCategories, getPlants } from "redux/actions/shared";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import makeAnimated from "react-select/animated";
import TextareaAutosize from "react-textarea-autosize";
import LoadingModal from "views/components/LoadingModal";
import noImage from "../../../assets/img/no-image.jpg";
import { YouTube, Vimeo } from "../../constants";

import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  CardFooter,
  Form,
  FormGroup,
  Input,
  Badge,
  Modal,
  ModalBody,
  ModalFooter,
  CardImg,
} from "reactstrap";

import ToastError, { ERROR_10, REQUIRED_FIELD } from "../../components/Errors";

import {
  getRecipeDetails,
  updateRecipe,
  deactivateRecipe,
  activateRecipe,
  deleteGallery,
  deleteVideo,
} from "redux/actions/recipes";

let timer = null;

/**
 * Recipe Details Component
 * Shows all details and allows user to update fields, deactivate and activate the recipe
 */
const RecipeDetails = (props) => {
  const {} = props;

  const animatedComponents = makeAnimated();
  const [alert, setAlert] = useState(null);
  const [recipe, setRecipe] = useState({
    title: "",
    description: "",
    servings: "",
    preparationTime: "",
    ingredients: [],
    instructions: [],
    video: {
      url: "",
      source: "",
    },
    premium: false,
    secondaryImages: [],
    primaryImage: {
      path: "",
    },
    relatedPlants: [],
    categories: [],
    active: true,
    created_at: "",
  });

  const [errorTitle, setErrorTitle] = useState(null);
  const [errorDescription, setErrorDescription] = useState(null);
  const [errorServings, setErrorServings] = useState(null);
  const [errorPreparation, setErrorPreparation] = useState(null);
  const [errorIngredients, setErrorIngredients] = useState(null);
  const [errorInstructions, setErrorInstructions] = useState(null);
  const [errorUrl, setErrorUrl] = useState(null);

  const [showPrimary, setShowPrimary] = useState(false);
  const [showSecondary, setShowSecondary] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [imageList] = useState(["", "", "", ""]);
  const [showCategory, setShowCategory] = useState(false);
  const [showRelated, setShowRelated] = useState(false);
  const [videoUrlId, setVideoUrlId] = useState("");
  const [videoUrlInput, setVideoUrlInput] = useState("");
  const [videoSource, setVideoSource] = useState("");
  const [recipeCategories, setRecipeCategories] = useState(
    props.recipeCategories
  );
  const [secondary, setSecondary] = useState([]);
  const [primary, setPrimary] = useState(null);
  const [categories, setCategories] = useState([]);
  const [relatedPlants, setRelatedPlants] = useState([]);

  const [modalGallery, setModalGallery] = useState(false);
  const [modalVideo, setModalVideo] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [modalMessage, setModalMessage] = useState("הפעולה בוצעה בהצלחה");

  const videoOptions = [
    { value: "Youtube", label: "Youtube" },
    { value: "Vimeo", label: "Vimeo" },
  ];

  const toggleGallery = () => {
    setModalGallery(!modalGallery);
  };

  const toggleVideo = () => {
    setModalVideo(!modalVideo);
  };

  /**
   * function that deletes all secondary images
   */
  const handleDeleteGallery = () => {
    setModalGallery(!modalGallery);
    props.deleteGallery(recipe._id);
    if (props.deleteGallery) {
      setModalSuccess(!modalSuccess);
      setRecipe({ ...recipe, secondaryImages: [] });
    }
  };

  /**
   * function that deletes video
   */
  const handleDeleteVideo = () => {
    setModalVideo(!modalVideo);
    props.deleteVideo(recipe._id);
    if (props.deleteVideo) {
      setModalSuccess(!modalSuccess);
      delete recipe.video;
    }
  };

  const showAlert = (message, action, activeObj) => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="האם את/ה בטוח/ה?"
        onConfirm={() => {
          action(activeObj, recipe._id, props);
          setAlert(null);
        }}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText="אישור"
        cancelBtnText="ביטול"
        showCancel
        btnSize=""
      >
        {message}
      </ReactBSAlert>
    );
  };

  const showErrorAlert = () => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="לא הוזנו נתונים לעדכון"
        onConfirm={() => {
          setAlert(null);
        }}
        confirmBtnBsStyle="error"
        confirmBtnText="אישור"
        btnSize=""
      >
        אנא נסה/י שנית
      </ReactBSAlert>
    );
  };

  const showUpdateAlert = (toUpdate) => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="האם את/ה בטוח/ה?"
        onConfirm={() => {
          props.updateRecipe(toUpdate, recipe._id, props);
          setAlert(null);
        }}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText="אישור"
        cancelBtnText="ביטול"
        showCancel
        btnSize=""
      >
        הפעולה הבאה תעדכן את המתכון
      </ReactBSAlert>
    );
  };

  const showUpdateErrorAlert = () => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="נא למלא את כל שדות החובה"
        onConfirm={() => {
          setAlert(null);
        }}
        confirmBtnBsStyle="error"
        confirmBtnText="אישור"
        btnSize=""
      >
        שדות חובה מסומנים בכוכבית
      </ReactBSAlert>
    );
  };

  /**
   * function that handles selecting video source
   */
  const handleVideoSelect = (selectedOption) => {
    setErrorUrl(null);
    setVideoUrlId("");

    if (selectedOption !== null) {
      if (videoSource.toLowerCase() !== selectedOption.value.toLowerCase()) {
        setVideoUrlId("");
        setVideoUrlInput("");
      }

      setVideoSource(selectedOption.value);
    } else {
      setVideoSource("");
      setVideoUrlId("");
    }
  };

  /**
   * function that handles video url input
   */
  const handleVideoUrl = (e) => {
    let urlId = e.target.value;
    setVideoUrlInput(urlId);

    if (urlId !== "") {
      // TODO - commented out due to failing on the following ID (3vi-1s1Bjs4)
      // let isValidId = validateUrlID(urlId);
      //
      // console.log(isValidId);
      //
      // if (isValidId) {
      //   setVideoUrlId(urlId);
      // }

      setVideoUrlId(urlId);
      setErrorUrl(null);
    } else {
      setVideoUrlId("");
      setErrorUrl(<ToastError error={REQUIRED_FIELD} />);
    }
  };

  /**
   * function that validates url id
   */
  const validateUrlID = (urlId) => {
    let regex = /^[0-9a-zA-Z]+$/;
    if (urlId.match(regex)) {
      return true;
    }
    return false;
  };

  const handleOnChange = (e) => {
    setRecipe({ ...recipe, [e.target.name]: e.target.value });
  };

  /**
   * function that handles primary image
   */
  const handleChangePrimary = (image) => {
    setPrimary(image[0]);
  };

  /**
   * function that handles secondary image
   */
  const handleChangeSecondary = (image) => {
    setSecondary([...secondary, image[0]]);
  };

  /**
   * function that handles delete primary image
   */
  const handleDeletePrimary = () => {
    setPrimary(null);
  };

  /**
   * function that handles delete secondary image
   */
  const handleDeleteSecondary = (image) => {
    let updatedList = secondary.filter(
      (val) => val.preview === image[0].preview
    );
    setSecondary(updatedList);
  };

  /*
   * function for getting options from backend
   * using setTimeout for optimization - does not send request to backend until user stops typing
   */
  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      clearTimeout(timer);

      timer = setTimeout(async () => {
        let list = [];

        const data = await props.getPlants(0, 10, inputValue);
        list = data.plants.map((plant) => {
          return {
            id: plant._id,
            value: plant.name,
            label: plant.name,
          };
        });

        resolve(list);
      }, 500);
    });

  /**
   * function that handles selecting related plants
   */
  const handleSelectPlants = (selected) => {
    if (selected) {
      let plantsIds = selected.map((plant) => {
        return plant.id;
      });

      setRelatedPlants(plantsIds);
    } else {
      setRelatedPlants([]);
    }
  };

  const handleSelectCategories = (selected) => {
    if (selected) {
      let categoriesIds = selected.map((category) => {
        return category.id;
      });

      setCategories(categoriesIds);
    } else {
      setCategories([]);
    }
  };

  /**
   * functions that handles adding ingredients
   */
  const handleInputIngredient = (e, index) => {
    const list = [...recipe.ingredients];
    list[index] = e.target.value;
    setRecipe({ ...recipe, ingredients: list });
  };

  const handleAddIngredient = () => {
    let size = recipe.ingredients.length;
    if (recipe.ingredients[size - 1] !== "") {
      setRecipe({ ...recipe, ingredients: [...recipe.ingredients, ""] });
    }
  };

  const handleRemoveIngredient = (index) => {
    const list = [...recipe.ingredients];
    list.splice(index, 1);
    setRecipe({ ...recipe, ingredients: list });
  };

  /**
   * functions that handles adding instructions
   */
  const handleInputInstruction = (e, index) => {
    const list = [...recipe.instructions];
    list[index] = e.target.value;
    setRecipe({ ...recipe, instructions: list });
  };

  const handleAddInstruction = () => {
    let size = recipe.instructions.length;
    if (recipe.instructions[size - 1] !== "") {
      setRecipe({ ...recipe, instructions: [...recipe.instructions, ""] });
    }
  };

  const handleRemoveInstruction = (index) => {
    const list = [...recipe.instructions];
    list.splice(index, 1);
    setRecipe({ ...recipe, instructions: list });
  };

  /**
   * functions for activating / deactivating an artcile
   */
  const handleActivate = () => {
    showAlert("הפעולה הבאה תהפוך את המתכון לפעיל", props.activateRecipe, {
      active: true,
    });
  };

  const handleDeactivate = () => {
    showAlert("הפעולה הבאה תהפוך את המתכון ללא פעיל", props.deactivateRecipe, {
      active: false,
    });
  };

  /**
   * function to reset all error messages
   */
  const resetErrorMessages = () => {
    setErrorTitle(null);
    setErrorDescription(null);
    setErrorPreparation(null);
    setErrorServings(null);
    setErrorIngredients(null);
    setErrorInstructions(null);
  };

  /**
   * function thats handles recipe update
   */
  const handleUpdate = () => {
    let toUpdate = {};
    let toUpdateFlag = true;
    let size1 = recipe.ingredients.length;
    let size2 = recipe.instructions.length;

    resetErrorMessages();

    if (recipe.title === "") {
      toUpdateFlag = false;
      setErrorTitle(<ToastError error={REQUIRED_FIELD} />);
    }

    if (recipe.description === "" || recipe.description.length < 10) {
      toUpdateFlag = false;
      setErrorDescription(<ToastError error={ERROR_10} />);
    }

    if (recipe.preparationTime === "" || recipe.preparationTime < 1) {
      toUpdateFlag = false;
      setErrorPreparation(<ToastError error={REQUIRED_FIELD} />);
    }

    if (recipe.servings === "" || recipe.servings < 1) {
      toUpdateFlag = false;
      setErrorServings(<ToastError error={REQUIRED_FIELD} />);
    }

    if (
      recipe.ingredients[size1 - 1] === "" ||
      recipe.ingredients.length === 0
    ) {
      toUpdateFlag = false;
      setErrorIngredients(<ToastError error={REQUIRED_FIELD} />);
    }

    if (
      recipe.instructions[size2 - 1] === "" ||
      recipe.instructions.length === 0
    ) {
      toUpdateFlag = false;
      setErrorInstructions(<ToastError error={REQUIRED_FIELD} />);
    }

    if (toUpdateFlag) {
      if (recipe.title !== props.singleRecipe.title) {
        toUpdate = { ...toUpdate, title: recipe.title };
      }

      if (recipe.description !== props.singleRecipe.description) {
        toUpdate = { ...toUpdate, description: recipe.description };
      }

      if (recipe.preparationTime !== props.singleRecipe.preparationTime) {
        toUpdate = {
          ...toUpdate,
          preparationTime: Number(recipe.preparationTime),
        };
      }

      if (recipe.servings !== props.singleRecipe.servings) {
        toUpdate = { ...toUpdate, servings: Number(recipe.servings) };
      }

      //
      if (
        JSON.stringify(recipe.ingredients) !==
        JSON.stringify(props.singleRecipe.ingredients)
      ) {
        toUpdate = { ...toUpdate, ingredients: recipe.ingredients };
      }

      if (
        JSON.stringify(recipe.instructions) !==
        JSON.stringify(props.singleRecipe.instructions)
      ) {
        toUpdate = { ...toUpdate, instructions: recipe.instructions };
      }
      //

      if (primary !== null) {
        toUpdate = { ...toUpdate, primaryImage: primary };
      }

      if (secondary.length > 0) {
        toUpdate = { ...toUpdate, secondaryImages: secondary };
      }

      if (categories.length > 0) {
        toUpdate = { ...toUpdate, categories: categories };
      }

      if (relatedPlants.length > 0) {
        toUpdate = { ...toUpdate, relatedPlants: relatedPlants };
      }

      if (videoSource !== "" && videoUrlId !== "" && errorUrl === null) {
        toUpdate = {
          ...toUpdate,
          video: { source: videoSource.toLowerCase(), url: videoUrlId },
        };
      }

      if (Object.keys(toUpdate).length !== 0) {
        resetErrorMessages();
        showUpdateAlert(toUpdate);
      } else {
        showErrorAlert();
      }
    } else {
      showUpdateErrorAlert();
    }
  };

  /*
   * useEffect for getting current recipe's details and categories
   */
  useEffect(() => {
    props.getRecipeDetails(props.match.params.id);
    if (props.recipeCategories === null) {
      props.getCategories("recipe");
    }
  }, []);

  /*
   * useEffect for setting current article
   * Math.random was added to each image path in order to make sure that the image changes after an update
   */
  useEffect(() => {
    if (props.singleRecipe !== null) {
      setRecipe({
        ...props.singleRecipe,
        primaryImage: {
          ...props.singleRecipe.primaryImage,
          path: props.singleRecipe.primaryImage.path + "?" + Math.random(),
        },
        secondaryImages: [
          ...props.singleRecipe.secondaryImages.map((image) => ({
            path: image.path + "?" + Math.random(),
          })),
        ],
      });
    }
  }, [props.singleRecipe]);

  /**
   * useEffect for mapping the categories to id, value, label
   * needed for react-select component
   */
  useEffect(() => {
    if (props.recipeCategories !== null) {
      setRecipeCategories(
        props.recipeCategories.map((category) => {
          return {
            id: category._id,
            value: category.name,
            label: category.name,
          };
        })
      );
    }
  }, [props.recipeCategories]);

  /*
   * useEffect for checking if recipe was updated
   */
  useEffect(() => {
    setVideoSource("");
    setVideoUrlId("");
    if (props.updatedRecipe) {
      setModalSuccess(!modalSuccess);
      setShowCategory(false);
      setShowSecondary(false);
      setShowRelated(false);
      setShowPrimary(false);
      setShowVideo(false);
      props.getRecipeDetails(props.match.params.id);
    }
  }, [props.updatedRecipe]);

  useEffect(() => {
    if (props.failed) {
      setModalSuccess(props.failed);
      setModalMessage("הפעולה נכשלה");
    }
  }, [props.failed]);

  return (
    <>
      <div className="content">
        {alert}

        <LoadingModal loading={props.sendingRequest} />

        <Modal isOpen={modalSuccess} className="text-center">
          <ModalBody>
            <h4>{modalMessage}</h4>
            <Row>
              <Col md="12" className="d-flex justify-content-center">
                <Button
                  color="primary"
                  onClick={() => {
                    setModalSuccess(!modalSuccess);
                    setModalMessage("הפעולה בוצעה בהצלחה");
                  }}
                >
                  אישור
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>

        <Modal isOpen={modalGallery} className="text-right">
          <ModalBody style={{ fontSize: "16px" }}>
            פעולה זו תמחוק את הגלריה
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleGallery}>
              ביטול
            </Button>
            <Button color="primary" onClick={handleDeleteGallery}>
              אישור
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={modalVideo} className="text-right">
          <ModalBody style={{ fontSize: "16px" }}>
            פעולה זו תמחוק את הוידאו
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleVideo}>
              ביטול
            </Button>
            <Button color="primary" onClick={handleDeleteVideo}>
              אישור
            </Button>
          </ModalFooter>
        </Modal>

        <h2 className="text-center">פרטי המתכון</h2>
        <Row>
          <Col xs={12} className="text-right">
            <Link to={"/recipes"}>
              <Button
                className="btn-fill mx-1"
                color="primary"
                type="submit"
                style={{ width: "250px" }}
              >
                חזרה{" "}
              </Button>
            </Link>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="text-right">
            <Card
              style={{
                padding: "10px",
              }}
            >
              <CardBody>
                <Form>
                  <Row>
                    <Col className="pr-md-1" md="10">
                      <FormGroup>
                        <h4 style={{ margin: "0px" }}>תמונה ראשית </h4>
                        <label
                          onClick={() => {
                            setShowPrimary(!showPrimary);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          עדכון תמונה ראשית
                        </label>
                      </FormGroup>
                    </Col>
                    <Col className="pr-md-1 d-flex justify-content-end" md="2">
                      <FormGroup>
                        <label>תאריך יצירת מתכון</label>
                        <br />
                        <label>
                          {new Date(recipe.created_at).toLocaleDateString(
                            "en-GB"
                          )}
                        </label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      md="4"
                      className="pr-md-1 d-flex justify-content-start"
                    >
                      <CardImg
                        top
                        width="50%"
                        style={{ height: "370px", objectFit: "cover" }}
                        src={
                          recipe.primaryImage.path !== ""
                            ? `${URL_API}${recipe.primaryImage.path}`
                            : noImage
                        }
                        alt="תמונה ראשית"
                      />
                    </Col>
                  </Row>
                  <br />

                  {showPrimary && (
                    <Row>
                      <Col
                        className="pr-md-1 d-flex justify-content-start"
                        md="12"
                      >
                        <FormGroup>
                          <label>ניתן להעלות קבצים מסוג: png, jpg, jpeg</label>
                          <br />
                          <label>גודל קובץ עד 5MB</label>
                        </FormGroup>
                      </Col>
                      <Col
                        className="pr-md-1 d-flex justify-content-start"
                        md="12"
                      >
                        <FormGroup>
                          <StyledDropzone
                            handleFileSave={handleChangePrimary}
                            handleRemoveFile={handleDeletePrimary}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  )}

                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <h4>כותרת המתכון *</h4>
                        <Input
                          name="title"
                          value={recipe.title}
                          type="text"
                          required
                          maxLength="60"
                          onChange={handleOnChange}
                        />
                        {errorTitle}
                      </FormGroup>
                    </Col>

                    <Col
                      className="pr-md-1 d-flex justify-content-center"
                      md="2"
                    >
                      <FormGroup>
                        <h4>זמן הכנה בדקות *</h4>
                        <Input
                          name="preparationTime"
                          type="number"
                          value={recipe.preparationTime}
                          required
                          onChange={handleOnChange}
                        />
                        {errorPreparation}
                      </FormGroup>
                    </Col>

                    <Col
                      className="pr-md-1 d-flex justify-content-center"
                      md="2"
                    >
                      <FormGroup>
                        <h4>מספר מנות *</h4>
                        <Input
                          name="servings"
                          type="number"
                          value={recipe.servings}
                          required
                          onChange={handleOnChange}
                        />
                        {errorServings}
                      </FormGroup>
                    </Col>

                    <Col
                      className="pr-md-1 d-flex justify-content-center"
                      md="1"
                    >
                      <FormGroup>
                        <h4 style={{ marginBottom: "8px" }}>פרימיום</h4>
                        <Input
                          name="premium"
                          type="checkbox"
                          style={{
                            height: "40px",
                            width: "40px",
                          }}
                          checked={recipe.premium}
                          disabled
                        />
                        <br />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="pr-md-1 " md="11">
                      <FormGroup>
                        <h4>תיאור המתכון *</h4>
                        <TextareaAutosize
                          className="autosize-textarea"
                          name="description"
                          type="textarea"
                          value={recipe.description}
                          required
                          minLength="10"
                          maxLength="1000"
                          onChange={handleOnChange}
                        />
                        {errorDescription}
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="pr-md-1" md="12">
                      <h4 style={{ margin: "0" }}>מצרכים *</h4>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="pr-md-1" md="12">
                      <FormGroup>
                        <ul>
                          {recipe.ingredients.map((ingredient, index) => {
                            return (
                              <div key={index}>
                                <Col md="11">
                                  <li
                                    style={{
                                      margin: "0px 0px 10px 10px",
                                      paddingRight: "10px",
                                      color: "#3ec7af",
                                      fontSize: "25px",
                                    }}
                                  >
                                    <Input
                                      name="ingredients"
                                      type="text"
                                      required
                                      maxLength="1000"
                                      value={ingredient}
                                      onChange={(e) =>
                                        handleInputIngredient(e, index)
                                      }
                                    />
                                  </li>
                                </Col>
                                <Col md="1">
                                  {index !== 0 && (
                                    <label
                                      className="label-secondary"
                                      style={{
                                        cursor: "pointer",
                                        fontWeight: "bold",
                                        fontSize: "16px",
                                        marginTop: "8px",
                                      }}
                                      onClick={() =>
                                        handleRemoveIngredient(index)
                                      }
                                    >
                                      X
                                    </label>
                                  )}
                                </Col>
                              </div>
                            );
                          })}
                        </ul>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row> {errorIngredients}</Row>

                  <Row>
                    <label
                      onClick={handleAddIngredient}
                      style={{ cursor: "pointer", fontWeight: "bold" }}
                    >
                      הוספת מצרכים
                    </label>
                  </Row>
                  <br />

                  <Row>
                    <Col className="pr-md-1" md="12">
                      <h4 style={{ margin: "0" }}>הוראות הכנה *</h4>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="pr-md-1" md="12">
                      <FormGroup>
                        <ul>
                          {recipe.instructions.map((instruction, index) => {
                            return (
                              <div key={index}>
                                <Col md="11">
                                  <li
                                    style={{
                                      margin: "0px 0px 10px 10px",
                                      paddingRight: "10px",
                                      color: "#3ec7af",
                                      fontSize: "25px",
                                    }}
                                  >
                                    <Input
                                      name="instructions"
                                      type="text"
                                      required
                                      maxLength="1000"
                                      value={instruction}
                                      onChange={(e) =>
                                        handleInputInstruction(e, index)
                                      }
                                    />
                                  </li>
                                </Col>
                                <Col md="1">
                                  {index !== 0 && (
                                    <label
                                      className="label-secondary"
                                      style={{
                                        cursor: "pointer",
                                        fontWeight: "bold",
                                        fontSize: "16px",
                                        marginTop: "8px",
                                      }}
                                      onClick={() =>
                                        handleRemoveInstruction(index)
                                      }
                                    >
                                      X
                                    </label>
                                  )}
                                </Col>
                              </div>
                            );
                          })}
                        </ul>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>{errorInstructions}</Row>
                  <Row>
                    <label
                      onClick={handleAddInstruction}
                      style={{ cursor: "pointer", fontWeight: "bold" }}
                    >
                      הוספת הוראות
                    </label>
                  </Row>
                  <br />

                  <Row>
                    <Col className="pr-md-1" md="12">
                      <FormGroup>
                        <h4 style={{ margin: "0px" }}>קטגוריות</h4>
                        <label
                          onClick={() => {
                            setShowCategory(!showCategory);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          עדכון קטגוריות
                        </label>

                        {recipe.categories.length === 0 && (
                          <ul>
                            <li>לא נמצאו קטגוריות במערכת</li>
                          </ul>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="pr-md-1" md="12">
                      <FormGroup>
                        {recipe.categories.map((category) => {
                          return (
                            <Badge
                              color="primary"
                              key={category._id}
                              style={{
                                fontSize: "14px",
                                padding: "16px 18px",
                                margin: "0px 0px 10px 15px",
                              }}
                            >
                              {category.name}
                            </Badge>
                          );
                        })}
                      </FormGroup>
                    </Col>
                  </Row>

                  {showCategory && (
                    <Row style={{ marginTop: "-10px" }}>
                      <Col className="pr-md-1" md="12">
                        <FormGroup>
                          <Select
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            isMulti
                            options={recipeCategories}
                            placeholder="בחר/י קטגוריות"
                            onChange={handleSelectCategories}
                          />
                          <br />
                        </FormGroup>
                      </Col>
                    </Row>
                  )}

                  <Row>
                    <Col className="pr-md-1" md="12">
                      <FormGroup>
                        <h4 style={{ margin: "0px" }}>צמחים קשורים </h4>
                        <label
                          onClick={() => {
                            setShowRelated(!showRelated);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          עדכון צמחים
                        </label>

                        {recipe.relatedPlants.length === 0 && (
                          <ul>
                            <li>לא נמצאו צמחים במערכת</li>
                          </ul>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="pr-md-1" md="12">
                      <FormGroup>
                        {recipe.relatedPlants.map((plant) => {
                          // return <li key={plant._id}>{plant.name}</li>;
                          return (
                            <Link
                              to={`/plants/${plant._id}`}
                              target="_blank"
                              key={plant._id}
                            >
                              <Badge
                                color="primary"
                                style={{
                                  fontSize: "14px",
                                  padding: "16px 18px",
                                  margin: "0px 0px 10px 15px",
                                }}
                              >
                                {plant.name}
                              </Badge>
                            </Link>
                          );
                        })}
                      </FormGroup>
                    </Col>
                  </Row>

                  {showRelated && (
                    <Row style={{ marginTop: "-10px" }}>
                      <Col className="pr-md-1" md="12">
                        <FormGroup>
                          <AsyncSelect
                            isMulti
                            components={animatedComponents}
                            placeholder="הקלידו שמות צמחים"
                            loadOptions={promiseOptions}
                            onChange={handleSelectPlants}
                          />
                          <br />
                        </FormGroup>
                      </Col>
                    </Row>
                  )}

                  <Row>
                    <Col className="pr-md-1" md="12">
                      <FormGroup>
                        <h4 style={{ margin: "0px" }}>וידאו </h4>
                        <label
                          onClick={() => {
                            setShowVideo(!showVideo);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          עדכון
                        </label>
                        <label style={{ padding: "0px 10px" }}> / </label>
                        {recipe.hasOwnProperty("video") &&
                        recipe.video !== null ? (
                          <label
                            onClick={toggleVideo}
                            style={{ cursor: "pointer" }}
                          >
                            מחיקה
                          </label>
                        ) : (
                          <label style={{ color: "grey" }}>מחיקה</label>
                        )}

                        {(!recipe.hasOwnProperty("video") ||
                          recipe.video === null) && (
                          <ul>
                            <li>לא נמצא וידאו במערכת</li>
                          </ul>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="pr-md-1" md="12">
                      <FormGroup>
                        {recipe.video && ( // && !showVideo
                          <ul>
                            <li>סוג וידאו: {recipe.video.source}</li>
                            <li>
                              וידאו לינק:{" "}
                              <a
                                target="_blank"
                                href={
                                  recipe.video.source === "youtube"
                                    ? `${YouTube}${recipe.video.url}`
                                    : `${Vimeo}${recipe.video.url}`
                                }
                                style={{ color: "blue" }}
                              >
                                {recipe.video.source === "youtube"
                                  ? `${YouTube}${recipe.video.url}`
                                  : `${Vimeo}${recipe.video.url}`}
                              </a>
                            </li>
                          </ul>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>

                  {showVideo && (
                    <>
                      <Row>
                        <Col className="pr-md-1" md="5">
                          <FormGroup>
                            <h4>סוג וידאו</h4>
                            <Select
                              isClearable={true}
                              isRtl={true}
                              isSearchable={true}
                              options={videoOptions}
                              onChange={handleVideoSelect}
                            />
                          </FormGroup>
                        </Col>

                        {videoSource !== "" && (
                          <Col className="pr-md-1" md="6">
                            <FormGroup>
                              <h4>וידאו ID</h4>
                              <Input
                                name="videoLink"
                                type="text"
                                onChange={handleVideoUrl}
                                value={videoUrlInput}
                              />
                              {errorUrl}
                            </FormGroup>
                          </Col>
                        )}
                      </Row>

                      {errorUrl === null && (
                        <Row>
                          <Col className="pr-md-1" md="12">
                            {videoSource === "Youtube" && videoUrlId !== "" && (
                              <YoutubePlayer url={`${YouTube}${videoUrlId}`} />
                            )}

                            {videoSource === "Vimeo" && videoUrlId !== "" && (
                              <VimeoPlayer
                                controls={true}
                                url={`${Vimeo}${videoUrlId}`}
                              />
                            )}
                          </Col>
                        </Row>
                      )}
                    </>
                  )}
                  <br />

                  <Row>
                    <Col className="pr-md-1" md="12">
                      <FormGroup>
                        <h4 style={{ margin: "0px" }}>תמונות משניות</h4>
                        <label
                          onClick={() => {
                            setShowSecondary(!showSecondary);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          עדכון
                        </label>
                        <label style={{ padding: "0px 10px" }}> / </label>

                        {recipe.secondaryImages.length === 0 ? (
                          <label style={{ color: "grey" }}>מחיקה</label>
                        ) : (
                          <label
                            onClick={toggleGallery}
                            style={{ cursor: "pointer" }}
                          >
                            מחיקה
                          </label>
                        )}
                        {recipe.secondaryImages.length === 0 && (
                          <ul>
                            <li>לא נמצאו תמונות במערכת</li>
                          </ul>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>

                  {recipe.secondaryImages.length > 0 && (
                    <Row>
                      {recipe.secondaryImages.map((image, index) => {
                        return (
                          <Col className="pr-md-1" md="3" key={index}>
                            <FormGroup>
                              <img
                                src={
                                  image.path !== ""
                                    ? `${URL_API}${image.path}`
                                    : noImage
                                }
                                alt="תמונה משנית"
                                style={{
                                  height: "8cm",
                                  width: "13cm",
                                  paddingLeft: "10px",
                                  objectFit: "cover",
                                }}
                              />
                            </FormGroup>
                          </Col>
                        );
                      })}
                    </Row>
                  )}

                  {showSecondary && (
                    <Row>
                      <Col className="pr-md-1" md="12">
                        <FormGroup>
                          <label>ניתן להעלות קבצים מסוג: png, jpg, jpeg</label>
                          <br />
                          <label>גודל כל קובץ עד 5MB</label>
                          <h5>
                            התמונות ישמרו ויוצגו לפי הסדר הנ"ל מימין לשמאל
                          </h5>
                        </FormGroup>
                      </Col>
                      {imageList.map((val, i) => {
                        return (
                          <Col className="pr-md-1" md="3" key={i}>
                            <FormGroup>
                              <StyledDropzone
                                handleFileSave={handleChangeSecondary}
                                handleRemoveFile={handleDeleteSecondary}
                              />
                            </FormGroup>
                          </Col>
                        );
                      })}
                    </Row>
                  )}
                </Form>

                <br />
                <h4>
                  ** שימו לב! עדכון כל אחד מהשדות ידרוס את הערך הקיים במערכת!
                </h4>
                {/* <h4>
                  ** עדכון / מחיקה של השדות יתבצע אך ורק בלחיצה על כפתור עדכון
                  מתכון!
                </h4> */}
              </CardBody>
              <CardFooter>
                <Row>
                  {recipe.active ? (
                    <Col className="pr-md-1" md="4">
                      <Button
                        onClick={handleDeactivate}
                        className="btn-fill mx-1"
                        color="primary"
                        type="submit"
                        style={{ width: "7cm" }}
                      >
                        ביטול מתכון
                      </Button>
                    </Col>
                  ) : (
                    <Col className="pr-md-1" md="4">
                      <Button
                        onClick={handleActivate}
                        className="btn-fill mx-1"
                        color="primary"
                        type="submit"
                        style={{ width: "7cm" }}
                      >
                        הפעלת מתכון
                      </Button>
                    </Col>
                  )}

                  <Col className="pr-md-1 d-flex justify-content-end" md="8">
                    <Button
                      onClick={handleUpdate}
                      className="btn-fill mx-1"
                      color="primary"
                      type="submit"
                      style={{ width: "7cm" }}
                    >
                      עדכון מתכון
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  plantsList: state.shared.plantsList,
  recipeCategories: state.shared.recipeCategories,

  singleRecipe: state.recipes.singleRecipe,
  sendingRequest: state.recipes.sendingRequest,
  deleteGallery: state.recipes.deleteGallery,
  deleteVideo: state.recipes.deleteVideo,
  updatedRecipe: state.recipes.updatedRecipe,
  failed: state.recipes.failed,
});

export default connect(mapStateToProps, {
  getRecipeDetails,
  updateRecipe,
  deactivateRecipe,
  activateRecipe,
  getCategories,
  deleteGallery,
  deleteVideo,
  getPlants,
})(RecipeDetails);
