import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";
import clients from "./clients";
import admins from "./admins";
import articles from "./articles";
import recipes from "./recipes";
import plants from "./plants";
import plantSpots from "./plants-spots";
import shared from "./shared";
import dashboard from "./dashboard";

const rootReducer = combineReducers({
  auth,
  message,
  clients,
  admins,
  articles,
  recipes,
  plants,
  plantSpots,
  shared,
  dashboard,
});

export default rootReducer;
