/* 

Utilizes the spinner provided with reactstrap but also receives props to modify itself.

The props are button:boolean(removes padding), size:px | rem, to determine the size of the spinner and also color, the default it the primary set in variables. 

*/
import React from "react";
import { Spinner as Spin, Row, Col } from "reactstrap";

const Spinner = (props) => {
  return (
    <Row
      style={{
        width: "100%",
        paddingTop: props.button ? "0" : "10px",
        paddingBottom: props.button ? "0" : "10px",
        display: "flex",
        justifyContent: "center",
        margin: 0,
      }}
    >
      <Col xs={12} style={{ display: "flex", justifyContent: "center" }}>
        <Spin
          style={{ width: props.size || "3rem", height: props.size || "3rem" }}
          color={props.color || "primary"}
        />
      </Col>
    </Row>
  );
};

export default Spinner;
