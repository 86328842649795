import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactBSAlert from "react-bootstrap-sweetalert";
import makeAnimated from "react-select/animated";
import YoutubePlayer from "react-player/youtube";
import VimeoPlayer from "react-player/vimeo";
import { connect } from "react-redux";
import StyledDropzone from "../../components/Dropzone";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import { createArticle } from "../../../redux/actions/articles";
import { getCategories, getPlants } from "redux/actions/shared";
import TextareaAutosize from "react-textarea-autosize";
import { Vimeo, YouTube } from "../../constants";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  FormGroup,
  Input,
  Row,
  Col,
  Modal,
  ModalBody,
  CustomInput,
} from "reactstrap";

import ToastError, {
  REQUIRED_FIELD,
  ERROR_10,
  ERROR_VIDEO_URL,
} from "../../components/Errors";
import LoadingModal from "views/components/LoadingModal";

let timer = null;
/**
 * Component for creating an new article
 */
const CreateArticle = (props) => {
  const animatedComponents = makeAnimated();
  const [newArticle, setNewArticle] = useState({
    title: "",
    body: "",
    snippet: "",
    categories: null,
    relatedPlants: null,
    video: {
      url: "",
      source: "",
    },
    premium: false,
    secondaryImages: [],
    primaryImage: null,
    generateNotification: false,
  });

  const videoOptions = [
    { value: "youtube", label: "Youtube" },
    { value: "vimeo", label: "Vimeo" },
  ];

  const [categories, setCategories] = useState(props.articleCategories);
  const [imageList] = useState(["", "", "", ""]);
  const [alert, setAlert] = useState(null);
  const [videoUrlId, setVideoUrlId] = useState("");
  const [videoSource, setVideoSource] = useState("");
  const [videoUrlInput, setVideoUrlInput] = useState("");

  const [errorTitle, setErrorTitle] = useState(null);
  const [errorBody, setErrorBody] = useState(null);
  const [errorSnippet, setErrorSnippet] = useState(null);
  const [errorPrimary, setErrorPrimary] = useState(null);
  const [errorUrl, setErrorUrl] = useState(null);
  const [notification, setNotification] = useState(false);
  const [modalFail, setModalFail] = useState(false);

  /**
   * function that handles generate notification to clients when creating new article
   */
  const handleNotificationSwitch = () => {
    setNewArticle({ ...newArticle, generateNotification: !notification });
    setNotification(!notification);
  };

  /**
   * function that handles selecting video source
   */
  const handleVideoSelect = (selectedOption) => {
    setErrorUrl(null);

    if (selectedOption !== null) {
      if (videoSource.toLowerCase() !== selectedOption.value.toLowerCase()) {
        setVideoUrlId("");
        setVideoUrlInput("");
      }

      setVideoSource(selectedOption.value);
    } else {
      setVideoSource("");
      setVideoUrlId("");
    }
  };

  /**
   * function that handles video url input
   */
  const handleVideoUrlId = (e) => {
    let urlId = e.target.value;
    setVideoUrlInput(urlId);

    if (urlId !== "") {
      // TODO - commented out due to failing on the following ID (3vi-1s1Bjs4)
      // let isValidId = validateUrlID(urlId);
      //
      // console.log(isValidId);
      //
      // if (isValidId) {
      //   setVideoUrlId(urlId);
      // }

      setVideoUrlId(urlId);
      setErrorUrl(null);
    } else {
      setVideoUrlId("");
      setErrorUrl(<ToastError error={REQUIRED_FIELD} />);
    }
  };

  /**
   * function that validates url id
   */
  const validateUrlID = (urlId) => {
    let regex = /^[0-9a-zA-Z]+$/;
    if (urlId.match(regex)) {
      return true;
    }
    return false;
  };

  /**
   * function that handles selecting categories
   */
  const handleSelectCategories = (selected) => {
    if (selected) {
      let categoriesIds = selected.map((category) => {
        return category.id;
      });

      setNewArticle({ ...newArticle, categories: categoriesIds });
    } else {
      setNewArticle({ ...newArticle, categories: null });
    }
  };

  const handleOnChange = (e) => {
    setNewArticle({ ...newArticle, [e.target.name]: e.target.value });
  };

  /**
   * function that handles if aticle is premium, default is false
   */
  const handlePremium = () => {
    setNewArticle({ ...newArticle, premium: !newArticle.premium });
  };

  /**
   * function that handles primary image
   */
  const handleChangePrimary = (primary) => {
    setNewArticle({ ...newArticle, primaryImage: primary[0] });
  };

  /**
   * function that handles secondary image
   */
  const handleChangeSecondary = (secondary) => {
    setNewArticle({
      ...newArticle,
      secondaryImages: [...newArticle.secondaryImages, secondary[0]],
    });
  };

  /**
   * function that handles delete primary image
   */
  const handleDeletePrimary = () => {
    setNewArticle({ ...newArticle, primaryImage: null });
  };

  /**
   * function that handles delete secondary image
   */
  const handleDeleteSecondary = (secondary) => {
    let updatedList = newArticle.secondaryImages.filter(
      (val) => val.preview === secondary[0].preview
    );
    setNewArticle({
      ...newArticle,
      secondaryImages: updatedList,
    });
  };

  /*
   * function for getting options from backend
   * using setTimeout for optimization - does not send request to backend until user stops typing
   */
  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      clearTimeout(timer);

      timer = setTimeout(async () => {
        let list = [];

        const data = await props.getPlants(0, 10, inputValue);
        list = data.plants.map((plant) => {
          return {
            id: plant._id,
            value: plant.name,
            label: plant.name,
          };
        });

        resolve(list);
      }, 500);
    });

  /**
   * function that handles selecting related plants
   */
  const handleSelectPlants = (selected) => {
    if (selected) {
      let plantsIds = selected.map((plant) => {
        return plant.id;
      });

      setNewArticle({ ...newArticle, relatedPlants: plantsIds });
    } else {
      setNewArticle({ ...newArticle, relatedPlants: null });
    }
  };

  /**
   * function alert for creating new article
   */
  const showAlert = (toCreate) => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="האם את/ה בטוח/ה?"
        onConfirm={() => {
          try {
            props.createArticle(toCreate, props);
          } catch (error) {
            //console.log(error);
          }
          setAlert(null);
        }}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText="אישור"
        cancelBtnText="ביטול"
        showCancel
        btnSize=""
      >
        הפעולה הבאה תיצור מאמר חדש במערכת
      </ReactBSAlert>
    );
  };

  /**
   * function alert errors
   */
  const showErrorAlert = () => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="נא למלא את כל שדות החובה"
        onConfirm={() => {
          setAlert(null);
        }}
        confirmBtnBsStyle="error"
        confirmBtnText="אישור"
        btnSize=""
      >
        שדות חובה מסומנים בכוכבית
      </ReactBSAlert>
    );
  };

  /**
   * function to reset all error messages
   */
  const resetErrorMessages = () => {
    setErrorTitle(null);
    setErrorBody(null);
    setErrorSnippet(null);
    setErrorPrimary(null);
  };

  /**
   * function thats handles creating a new article
   */
  const handleCreateArticle = () => {
    let toCreate = {};
    let toCreateFlag = true;
    resetErrorMessages();

    if (newArticle.title === "") {
      toCreateFlag = false;
      setErrorTitle(<ToastError error={REQUIRED_FIELD} />);
    }

    if (newArticle.body.length < 10) {
      toCreateFlag = false;
      setErrorBody(<ToastError error={ERROR_10} />);
    }

    if (newArticle.snippet.length < 10) {
      toCreateFlag = false;
      setErrorSnippet(<ToastError error={ERROR_10} />);
    }

    if (newArticle.primaryImage === null) {
      toCreateFlag = false;
      setErrorPrimary(<ToastError error={REQUIRED_FIELD} />);
    }

    if (toCreateFlag) {
      toCreate = { ...toCreate, title: newArticle.title };
      toCreate = { ...toCreate, body: newArticle.body };
      toCreate = { ...toCreate, snippet: newArticle.snippet };
      toCreate = { ...toCreate, primaryImage: newArticle.primaryImage };

      if (newArticle.premium) {
        toCreate = { ...toCreate, premium: newArticle.premium };
      }

      if (newArticle.categories !== null) {
        toCreate = { ...toCreate, categories: newArticle.categories };
      }

      if (newArticle.relatedPlants !== null) {
        toCreate = { ...toCreate, relatedPlants: newArticle.relatedPlants };
      }

      if (videoSource !== "" && videoUrlId !== "" && errorUrl === null) {
        toCreate = {
          ...toCreate,
          video: { source: videoSource.toLowerCase(), url: videoUrlId },
        };
      }

      if (newArticle.secondaryImages.length > 0) {
        toCreate = { ...toCreate, secondaryImages: newArticle.secondaryImages };
      }

      toCreate = {
        ...toCreate,
        generateNotification: newArticle.generateNotification,
      };

      resetErrorMessages();

      showAlert(toCreate);
    } else {
      showErrorAlert();
    }
  };

  /**
   * useEffect for getting article's categories
   */
  useEffect(() => {
    if (props.articleCategories === null) {
      props.getCategories("article");
    }
  }, []);

  /**
   * useEffect for mapping the categories to id, value, label
   * needed for react-select component
   */
  useEffect(() => {
    if (props.articleCategories !== null) {
      setCategories(
        props.articleCategories.map((category) => {
          return {
            id: category._id,
            value: category.name,
            label: category.name,
          };
        })
      );
    }
  }, [props.articleCategories]);

  useEffect(() => {
    if (props.failed) {
      setModalFail(props.failed);
    }
  }, [props.failed]);

  return (
    <>
      {alert}
      {/* <Modal isOpen={props.sendingRequest} className="text-center">
        <ModalBody>
          <Spinner
            style={{ borderColor: "#3ec7af", width: "50px", height: "50px" }}
          />
          <h3 style={{ color: "#3ec7af" }}>אנא המתן...</h3>
        </ModalBody>
      </Modal> */}

      <LoadingModal loading={props.sendingRequest} />

      <Modal isOpen={modalFail} className="text-center">
        <ModalBody>
          <h4>הפעולה נכשלה</h4>
          <Row>
            <Col md="12" className="d-flex justify-content-center">
              <Button
                color="primary"
                onClick={() => {
                  setModalFail(!modalFail);
                }}
              >
                אישור
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>

      <div className="content">
        <h2 className="text-center">יצירת מאמר חדש</h2>
        <Row>
          <Col xs={12} className="text-right">
            <Link to={"/articles"}>
              <Button
                className="btn-fill mx-1"
                color="primary"
                type="submit"
                style={{ width: "250px" }}
              >
                חזרה{" "}
              </Button>
            </Link>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="text-right">
            {/* <Form> */}
            <Card style={{ padding: "10px" }}>
              <CardBody>
                <Row>
                  <Col className="pr-md-1" md="6">
                    <FormGroup>
                      <h4>כותרת המאמר *</h4>
                      <Input
                        name="title"
                        value={newArticle.title}
                        type="text"
                        required
                        maxLength="60"
                        onChange={handleOnChange}
                      />
                      {errorTitle}
                    </FormGroup>
                  </Col>

                  <Col className="pr-md-1 d-flex justify-content-center" md="1">
                    <FormGroup>
                      <h4 style={{ marginBottom: "8px" }}>פרימיום</h4>
                      <Input
                        name="premium"
                        type="checkbox"
                        style={{ height: "40px", width: "40px" }}
                        checked={newArticle.premium}
                        onChange={handlePremium}
                      />
                      <br />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-md-1" md="12">
                    <FormGroup>
                      <h4>גוף המאמר *</h4>
                      <TextareaAutosize
                        className="autosize-textarea"
                        value={newArticle.body}
                        onChange={handleOnChange}
                        name="body"
                        required
                        minLength="10"
                        maxLength="10000"
                      />
                      {errorBody}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-md-1" md="12">
                    <FormGroup>
                      <h4>תיאור קצר *</h4>
                      <TextareaAutosize
                        className="autosize-textarea"
                        required
                        value={newArticle.snippet}
                        name="snippet"
                        type="textarea"
                        minLength="10"
                        maxLength="200"
                        onChange={handleOnChange}
                      />
                      {errorSnippet}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-md-1" md="12">
                    <FormGroup>
                      <h4>קטגוריות</h4>
                      <Select
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        isMulti
                        options={categories}
                        placeholder="בחר/י קטגוריות"
                        onChange={handleSelectCategories}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-md-1" md="12">
                    <FormGroup>
                      <h4>צמחים</h4>
                      <AsyncSelect
                        isMulti
                        components={animatedComponents}
                        placeholder="הקלידו שמות צמחים"
                        loadOptions={promiseOptions}
                        onChange={handleSelectPlants}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-md-1" md="6">
                    <FormGroup>
                      <h4>סוג וידאו</h4>
                      <Select
                        isClearable={true}
                        isRtl={true}
                        isSearchable={true}
                        options={videoOptions}
                        onChange={handleVideoSelect}
                      />
                    </FormGroup>
                  </Col>

                  {videoSource !== "" && (
                    <Col className="px-md-1" md="6">
                      <FormGroup>
                        <h4>וידאו ID</h4>
                        <Input
                          name="videoLink"
                          type="text"
                          onChange={handleVideoUrlId}
                          value={videoUrlInput}
                        />
                        {errorUrl}
                      </FormGroup>
                    </Col>
                  )}
                </Row>

                {errorUrl === null && (
                  <Row>
                    <Col className="pr-md-1" md="12">
                      {videoSource === "youtube" && videoUrlId !== "" && (
                        <YoutubePlayer url={`${YouTube}${videoUrlId}`} />
                      )}

                      {videoSource === "vimeo" && videoUrlId !== "" && (
                        <VimeoPlayer
                          controls={true}
                          url={`${Vimeo}${videoUrlId}`}
                        />
                      )}
                    </Col>
                  </Row>
                )}

                <br />
                <Row>
                  <Col className="pr-md-1 d-flex justify-content-start" md="12">
                    <FormGroup>
                      <h4 style={{ margin: "0" }}>תמונה ראשית *</h4>
                      {errorPrimary}
                      <label>ניתן להעלות קבצים מסוג: png, jpg, jpeg</label>
                      <br />
                      <label>גודל קובץ עד 5MB</label>
                      <StyledDropzone
                        handleFileSave={handleChangePrimary}
                        handleRemoveFile={handleDeletePrimary}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col className="pr-md-1" md="12">
                    <FormGroup>
                      <h4 style={{ margin: "0" }}>תמונות משניות</h4>
                      <label>ניתן להעלות קבצים מסוג: png, jpg, jpeg</label>
                      <br />
                      <label>גודל כל קובץ עד 5MB</label>
                      <h5>התמונות ישמרו ויוצגו לפי הסדר הנ"ל מימין לשמאל</h5>
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  {imageList.map((val, i) => {
                    return (
                      <Col className="pr-md-1" md="3" key={i}>
                        <FormGroup>
                          <StyledDropzone
                            handleFileSave={handleChangeSecondary}
                            handleRemoveFile={handleDeleteSecondary}
                          />
                        </FormGroup>
                      </Col>
                    );
                  })}
                </Row>

                <br />
                <Row>
                  <Col className="pr-md-1" md="12">
                    <h4>התראת לקוחות על מאמר חדש</h4>
                    <CustomInput
                      id="switchInput"
                      type="switch"
                      label={notification ? "כן" : "לא"}
                      onChange={handleNotificationSwitch}
                      checked={notification ? true : false}
                    />
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col className="pr-md-1" md="12">
                    <Button
                      onClick={handleCreateArticle}
                      className="btn-fill mx-1"
                      color="primary"
                      type="submit"
                      style={{ width: "250px" }}
                    >
                      צור מאמר
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
            {/* </Form> */}
          </Col>
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  articleCategories: state.shared.articleCategories,
  plantsList: state.shared.plantsList,
  sendingRequest: state.articles.sendingRequest,
  failed: state.articles.failed,
});

export default connect(mapStateToProps, {
  createArticle,
  getCategories,
  getPlants,
})(CreateArticle);
