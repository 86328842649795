import React, {useMemo, useEffect, useState} from "react";
import {useDropzone} from "react-dropzone";
import imageCompression from 'browser-image-compression';

const MAX_SIZE = 5000000;

const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#1de9b6",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
    height: "250px",
    width: "300px",
};

const activeStyle = {
    borderColor: "#2196f3",
};

const acceptStyle = {
    borderColor: "#00e676",
};

const rejectStyle = {
    borderColor: "#ff1744",
};

const thumbsContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16,
};

const thumb = {
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    width: "150px",
    height: "120px",
    padding: 4,
    boxSizing: "border-box",
};

const thumbInner = {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
};

const img = {
    display: "block",
    // width: "auto",
    width: "150px",
    height: "120px",
};

export default function StyledDropzone(props) {
    const [files, setFiles] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
        acceptedFiles,
        open,
    } = useDropzone({
        // maxSize: MAX_SIZE,
        accept: "image/jpeg, image/jpg, image/png",
        noClick: true,
        noKeyboard: true,
        onDrop: async (acceptedFiles) => {
            setErrorMessage("");
            if (acceptedFiles.length > 0) {
                if (acceptedFiles[0].name.length > 100) {
                    setErrorMessage("שם קובץ חייב להיות עד 100 תווים");
                } else if (acceptedFiles[0].size > MAX_SIZE) {
                    setErrorMessage("ניתן להעלות קובץ עד 5MB");
                } else {
                    const compressedFile = await imageCompression(acceptedFiles[0], {
                        maxSizeMB: 0.5,
                        maxWidthOrHeight: 500
                    });

                    let mutatedCompressFile = new File([compressedFile], acceptedFiles[0].name, {type: acceptedFiles[0].type});

                    setFiles([mutatedCompressFile].map((file) =>
                        Object.assign(file, {
                            preview: URL.createObjectURL(file),
                        })
                    ));

                    props.handleFileSave([mutatedCompressFile]);
                    setErrorMessage("");
                }
            } else {
                setErrorMessage("לא ניתן לעלות קובץ מסוג זה");
            }
        },
    });

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isDragActive, isDragReject]
    );

    const handleRemoveImage = (e) => {
        props.handleRemoveFile(files);
        setFiles([]);
    };

    const thumbs = files.map((file) => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img src={file.preview} style={img}/>
            </div>
        </div>
    ));

    useEffect(
        () => () => {
            // Make sure to revoke the data uris to avoid memory leaks
            files.forEach((file) => URL.revokeObjectURL(file.preview));
        },
        [files]
    );

    // const filepath = acceptedFiles.map((file) => (
    //   <li key={file.path}>
    //     {file.path} - {file.size} bytes
    //   </li>
    // ));

    return (
        <div>
            <div {...getRootProps({style})}>
                <input {...getInputProps()} />
                <p style={{marginBottom: "0"}}>גררו לכאן תמונה</p>
                <aside style={thumbsContainer}>{thumbs}</aside>
                {files.length === 0 ? (
                    <button
                        type="button"
                        onClick={open}
                        className="btn"
                        style={{
                            padding: "5px 10px",
                        }}
                    >
                        בחרו תמונה
                    </button>
                ) : (
                    <button
                        type="button"
                        onClick={handleRemoveImage}
                        className="btn"
                        style={{
                            padding: "5px 10px",
                        }}
                    >
                        מחקו תמונה
                    </button>
                )}
                <p style={{color: "red"}}>
                    <br/>
                    {errorMessage}
                </p>
            </div>
        </div>
    );
}
