import {
  ADMINS_LOADED,
  ADMINS_LOAD_FAIL,
  DATA_LOADING,
  ADMIN_CREATED,
  ADMIN_CREATE_FAIL,
  ADMIN_DELETED,
  ADMIN_DELETE_FAIL,
  ADMIN_CROWNED,
  ADMIN_CROWN_FAIL,
  ADMIN_PROFILE_SUCCESS,
  ADMIN_PROFILE_FAIL,
  SENDING_DATA,
} from "../types/admins";

const initialState = {
  stats: null,
  adminsList: [],
  loading: false,
  statsLoading: false,
  // csvLink: null,
  statusCode: false,
  offset: 0,
  totalAdmins: 0,
  crowned: false,
  newAdmin: false,
  sendingRequest: false,
  updateAdmin: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case DATA_LOADING:
      return {
        ...state,
        loading: true,
        updateAdmin: false,
      };
    case SENDING_DATA:
      return {
        ...state,
        sendingRequest: action.payload,
      };
    case ADMINS_LOADED:
      return {
        ...state,
        loading: false,
        adminsList: action.payload.data.admins,
        totalAdmins: action.payload.data.entityCount,
        offset: action.payload.offsetVal,
      };
    case ADMIN_CREATED:
      return {
        ...state,
        loading: false,
        newAdmin: true,
        updateAdmin: true,
      };
    case ADMIN_CROWNED:
      return {
        ...state,
        loading: false,
        crowned: false,
      };

    case ADMIN_DELETED:
      return {
        ...state,
        loading: false,
        adminsList: [
          ...state.adminsList.filter((admin) => admin._id !== action.payload),
        ],
        updateAdmin: true,
      };
    case ADMIN_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        crowned: action.payload.crowned,
      };
    case ADMINS_LOAD_FAIL:
    case ADMIN_CROWN_FAIL:
    case ADMIN_DELETE_FAIL:
    case ADMIN_PROFILE_FAIL:
    case ADMIN_CREATE_FAIL:
      return {
        ...state,
        loading: false,
        statsLoading: false,
      };

    default:
      return state;
  }
}
