import {
  DATA_LOADING,
  ARTICLES_LOADED,
  ARTICLES_LOAD_FAIL,
  ARTICLE_LOADED,
  ARTICLE_LOAD_FAIL,
  ARTICLE_CREATED,
  ARTICLE_CREATE_FAIL,
  ARTICLE_UPDATED,
  ARTICLE_UPDATE_FAIL,
  ARTICLE_DEACTIVATED,
  ARTICLE_DEACTIVATE_FAIL,
  ARTICLE_ACTIVATED,
  ARTICLE_ACTIVATE_FAIL,
  SENDING_DATA,
  GALLERY_DELETED,
  GALLERY_DELETE_FAIL,
  VIDEO_DELETED,
  VIDEO_DELETE_FAIL,
  ARTICLES_EXPORTED,
  ARTICLES_EXPORT_FAIL,
} from "../types/articles";

const initialState = {
  stats: null,
  articlesList: [],
  loading: false,
  singleArticle: null,
  statsLoading: false,
  csvArticleData: null,
  offset: 0,
  limit: 50,
  active: true,
  totalArticles: 0,
  updatedArticle: false,
  createdArticle: false,
  deleteGallery: false,
  deleteVideo: false,
  sendingRequest: false,
  failed: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case DATA_LOADING:
      return {
        ...state,
        loading: true,
        updatedArticle: false,
        createdArticle: false,
        deleteGallery: false,
        deleteVideo: false,
        failed: false,
      };
    case SENDING_DATA:
      return {
        ...state,
        sendingRequest: action.payload,
      };
    case ARTICLES_EXPORTED:
      return {
        ...state,
        csvArticleData: action.payload,
      };
    case ARTICLES_LOADED:
      return {
        ...state,
        loading: false,
        failed: false,
        articlesList: action.payload.data.articles,
        totalArticles: action.payload.data.entityCount,
        offset: action.payload.offsetVal,
        active: action.payload.active,
        // limit: action.payload.limitVal,
      };
    case ARTICLE_LOADED:
      return {
        ...state,
        failed: false,
        loading: false,
        singleArticle: action.payload,
      };
    case ARTICLE_UPDATED:
    case ARTICLE_DEACTIVATED:
    case ARTICLE_ACTIVATED:
      return {
        ...state,
        loading: false,
        updatedArticle: true,
      };
    case ARTICLE_CREATED:
      return {
        ...state,
        loading: false,
        createdArticle: true,
      };
    case GALLERY_DELETED:
      return {
        ...state,
        loading: false,
        deleteGallery: true,
      };
    case VIDEO_DELETED:
      return {
        ...state,
        loading: false,
        deleteVideo: true,
      };
    case GALLERY_DELETE_FAIL:
    case VIDEO_DELETE_FAIL:
    case ARTICLE_CREATE_FAIL:
    case ARTICLE_UPDATE_FAIL:
    case ARTICLE_ACTIVATE_FAIL:
    case ARTICLE_DEACTIVATE_FAIL:
      return {
        ...state,
        loading: false,
        statsLoading: false,
        failed: true,
      };
    case ARTICLES_LOAD_FAIL:
    case ARTICLE_LOAD_FAIL:
    case ARTICLES_EXPORT_FAIL:
      return {
        ...state,
        loading: false,
        statsLoading: false,
      };
    default:
      return state;
  }
}
