import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import ToastError, {REQUIRED_FIELD} from "../../components/Errors";
import {Link} from "react-router-dom";
import ReactBSAlert from "react-bootstrap-sweetalert";
// import noImage from "../../../assets/img/no-image-found.png";
import avatar from "../../../assets/img/default-avatar.png";
import {URL_API} from "../../../path";
import LoadingModal from "views/components/LoadingModal";

import {
    Button,
    Card,
    CardBody,
    Row,
    Col,
    CardFooter,
    Form,
    FormGroup,
    Modal,
    ModalBody,
} from "reactstrap";

import {
    getClient,
    deactivateClient,
    createManualSubscription,
    deactivateSubscription,
} from "../../../redux/actions/clients";
import ReactDatetime from "react-datetime";
import articles from "redux/reducers/articles";

/**
 * ClientDetails Component for viewing a client's details
 * Actions - delete client's subscription or create new subscrition
 */
const ClientDetails = (props) => {
    const [client, setClient] = useState({
        name: "",
        clientEmail: "",
        phone: "",
        birthDate: "",
        profileCreated: "",
        premium: {
            active: false,
            type: "",
            expirationDate: "",
        },
        profilePicture: "",
    });

    const [alert, setAlert] = useState(null);
    const [subscription, setSubscription] = useState(false);
    const [expirationDate, setExpirationDate] = useState("");
    const [errorDate, setErrorDate] = useState(null);
    const [modalSuccess, setModalSuccess] = useState(false);

    const handleDate = (selected) => {
        if (selected) {
            setExpirationDate(selected._d);
        } else {
            setExpirationDate("");
        }
    };

    /**
     * method that handles deleting a client
     */
    const showDeleteClientAlert = () => {
        setAlert(
            <ReactBSAlert
                warning
                style={{display: "block", marginTop: "-100px"}}
                title="האם את/ה בטוח/ה?"
                onConfirm={() => {
                    props.deactivateClient(client._id, props);
                    setAlert(null);
                }}
                onCancel={() => setAlert(null)}
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                confirmBtnText="אישור"
                cancelBtnText="ביטול"
                showCancel
                btnSize=""
            >
                הפעולה הבאה תמחק את הלקוח מהמערכת
            </ReactBSAlert>
        );
    };

    /**
     * method that handles deleting a client's subscription
     */
    const showDeleteSubscriptionAlert = () => {
        setAlert(
            <ReactBSAlert
                warning
                style={{display: "block", marginTop: "-100px"}}
                title="האם את/ה בטוח/ה?"
                onConfirm={async () => {
                    props.deactivateSubscription(client._id, props);
                    setAlert(null);
                    props.getClient(props.match.params.id); // refresh state
                }}
                onCancel={() => setAlert(null)}
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                confirmBtnText="אישור"
                cancelBtnText="ביטול"
                showCancel
                btnSize=""
            >
                הפעולה הבאה תבטל את המנוי של הלקוח
            </ReactBSAlert>
        );
    };

    /**
     * method that handles creating a new subscription
     */
    const showCreateSubscriptionAlert = () => {
        let currentDate = new Date();

        if (expirationDate > currentDate && expirationDate !== "") {
            setErrorDate(null);
            setAlert(
                <ReactBSAlert
                    warning
                    style={{display: "block", marginTop: "-100px"}}
                    title="האם את/ה בטוח/ה?"
                    onConfirm={() => {
                        props.createManualSubscription(client._id, expirationDate, props);
                        setAlert(null);
                    }}
                    onCancel={() => setAlert(null)}
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    confirmBtnText="אישור"
                    cancelBtnText="ביטול"
                    showCancel
                    btnSize=""
                >
                    הפעולה הבאה תיצור מנוי ללקוח
                </ReactBSAlert>
            );
        } else {
            setErrorDate(<ToastError error={"נא לבחור תאריך עתידי"}/>);
        }

        if (expirationDate === "") {
            setErrorDate(<ToastError error={REQUIRED_FIELD}/>);
        }
    };

    /**
     * useEffect for getting current client's details
     */
    useEffect(() => {
        props.getClient(props.match.params.id);
    }, []);

    /**
     * useEffect for setting client state
     */
    useEffect(() => {
        if (props.singleClient !== null) {
            setClient(props.singleClient);
        }
    }, [props.singleClient]);

    useEffect(() => {
        if (props.updateClient) {
            setModalSuccess(!modalSuccess);
            setSubscription(false);
            props.getClient(props.match.params.id);
        }
    }, [props.updateClient]);

    return (
        <>
            <div className="content">
                {alert}

                <LoadingModal loading={props.sendingRequest}/>

                <Modal isOpen={modalSuccess} className="text-center">
                    <ModalBody>
                        <h4>הפעולה בוצעה בהצלחה</h4>
                        <Row>
                            <Col md="12" className="d-flex justify-content-center">
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        setModalSuccess(!modalSuccess);
                                    }}
                                >
                                    אישור
                                </Button>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>

                <h2 className="text-center">פרופיל לקוח</h2>

                <Row>
                    <Col xs={12} className="text-right">
                        <Link to={"/clients"}>
                            <Button
                                className="btn-fill mx-1"
                                color="primary"
                                type="submit"
                                style={{width: "250px"}}
                            >
                                חזרה{" "}
                            </Button>
                        </Link>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="text-right">
                        <Card style={{padding: "10px"}}>
                            <CardBody>
                                <Form>
                                    <Row>
                                        <Col className="pr-md-1" md="8">
                                            <FormGroup>
                                                <h4 style={{color: "#3ec7af"}}>תמונת פרופיל</h4>
                                                <img
                                                    src={
                                                        client.hasOwnProperty("profilePicture")
                                                            ? URL_API + client.profilePicture.path
                                                            : avatar
                                                    }
                                                    alt="profilePicture"
                                                    style={{
                                                        borderRadius: "50%",
                                                        border: "2px solid black",
                                                        height: "5cm",
                                                        width: "5cm",
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col className="pr-md-1 d-flex justify-content-end" md="4">
                                            <FormGroup>
                                                <label>תאריך יצירת פרופיל</label>
                                                <br/>
                                                <label style={{fontSize: "14px"}}>
                                                    {new Date(client.created_at).toLocaleDateString(
                                                        "en-GB"
                                                    )}
                                                </label>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col className="pr-md-1" md="3">
                                            <FormGroup>
                                                <h4 style={{color: "#3ec7af"}}>
                                                    שם מלא:
                                                    <p>{client.name}</p>
                                                </h4>
                                            </FormGroup>
                                        </Col>

                                        <Col className="pr-md-1" md="3">
                                            <FormGroup>
                                                <h4 style={{color: "#3ec7af"}}>
                                                    כתובת אימייל: <p>{client.clientEmail}</p>
                                                </h4>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="pr-md-1" md="3">
                                            <FormGroup>
                                                <h4 style={{color: "#3ec7af"}}>
                                                    טלפון: <p>{client.phone.replace(/\+972/, "0")}</p>
                                                </h4>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col className="pr-md-1" md="3">
                                            <FormGroup>
                                                <h4 style={{color: "#3ec7af"}}>
                                                    פרימיום: <p>{client.premium.active ? "כן" : "לא"}</p>
                                                </h4>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        {client.premium.active && (
                                            <>
                                                <Col className="pr-md-1" md="3">
                                                    <FormGroup>
                                                        <h4 style={{color: "#3ec7af"}}>
                                                            תאריך תוקף מנוי:{" "}
                                                            <p>
                                                                {new Date(
                                                                    client.premium.expirationDate
                                                                ).toLocaleDateString("en-GB")}
                                                            </p>
                                                        </h4>
                                                    </FormGroup>
                                                </Col>

                                                <Col className="pr-md-1" md="3">
                                                    <FormGroup>
                                                        <h4 style={{color: "#3ec7af"}}>
                                                            סוג מנוי: <p>{client.premium.type}</p>
                                                        </h4>
                                                    </FormGroup>
                                                </Col>

                                                <Col className="pr-md-1" md="3">
                                                    <FormGroup>
                                                        <h4 style={{color: "#3ec7af"}}>
                                                            סטטוס מנוי: <p>{client.premium.status}</p>
                                                        </h4>
                                                    </FormGroup>
                                                </Col>
                                            </>
                                        )}
                                    </Row>
                                </Form>
                            </CardBody>
                            <br/>
                            <CardFooter>
                                <Row>
                                    <Col className="pr-md-1" md="8">
                                        {client.premium.active && (
                                            <Button
                                                onClick={showDeleteSubscriptionAlert}
                                                className="btn-fill mx-1"
                                                color="primary"
                                                type="submit"
                                                style={{width: "250px"}}
                                            >
                                                ביטול מנוי
                                            </Button>
                                        )}
                                        {!subscription && !client.premium.active && (
                                            <Button
                                                onClick={() => {
                                                    setSubscription(true);
                                                }}
                                                className="btn-fill mx-1"
                                                color="primary"
                                                type="submit"
                                                style={{width: "250px"}}
                                            >
                                                צור מנוי
                                            </Button>
                                        )}
                                    </Col>
                                    <Col className="pr-md-1 d-flex justify-content-end" md="4">
                                        <Button
                                            onClick={showDeleteClientAlert}
                                            className="btn-fill mx-1"
                                            color="primary"
                                            type="submit"
                                            style={{width: "250px"}}
                                        >
                                            מחק לקוח
                                        </Button>
                                    </Col>
                                </Row>

                                {subscription && (
                                    <div>
                                        <br/>
                                        <hr
                                            style={{backgroundColor: "#3ec7af", fontWeight: "bold"}}
                                        />
                                        <Row>
                                            <Col className="pr-md-1" md="3">
                                                <h5>תאריך תוקף מנוי</h5>
                                                <FormGroup>
                                                    {/* <Input
                            name="expirationDate"
                            value={expirationDate}
                            type="date"
                            onChange={handleDate}
                            required
                          /> */}
                                                    <ReactDatetime
                                                        inputProps={{
                                                            className: "form-control",
                                                            placeholder: "בחרו תאריך",
                                                        }}
                                                        timeFormat={false}
                                                        onChange={handleDate}
                                                        required
                                                    />
                                                    {errorDate}
                                                </FormGroup>
                                            </Col>
                                            <Col className="pr-md-1" md="4">
                                                <Button
                                                    onClick={showCreateSubscriptionAlert}
                                                    className="btn-fill mx-1"
                                                    color="primary"
                                                    type="submit"
                                                    style={{
                                                        width: "120px",
                                                        marginTop: "32px",
                                                        padding: "10px 10px",
                                                    }}
                                                >
                                                    צור מנוי
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                )}
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    singleClient: state.clients.singleClient,
    sendingRequest: state.clients.sendingRequest,
    updateClient: state.clients.updateClient,
});

export default connect(mapStateToProps, {
    getClient,
    deactivateClient,
    createManualSubscription,
    deactivateSubscription,
})(ClientDetails);
