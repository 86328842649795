import { requestWrapper, supportedRequestMethods } from "../../API";
import { createMessage } from "./message";
import {
  DATA_LOADING,
  RECIPES_LOADED,
  RECIPES_LOAD_FAIL,
  RECIPE_LOADED,
  RECIPE_LOAD_FAIL,
  RECIPE_CREATED,
  RECIPE_CREATE_FAIL,
  RECIPE_UPDATED,
  RECIPE_UPDATE_FAIL,
  RECIPE_ACTIVATED,
  RECIPE_ACTIVATE_FAIL,
  RECIPE_DEACTIVATED,
  RECIPE_DEACTIVATE_FAIL,
  SENDING_DATA,
  GALLERY_DELETED,
  GALLERY_DELETE_FAIL,
  VIDEO_DELETED,
  VIDEO_DELETE_FAIL,
  RECIPES_EXPORTED,
  RECIPES_EXPORT_FAIL,
} from "../types/recipes";

/**
 * async function that sends a request to get recipes
 */
export const getRecipes = (offset, limit, active, query) => async (
  dispatch
) => {
  dispatch({ type: DATA_LOADING });
  let endpointString = "";
  if (query === "") {
    endpointString = `/admin/recipes?offset=${offset}&limit=${limit}&active=${active}`;
  } else {
    endpointString = `/admin/recipes?offset=${offset}&limit=${limit}&active=${active}&query=${query}`;
  }
  let requestParams = {
    method: supportedRequestMethods.GET,
    endpoint: endpointString,
  };
  let response = await requestWrapper(requestParams);
  if (response && response.success) {
    dispatch({
      type: RECIPES_LOADED,
      payload: {
        data: response.data.data,
        offsetVal: offset,
        limitVal: limit,
        active: active,
      },
    });
  } else {
    if (response) dispatch(createMessage(response.data.message));
    dispatch({
      type: RECIPES_LOAD_FAIL,
    });
  }
};

/**
 * async function that sends a request to get an recipe's details with the id
 */
export const getRecipeDetails = (id) => async (dispatch) => {
  dispatch({ type: DATA_LOADING });
  let requestParams = {
    method: supportedRequestMethods.GET,
    endpoint: `/admin/recipes/${id}`,
  };
  let response = await requestWrapper(requestParams);

  if (response && response.success) {
    dispatch({
      type: RECIPE_LOADED,
      payload: response.data.data,
    });
  } else {
    if (response) dispatch(createMessage(response.data.message));
    dispatch({
      type: RECIPE_LOAD_FAIL,
    });
  }
};

/**
 * async function that sends a request to create a new recipe
 */
export const createRecipe = (recipeObj, props) => async (dispatch) => {
  dispatch({ type: DATA_LOADING });

  const formData = new FormData();

  Object.keys(recipeObj).forEach((key) => {
    if (
      key !== "primaryImage" &&
      key !== "secondaryImages" &&
      typeof recipeObj[key] !== "string"
    ) {
      formData.append(key, JSON.stringify(recipeObj[key]));
    } else if (key === "primaryImage" || typeof recipeObj[key] === "string") {
      formData.append(key, recipeObj[key]);
    } else if (key === "secondaryImages") {
      recipeObj[key].forEach((file) => {
        formData.append(key, file);
      });
    }
  });

  let requestParams = {
    method: supportedRequestMethods.POST,
    endpoint: `/admin/recipes/`,
    body: formData,
  };

  dispatch({ type: SENDING_DATA, payload: true });
  let response = await requestWrapper(requestParams);
  dispatch({ type: SENDING_DATA, payload: false });

  if (response && response.success) {
    dispatch({
      type: RECIPE_CREATED,
    });
    props.history.push("/recipes");
  } else {
    if (response) dispatch(createMessage(response.data.message));
    dispatch({
      type: RECIPE_CREATE_FAIL,
    });
  }
};

/**
 * async function that sends a request to update a recipe
 */
export const updateRecipe = (recipe, id, props) => async (dispatch) => {
  dispatch({ type: DATA_LOADING });

  const formData = new FormData();

  Object.keys(recipe).forEach((key) => {
    if (
      key !== "primaryImage" &&
      key !== "secondaryImages" &&
      typeof recipe[key] !== "string"
    ) {
      formData.append(key, JSON.stringify(recipe[key]));
    } else if (key === "primaryImage" || typeof recipe[key] === "string") {
      formData.append(key, recipe[key]);
    } else if (key === "secondaryImages") {
      recipe[key].forEach((file) => {
        formData.append(key, file);
      });
    }
  });

  let requestParams = {
    method: supportedRequestMethods.PATCH,
    endpoint: `/admin/recipes/${id}`,
    body: formData,
  };

  dispatch({ type: SENDING_DATA, payload: true });
  let response = await requestWrapper(requestParams);
  dispatch({ type: SENDING_DATA, payload: false });

  if (response && response.success) {
    dispatch({
      type: RECIPE_UPDATED,
    });
  } else {
    if (response) dispatch(createMessage(response.data.message));
    dispatch({
      type: RECIPE_UPDATE_FAIL,
    });
  }
};

/**
 * async function that sends a request to deactivate a recipe
 */
export const deactivateRecipe = (recipe, id, props) => async (dispatch) => {
  dispatch({ type: DATA_LOADING });

  const formData = new FormData();

  Object.keys(recipe).forEach((key) => {
    if (key && Array.isArray(recipe[key])) {
      formData.append(key, JSON.stringify(recipe[key]));
    } else if (key) {
      formData.append(key, recipe[key]);
    }
  });

  let requestParams = {
    method: supportedRequestMethods.PATCH,
    endpoint: `/admin/recipes/${id}`,
    body: formData,
  };

  dispatch({ type: SENDING_DATA, payload: true });
  let response = await requestWrapper(requestParams);
  dispatch({ type: SENDING_DATA, payload: false });

  if (response && response.success) {
    dispatch({
      type: RECIPE_DEACTIVATED,
    });
  } else {
    if (response) dispatch(createMessage(response.data.message));
    dispatch({
      type: RECIPE_DEACTIVATE_FAIL,
    });
  }
};

/**
 * async function that sends a request to activate a recipe
 */
export const activateRecipe = (recipe, id, props) => async (dispatch) => {
  dispatch({ type: DATA_LOADING });

  const formData = new FormData();

  Object.keys(recipe).forEach((key) => {
    if (key && Array.isArray(recipe[key])) {
      formData.append(key, JSON.stringify(recipe[key]));
    } else if (key) {
      formData.append(key, recipe[key]);
    }
  });

  let requestParams = {
    method: supportedRequestMethods.PATCH,
    endpoint: `/admin/recipes/${id}`,
    body: formData,
  };

  dispatch({ type: SENDING_DATA, payload: true });
  let response = await requestWrapper(requestParams);
  dispatch({ type: SENDING_DATA, payload: false });

  if (response && response.success) {
    dispatch({
      type: RECIPE_ACTIVATED,
    });
  } else {
    if (response) dispatch(createMessage(response.data.message));
    dispatch({
      type: RECIPE_ACTIVATE_FAIL,
    });
  }
};

/**
 * async function that sends a request to delete the recipe's gallery
 */
export const deleteGallery = (id) => async (dispatch) => {
  dispatch({ type: DATA_LOADING });

  let requestParams = {
    method: supportedRequestMethods.DELETE,
    endpoint: `/admin/recipes/${id}/gallery`,
  };

  dispatch({ type: SENDING_DATA, payload: true });
  let response = await requestWrapper(requestParams);
  dispatch({ type: SENDING_DATA, payload: false });

  if (response && response.success) {
    dispatch({
      type: GALLERY_DELETED,
    });
  } else {
    if (response) dispatch(createMessage(response.data.message));
    dispatch({
      type: GALLERY_DELETE_FAIL,
    });
  }
};

/**
 * async function that sends a request to delete the recipe's video
 */
export const deleteVideo = (id) => async (dispatch) => {
  dispatch({ type: DATA_LOADING });

  let requestParams = {
    method: supportedRequestMethods.DELETE,
    endpoint: `/admin/recipes/${id}/video`,
  };

  dispatch({ type: SENDING_DATA, payload: true });
  let response = await requestWrapper(requestParams);
  dispatch({ type: SENDING_DATA, payload: false });

  if (response && response.success) {
    dispatch({
      type: VIDEO_DELETED,
    });
  } else {
    if (response) dispatch(createMessage(response.data.message));
    dispatch({
      type: VIDEO_DELETE_FAIL,
    });
  }
};

/**
 * async function that sends a request to get recipes data
 */
export const exportRecipes = () => async (dispatch) => {
  dispatch({ type: DATA_LOADING });

  let requestParams = {
    method: supportedRequestMethods.GET,
    endpoint: `/admin/recipes/export-csv`,
  };

  dispatch({ type: SENDING_DATA, payload: true });
  let response = await requestWrapper(requestParams);
  dispatch({ type: SENDING_DATA, payload: false });

  if (response && response.success) {
    dispatch({
      type: RECIPES_EXPORTED,
      payload: response.data,
    });
  } else {
    if (response) dispatch(createMessage(response.data.message));
    dispatch({
      type: RECIPES_EXPORT_FAIL,
    });
  }
};
