import { Redirect, Route } from 'react-router-dom';
import React from 'react';
import { connect } from 'react-redux';
import Spinner from './Spinner';

// A wrapper for <Route> that redirects to the login page if the user is not authenticated
const PrivateRoute = ({ component: Component, auth, ...rest }) => {
	// verify if the user is authenticated first before sending him to the login page

	return (
		<Route
			{...rest}
			render={(props) => {
				if (auth.verifyingSession) {
					return <Spinner />;
				} else if (!auth.authenticated) {
					return <Redirect to="/login" />;
				} else {
					return <Component {...props} />;
				}
			}}
		/>
	);
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});

export default connect(mapStateToProps, {})(PrivateRoute);
