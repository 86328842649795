/*

Using the notifications alert provided in the template and a custom error reducer this can display alert on any screen. Only provide a string for the alert text.

See error actions in the redux folder for more details.

Placed at the App component inside some container. 

*/
import React, { Component } from "react";
import NotificationAlert from "react-notification-alert";
import { connect } from "react-redux";

let options = {};

class Alert extends Component {
  notify() {
    this.refs.notify.notificationAlert(options);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.error !== this.props.error &&
      this.props.error !== "" &&
      this.props.error !== undefined
    ) {
      // console.log(this.props.error);

      options = {
        place: "tl",
        message: this.props.error,
        type: "primary",
        icon: "now-ui-icons ui-1_bell-53",
        autoDismiss: 30,
      };
      this.refs.notify.notificationAlert(options);
    }
  }
  render() {
    return (
      <div>
        <NotificationAlert ref="notify" />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  error: state.message.message.text,
});

export default connect(mapStateToProps, {})(Alert);
