export const DATA_LOADING = "DATA_LOADING";

export const SENDING_DATA = "SENDING_DATA";

export const ARTICLES_LOADED = "ARTICLES_LOADED";
export const ARTICLES_LOAD_FAIL = "ARTICLES_LOAD_FAIL";

export const ARTICLE_LOADED = "ARTICLE_LOADED";
export const ARTICLE_LOAD_FAIL = "ARTICLE_LOAD_FAIL";

export const ARTICLE_CREATED = "ARTICLE_CREATED";
export const ARTICLE_CREATE_FAIL = "ARTICLE_CREATE_FAIL";

export const ARTICLE_UPDATED = "ARTICLE_UPDATED";
export const ARTICLE_UPDATE_FAIL = "ARTICLE_UPDATE_FAIL";

export const ARTICLE_ACTIVATED = "ARTICLE_ACTIVATED";
export const ARTICLE_ACTIVATE_FAIL = "ARTICLE_ACTIVATE_FAIL";

export const ARTICLE_DEACTIVATED = "ARTICLE_DEACTIVATED";
export const ARTICLE_DEACTIVATE_FAIL = "ARTICLE_DEACTIVATE_FAIL";

export const GALLERY_DELETED = "GALLERY_DELETED";
export const GALLERY_DELETE_FAIL = "GALLERY_DELETE_FAIL";

export const VIDEO_DELETED = "VIDEO_DELETED";
export const VIDEO_DELETE_FAIL = "VIDEO_DELETE_FAIL";

export const ARTICLES_EXPORTED = "ARTICLES_EXPORTED";
export const ARTICLES_EXPORT_FAIL = "ARTICLES_EXPORT_FAIL";
