import {
  DATA_LOADING,
  RECIPES_LOADED,
  RECIPES_LOAD_FAIL,
  RECIPE_LOADED,
  RECIPE_LOAD_FAIL,
  RECIPE_CREATED,
  RECIPE_CREATE_FAIL,
  RECIPE_UPDATED,
  RECIPE_UPDATE_FAIL,
  RECIPE_ACTIVATED,
  RECIPE_ACTIVATE_FAIL,
  RECIPE_DEACTIVATED,
  RECIPE_DEACTIVATE_FAIL,
  SENDING_DATA,
  GALLERY_DELETED,
  VIDEO_DELETED,
  VIDEO_DELETE_FAIL,
  GALLERY_DELETE_FAIL,
  RECIPES_EXPORTED,
  RECIPES_EXPORT_FAIL,
} from "../types/recipes";

const initialState = {
  stats: null,
  recipesList: [],
  loading: false,
  singleRecipe: null,
  statsLoading: false,
  csvRecipeData: null,
  offset: 0,
  limit: 50,
  active: true,
  totalRecipes: 0,
  categories: null,
  updatedRecipe: false,
  createdRecipe: false,
  sendingRequest: false,
  deleteGallery: false,
  deleteVideo: false,
  failed: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case DATA_LOADING:
      return {
        ...state,
        loading: true,
        updatedRecipe: false,
        createdRecipe: false,
        deleteGallery: false,
        deleteVideo: false,
        failed: false,
      };
    case SENDING_DATA:
      return {
        ...state,
        sendingRequest: action.payload,
      };
    case RECIPES_EXPORTED:
      return {
        ...state,
        csvRecipeData: action.payload,
      };
    case RECIPES_LOADED:
      return {
        ...state,
        failed: false,
        loading: false,
        recipesList: action.payload.data.recipes,
        totalRecipes: action.payload.data.entityCount,
        offset: action.payload.offsetVal,
        active: action.payload.active,
      };
    case RECIPE_LOADED:
      return {
        ...state,
        loading: false,
        failed: false,
        singleRecipe: action.payload,
      };
    case RECIPE_UPDATED:
    case RECIPE_DEACTIVATED:
    case RECIPE_ACTIVATED:
      return {
        ...state,
        loading: false,
        updatedRecipe: true,
      };
    case RECIPE_CREATED:
      return {
        ...state,
        loading: false,
        createdRecipe: true,
      };
    case GALLERY_DELETED:
      return {
        ...state,
        loading: false,
        deleteGallery: true,
      };

    case VIDEO_DELETED:
      return {
        ...state,
        loading: false,
        deleteVideo: true,
      };
    case GALLERY_DELETE_FAIL:
    case VIDEO_DELETE_FAIL:
    case RECIPE_UPDATE_FAIL:
    case RECIPE_ACTIVATE_FAIL:
    case RECIPE_DEACTIVATE_FAIL:
    case RECIPE_CREATE_FAIL:
      return {
        ...state,
        loading: false,
        statsLoading: false,
        failed: true,
      };

    case RECIPES_LOAD_FAIL:
    case RECIPE_LOAD_FAIL:
    case RECIPES_EXPORT_FAIL:
      return {
        ...state,
        loading: false,
        statsLoading: false,
      };
    default:
      return state;
  }
}
