import { requestWrapper, supportedRequestMethods } from "../../API";
import { createMessage } from "./message";
import {
  DATA_LOADING,
  ARTICLES_LOADED,
  ARTICLES_LOAD_FAIL,
  ARTICLE_LOADED,
  ARTICLE_LOAD_FAIL,
  ARTICLE_CREATED,
  ARTICLE_CREATE_FAIL,
  ARTICLE_UPDATED,
  ARTICLE_UPDATE_FAIL,
  ARTICLE_ACTIVATED,
  ARTICLE_ACTIVATE_FAIL,
  ARTICLE_DEACTIVATED,
  ARTICLE_DEACTIVATE_FAIL,
  SENDING_DATA,
  GALLERY_DELETED,
  GALLERY_DELETE_FAIL,
  VIDEO_DELETED,
  VIDEO_DELETE_FAIL,
  ARTICLES_EXPORTED,
  ARTICLES_EXPORT_FAIL,
} from "../types/articles";

/**
 * async function that sends a request to get articles
 */
export const getArticles = (offset, limit, active, query) => async (
  dispatch
) => {
  dispatch({ type: DATA_LOADING });
  let endpointString = "";
  if (query === "") {
    endpointString = `/admin/articles?offset=${offset}&limit=${limit}&active=${active}`;
  } else {
    endpointString = `/admin/articles?offset=${offset}&limit=${limit}&active=${active}&query=${query}`;
  }
  let requestParams = {
    method: supportedRequestMethods.GET,
    endpoint: endpointString,
  };
  let response = await requestWrapper(requestParams);
  if (response && response.success) {
    dispatch({
      type: ARTICLES_LOADED,
      payload: {
        data: response.data.data,
        offsetVal: offset,
        limitVal: limit,
        active: active,
      },
    });
  } else {
    if (response) dispatch(createMessage(response.data.message));
    dispatch({
      type: ARTICLES_LOAD_FAIL,
    });
  }
};

/**
 * async function that sends a request to get an article's details with the id
 */
export const getArticleDetails = (id) => async (dispatch) => {
  dispatch({ type: DATA_LOADING });
  let requestParams = {
    method: supportedRequestMethods.GET,
    endpoint: `/admin/articles/${id}`,
  };
  let response = await requestWrapper(requestParams);

  if (response && response.success) {
    dispatch({
      type: ARTICLE_LOADED,
      payload: response.data.data,
    });
  } else {
    if (response) dispatch(createMessage(response.data.message));
    dispatch({
      type: ARTICLE_LOAD_FAIL,
    });
  }
};

/**
 * async function that sends a request to create a new article
 */
export const createArticle = (articleObj, props) => async (dispatch) => {
  dispatch({ type: DATA_LOADING });

  const formData = new FormData();

  Object.keys(articleObj).forEach((key) => {
    // console.log(`key: ${key}`);
    if (
      key !== "primaryImage" &&
      key !== "secondaryImages" &&
      typeof articleObj[key] !== "string"
    ) {
      // console.log(`Converting ${key} to a string`)
      formData.append(key, JSON.stringify(articleObj[key]));
    } else if (key === "primaryImage" || typeof articleObj[key] === "string") {
      formData.append(key, articleObj[key]);
    } else if (key === "secondaryImages") {
      articleObj[key].forEach((file) => {
        formData.append(key, file);
      });
    }
  });

  let requestParams = {
    method: supportedRequestMethods.POST,
    endpoint: `/admin/articles/`,
    body: formData,
  };

  dispatch({ type: SENDING_DATA, payload: true });
  let response = await requestWrapper(requestParams);
  dispatch({ type: SENDING_DATA, payload: false });

  if (response && response.success) {
    dispatch({
      type: ARTICLE_CREATED,
    });
    props.history.push("/articles");
  } else {
    if (response) dispatch(createMessage(response.data.message));
    dispatch({
      type: ARTICLE_CREATE_FAIL,
    });
  }
};

/**
 * async function that sends a request to update an article
 */
export const updateArticle = (article, id, props) => async (dispatch) => {
  dispatch({ type: DATA_LOADING });

  const formData = new FormData();

  Object.keys(article).forEach((key) => {
    // console.log(`key: ${key}`);
    if (
      key !== "primaryImage" &&
      key !== "secondaryImages" &&
      typeof article[key] !== "string"
    ) {
      formData.append(key, JSON.stringify(article[key]));
    } else if (key === "primaryImage" || typeof article[key] === "string") {
      formData.append(key, article[key]);
    } else if (key === "secondaryImages") {
      article[key].forEach((file) => {
        formData.append(key, file);
      });
    }
  });

  let requestParams = {
    method: supportedRequestMethods.PATCH,
    endpoint: `/admin/articles/${id}`,
    body: formData,
  };

  dispatch({ type: SENDING_DATA, payload: true });
  let response = await requestWrapper(requestParams);
  dispatch({ type: SENDING_DATA, payload: false });

  if (response && response.success) {
    dispatch({
      type: ARTICLE_UPDATED,
    });
  } else {
    if (response) dispatch(createMessage(response.data.message));
    dispatch({
      type: ARTICLE_UPDATE_FAIL,
    });
  }
};

/**
 * async function that sends a request to deactivate an article
 */
export const deactivateArticle = (activeObj, id, props) => async (dispatch) => {
  dispatch({ type: DATA_LOADING });

  const formData = new FormData();

  Object.keys(activeObj).forEach((key) => {
    if (key && Array.isArray(activeObj[key])) {
      formData.append(key, JSON.stringify(activeObj[key]));
    } else if (key) {
      formData.append(key, activeObj[key]);
    }
  });

  let requestParams = {
    method: supportedRequestMethods.PATCH,
    endpoint: `/admin/articles/${id}`,
    body: formData,
  };

  dispatch({ type: SENDING_DATA, payload: true });
  let response = await requestWrapper(requestParams);
  dispatch({ type: SENDING_DATA, payload: false });

  if (response && response.success) {
    dispatch({
      type: ARTICLE_DEACTIVATED,
    });
  } else {
    if (response) dispatch(createMessage(response.data.message));
    dispatch({
      type: ARTICLE_DEACTIVATE_FAIL,
    });
  }
};

/**
 * async function that sends a request to activate an article
 */
export const activateArticle = (activeObj, id, props) => async (dispatch) => {
  dispatch({ type: DATA_LOADING });

  const formData = new FormData();

  Object.keys(activeObj).forEach((key) => {
    if (key && Array.isArray(activeObj[key])) {
      formData.append(key, JSON.stringify(activeObj[key]));
    } else if (key) {
      formData.append(key, activeObj[key]);
    }
  });

  let requestParams = {
    method: supportedRequestMethods.PATCH,
    endpoint: `/admin/articles/${id}`,
    body: formData,
  };

  dispatch({ type: SENDING_DATA, payload: true });
  let response = await requestWrapper(requestParams);
  dispatch({ type: SENDING_DATA, payload: false });

  if (response && response.success) {
    dispatch({
      type: ARTICLE_ACTIVATED,
    });
  } else {
    if (response) dispatch(createMessage(response.data.message));
    dispatch({
      type: ARTICLE_ACTIVATE_FAIL,
    });
  }
};

/**
 * async function that sends a request to delete the article's gallery
 */
export const deleteGallery = (id) => async (dispatch) => {
  dispatch({ type: DATA_LOADING });

  let requestParams = {
    method: supportedRequestMethods.DELETE,
    endpoint: `/admin/articles/${id}/gallery`,
  };

  dispatch({ type: SENDING_DATA, payload: true });
  let response = await requestWrapper(requestParams);
  dispatch({ type: SENDING_DATA, payload: false });

  if (response && response.success) {
    dispatch({
      type: GALLERY_DELETED,
    });
  } else {
    if (response) dispatch(createMessage(response.data.message));
    dispatch({
      type: GALLERY_DELETE_FAIL,
    });
  }
};

/**
 * async function that sends a request to delete the article's video
 */
export const deleteVideo = (id) => async (dispatch) => {
  dispatch({ type: DATA_LOADING });

  let requestParams = {
    method: supportedRequestMethods.DELETE,
    endpoint: `/admin/articles/${id}/video`,
  };

  dispatch({ type: SENDING_DATA, payload: true });
  let response = await requestWrapper(requestParams);
  dispatch({ type: SENDING_DATA, payload: false });

  if (response && response.success) {
    dispatch({
      type: VIDEO_DELETED,
    });
  } else {
    if (response) dispatch(createMessage(response.data.message));
    dispatch({
      type: VIDEO_DELETE_FAIL,
    });
  }
};

/**
 * async function that sends a request to get articles data
 */
export const exportArticles = () => async (dispatch) => {
  dispatch({ type: DATA_LOADING });

  let requestParams = {
    method: supportedRequestMethods.GET,
    endpoint: `/admin/articles/export-csv`,
  };

  dispatch({ type: SENDING_DATA, payload: true });
  let response = await requestWrapper(requestParams);
  dispatch({ type: SENDING_DATA, payload: false });

  if (response && response.success) {
    dispatch({
      type: ARTICLES_EXPORTED,
      payload: response.data,
    });
  } else {
    if (response) dispatch(createMessage(response.data.message));
    dispatch({
      type: ARTICLES_EXPORT_FAIL,
    });
  }
};
