import Dashboard from "views/pages/Dashboard.js";
import ClientsList from "views/pages/clients/ClientsList.js";
import AdminsList from "views/pages/admins/AdminsList.js";
import ClientDetails from "views/pages/clients/ClientDetails.js";
import CreateAdmin from "views/pages/admins/CreateAdmin.js";
import ArticlesList from "views/pages/articles/ArticlesList.js";
import ArticleDetails from "views/pages/articles/ArticleDetails.js";
import CreateArticle from "views/pages/articles/CreateArticle.js";
import RecipesList from "views/pages/recipes/RecipesList.js";
import RecipeDetails from "views/pages/recipes/RecipeDetails.js";
import CreateRecipe from "views/pages/recipes/CreateRecipe.js";
import PlantsList from "views/pages/plants/PlantsList.js";
import PlantDetails from "views/pages/plants/PlantDetails.js";
import CreatePlant from "views/pages/plants/CreatePlant.js";
import PlantsSpotList from "views/pages/plants-spots/PlantsSpotList.js";
import CreatePlantSpot from "views/pages/plants-spots/CreatePlantSpot.js";
import CreateIdentificationImage from "views/pages/plants/CreateIdentificationImage";
import IdentificationImageDetails from "views/pages/plants/IdentificationImageDetails";
import CreateNotification from "./views/pages/CreateNotification";

const routes = [
  {
    path: "/",
    name: `פאנל ניהול`,
    rtlName: "סטטיסטיקה",
    icon: "tim-icons icon-chart-bar-32",
    component: Dashboard,
  },
  {
    path: "/dashboard",
    name: `סטטיסטיקה`,
    rtlName: "סטטיסטיקה",
    icon: "tim-icons icon-chart-bar-32",
    component: Dashboard,
  },
  {
    path: "/clients",
    name: `לקוחות`,
    rtlName: "רשימת לקוחות",
    icon: "tim-icons icon-single-02",
    component: ClientsList,
  },
  {
    path: "/admins",
    name: `מנהלי מערכת`,
    rtlName: "רשימת מנהלי מערכת",
    icon: "tim-icons icon-single-02",
    component: AdminsList,
  },
  {
    path: "/create-notification",
    name: `שליחת התראת מערכת`,
    rtlName: "שליחת התראת מערכת",
    icon: "tim-icons icon-send",
    component: CreateNotification,
  },
  {
    path: "/articles",
    name: `מאמרים`,
    rtlName: "רשימת מאמרים",
    icon: "tim-icons icon-bullet-list-67",
    component: ArticlesList,
  },
  {
    path: "/recipes",
    name: "מתכונים",
    rtlName: "רשימת מתכונים",
    icon: "tim-icons icon-bullet-list-67",
    component: RecipesList,
  },
  {
    path: "/plants",
    name: `צמחים`,
    rtlName: "רשימת צמחים",
    icon: "tim-icons icon-bullet-list-67",
    component: PlantsList,
  },
  {
    path: "/plant-spots",
    name: `ספוטים של צמחים`,
    rtlName: "רשימת ספוטים של צמחים",
    icon: "tim-icons icon-square-pin",
    component: PlantsSpotList,
  },
  {
    path: "/clients/:id",
    name: `פרופיל לקוח`,
    rtlName: "פרופיל לקוח",
    icon: "tim-icons icon-chart-pie-36",
    component: ClientDetails,
  },
  {
    path: "/admins/create",
    name: `יצירת מנהל מערכת`,
    rtlName: "יצירת מנהל מערכת",
    icon: "tim-icons icon-chart-pie-36",
    component: CreateAdmin,
  },
  {
    path: "/articles/create",
    name: `יצירת מאמר`,
    icon: "tim-icons icon-chart-pie-36",
    component: CreateArticle,
  },
  {
    path: "/articles/:id",
    name: `פרטי המאמר`,
    icon: "tim-icons icon-chart-pie-36",
    component: ArticleDetails,
  },
  {
    path: "/recipes/create",
    name: `יצירת מתכון`,
    icon: "tim-icons icon-chart-pie-36",
    component: CreateRecipe,
  },
  {
    path: "/recipes/:id",
    name: `פרטי המתכון`,
    icon: "tim-icons icon-chart-pie-36",
    component: RecipeDetails,
  },
  {
    path: "/plants/create",
    name: `יצירת צמח`,
    icon: "tim-icons icon-chart-pie-36",
    component: CreatePlant,
  },
  {
    path: "/plants/:id",
    name: `פרטי הצמח`,
    icon: "tim-icons icon-chart-pie-36",
    component: PlantDetails,
  },
  {
    path: "/plant-spots/create",
    name: `יצירת ספוט צמח`,
    icon: "tim-icons icon-chart-pie-36",
    component: CreatePlantSpot,
  },
  {
    path: "/plants/:id/identification-images/create",
    name: `יצירת צמח לזיהוי`,
    icon: "tim-icons icon-chart-pie-36",
    component: CreateIdentificationImage,
  },
  {
    path: "/plants/:id/identification-images/:image",
    name: `פרטי צמח לזיהוי`,
    icon: "tim-icons icon-chart-pie-36",
    component: IdentificationImageDetails,
  },
];

export default routes;

export const adminRoutes = [
  {
    path: "/dashboard",
    name: `סטטיסטיקה`,
    rtlName: "סטטיסטיקה",
    icon: "tim-icons icon-chart-bar-32",
    component: Dashboard,
  },
  {
    path: "/clients",
    name: `לקוחות`,
    rtlName: "רשימת לקוחות",
    icon: "tim-icons icon-single-02",
    component: ClientsList,
  },

  {
    path: "/articles",
    name: `מאמרים`,
    rtlName: "רשימת מאמרים",
    icon: "tim-icons icon-bullet-list-67",
    component: ArticlesList,
  },
  {
    path: "/recipes",
    name: `מתכונים`,
    rtlName: "רשימת מתכונים",
    icon: "tim-icons icon-bullet-list-67",
    component: RecipesList,
  },
  {
    path: "/plants",
    name: `צמחים`,
    rtlName: "רשימת צמחים",
    icon: "tim-icons icon-bullet-list-67",
    component: PlantsList,
  },
  {
    path: "/plant-spots",
    name: `ספוטים של צמחים`,
    rtlName: "רשימת ספוטים של צמחים",
    icon: "tim-icons icon-square-pin",
    component: PlantsSpotList,
  },
  {
    path: "/create-notification",
    name: `שליחת התראת מערכת`,
    rtlName: "שליחת התראת מערכת",
    icon: "tim-icons icon-send",
    component: CreateNotification,
  },
];

export const crownedAdminRoutes = [
  ...adminRoutes,
  {
    path: "/admins",
    name: `מנהלי מערכת`,
    rtlName: "רשימת מנהלי מערכת",
    icon: "tim-icons icon-single-02",
    component: AdminsList,
  },
];
