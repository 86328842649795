export const DATA_LOADING = "DATA_LOADING";

export const SENDING_DATA = "SENDING_DATA";

export const PLANTS_LOADED = "PLANTS_LOADED";
export const PLANTS_LOAD_FAIL = "PLANTS_LOAD_FAIL";

export const PLANT_LOADED = "PLANT_LOADED";
export const PLANT_LOAD_FAIL = "PLANT_LOAD_FAIL";

export const PLANT_CREATED = "PLANT_CREATED";
export const PLANT_CREATE_FAIL = "PLANT_CREATE_FAIL";

export const PLANT_UPDATED = "PLANT_UPDATED";
export const PLANT_UPDATE_FAIL = "PLANT_UPDATE_FAIL";

export const PLANT_ACTIVATED = "PLANT_ACTIVATED";
export const PLANT_ACTIVATE_FAIL = "PLANT_ACTIVATE_FAIL";

export const PLANT_DEACTIVATED = "PLANT_DEACTIVATED";
export const PLANT_DEACTIVATE_FAIL = "PLANT_DEACTIVATE_FAIL";

export const PLANT_CATEGORIES = "PLANT_CATEGORIES";
export const PLANT_CATEGORIES_FAIL = "PLANT_CATEGORIES_FAIL";

export const GALLERY_DELETED = "GALLERY_DELETED";
export const GALLERY_DELETE_FAIL = "GALLERY_DELETE_FAIL";

export const PLANTS_EXPORTED = "PLANTS_EXPORTED";
export const PLANTS_EXPORT_FAIL = "PLANTS_EXPORT_FAIL";

// PLANT IDENTIFICATION IMAGES
export const IDENTIFICATION_IMAGES_LOADED = "IDENTIFICATION_IMAGES_LOADED";
export const IDENTIFICATION_IMAGES_LOAD_FAIL =
  "IDENTIFICATION_IMAGES_LOAD_FAIL";

export const IDENTIFICATION_IMAGE_DETAILS_LOADED =
  "IDENTIFICATION_IMAGE_DETAILS_LOADED";
export const IDENTIFICATION_IMAGE_DETAILS_LOAD_FAIL =
  "IDENTIFICATION_IMAGE_DETAILS_LOAD_FAIL";

export const IDENTIFICATION_IMAGE_DELETED = "IDENTIFICATION_IMAGE_DELETED";
export const IDENTIFICATION_IMAGE_DELETE_FAIL =
  "IDENTIFICATION_IMAGE_DELETE_FAIL";

export const IDENTIFICATION_IMAGE_UPLOADED = "IDENTIFICATION_IMAGE_UPLOADED";
export const IDENTIFICATION_IMAGE_UPLOAD_FAIL =
  "IDENTIFICATION_IMAGE_UPLOAD_FAIL";
