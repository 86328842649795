import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import store from "redux/store";
import App from "App";
import * as Sentry from "@sentry/react";

import "assets/css/nucleo-icons.css";
import "react-notification-alert/dist/animate.css";
import "assets/scss/black-dashboard-pro-react.scss?v=1.1.0";
import "assets/demo/demo.css";
import "assets/css/custom.css";
import Alert from "./components/Alert";

Sentry.init({
  dsn:
    "https://89f4ce37381942b8ac1255fe098ea11f@o348190.ingest.sentry.io/5278509",
  environment: process.env.REACT_APP_ENV,
});

const hist = createBrowserHistory();

ReactDOM.render(
  <Provider store={store}>
    <div className="rna-container">
      <Alert />
    </div>
    <Router history={hist}>
      <App />
    </Router>
  </Provider>,
  document.getElementById("root")
);
