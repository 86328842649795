import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import ReactBSAlert from "react-bootstrap-sweetalert";
import {URL_API} from "../../../path";
import LoadingModal from "views/components/LoadingModal";
import noImage from "../../../assets/img/no-image.jpg";

import {
    deletePlantIdentificationImage,
    getPlantIdentificationImageDetails,
} from "../../../redux/actions/plants";

import {
    Button,
    Card,
    CardBody,
    Row,
    Col,
    CardFooter,
    FormGroup,
    Modal,
    ModalBody,
    CardImg,
} from "reactstrap";

const colorNameMap = {
    white: 'White',
    black: 'Black',
    pink: 'Pink',
    '#97DEA1': 'Green',
    '#FF3D57': 'Pastel pink',
    '#BA9866': 'Brown',
    '#C95A5A': 'Brick pink',
    '#8B0749': 'Magenta pink',
    '#FFC664': 'Orange',
    '#F8F897': 'Yellow',
    '#2970FF': 'Caribbean blue',
    '#8D4683': 'Eggplant purple',
}

/**
 * Identification Image Details Component
 * Shows all details and allows user to delete the image
 */
const IdentificationImageDetails = (props) => {
    const {} = props;

    const [identificationDetails, setIdentificationDetails] = useState({
        image: {path: ""},
        created_at: "",
        habitat: [],
        growthState: [],
        leafArrangement: [],
        leafBase: [],
        leafContour: [],
        leafShape: [],
        leafStructure: [],
        leafSurface: [],
        leafTips: [],
        leafUniqueFeatures: [],
        inflorescence: [],
        inflorescenceColor: [],
        fruitType: [],
        fruitColor: [],
    });
    const [alert, setAlert] = useState(null);
    const [modalFail, setModalFail] = useState(false);

    const handleDeleteImage = () => {
        showAlert();
    };

    /**
     * function that handles deleting an identification image
     */
    const showAlert = () => {
        setAlert(
            <ReactBSAlert
                warning
                style={{display: "block", marginTop: "-100px"}}
                title="האם את/ה בטוח/ה?"
                onConfirm={() => {
                    props.deletePlantIdentificationImage(
                        props.match.params.id,
                        props.match.params.image,
                        props
                    );
                    setAlert(null);
                }}
                onCancel={() => {
                    setAlert(null);
                }}
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                confirmBtnText="אישור"
                cancelBtnText="ביטול"
                showCancel
                btnSize=""
            >
                הפעולה הבאה תמחק את התמונה לזיהוי הצמח
            </ReactBSAlert>
        );
    };

    useEffect(() => {
        props.getPlantIdentificationImageDetails(
            props.match.params.id,
            props.match.params.image
        );
    }, []);

    useEffect(() => {
        if (props.identificationDetails) {
            setIdentificationDetails(props.identificationDetails);
        }
    }, [props.identificationDetails]);

    useEffect(() => {
        if (props.failedIdentImage) {
            setModalFail(props.failedIdentImage);
        }
    }, [props.failedIdentImage]);

    return (
        <>
            <div className="content">
                {alert}

                <LoadingModal loading={props.sendingRequest}/>

                <Modal isOpen={modalFail} className="text-center">
                    <ModalBody>
                        <h4>הפעולה נכשלה</h4>
                        <Row>
                            <Col md="12" className="d-flex justify-content-center">
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        setModalFail(!modalFail);
                                    }}
                                >
                                    אישור
                                </Button>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>

                <h2 className="text-center">פרטי התמונה לזיהוי הצמח</h2>

                <Row>
                    <Col xs={12} className="d-flex justify-content-start">
                        <Link
                            to={{
                                pathname: `/plants/${props.match.params.id}`,
                                data: true,
                            }}
                        >
                            <Button
                                className="btn-fill mx-1"
                                color="primary"
                                type="submit"
                                style={{width: "250px"}}
                            >
                                חזרה{" "}
                            </Button>
                        </Link>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} className="text-right">
                        <Card
                            style={{
                                padding: "10px",
                            }}
                        >
                            <CardBody>
                                <Row>
                                    <Col className="pr-md-1" md="4">
                                        <FormGroup>
                                            <h4>תמונת הצמח</h4>
                                            <CardImg
                                                top
                                                width="50%"
                                                style={{height: "370px", objectFit: "cover"}}
                                                src={
                                                    identificationDetails.image.path !== ""
                                                        ? `${URL_API}${identificationDetails.image.path}`
                                                        : noImage
                                                }
                                                alt="תמונה הצמח"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col className="pr-md-1 d-flex justify-content-end" md="8">
                                        <FormGroup>
                                            <label>תאריך יצירת תמונה</label>
                                            <br/>
                                            <label>
                                                {new Date(
                                                    identificationDetails.created_at
                                                ).toLocaleDateString("en-GB")}
                                            </label>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <br/>

                                {identificationDetails.habitat.length > 0 && (
                                    <Row>
                                        <Col className="pr-md-1" md="12">
                                            <FormGroup>
                                                <h4>סביבה</h4>
                                                <ul>
                                                    {identificationDetails.habitat?.map((habitat) => {
                                                        return (
                                                            <li key={habitat._id}>
                                                                {habitat.characteristic}
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                )}

                                {identificationDetails.growthState.length > 0 && (
                                    <Row>
                                        <Col className="pr-md-1" md="6">
                                            <FormGroup>
                                                <h4>סוג הצמח</h4>
                                                <ul>
                                                    {identificationDetails.growthState.map((growth) => {
                                                        return (
                                                            <li key={growth._id}>{growth.characteristic}</li>
                                                        );
                                                    })}
                                                </ul>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                )}

                                <Row>
                                    <Col className="pr-md-1" md="12">
                                        <FormGroup>
                                            <h4>פרטים לזיהוי העלה</h4>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                {identificationDetails.leafStructure.length > 0 && (
                                    <Row>
                                        <Col className="pr-md-1" md="6">
                                            <FormGroup>
                                                <label style={{fontSize: "14px"}}>מבנה העלה</label>
                                                <ul>
                                                    {identificationDetails.leafStructure.map(
                                                        (structure) => {
                                                            return (
                                                                <li key={structure._id}>
                                                                    {structure.characteristic}
                                                                </li>
                                                            );
                                                        }
                                                    )}
                                                </ul>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                )}

                                {identificationDetails.leafShape.length > 0 && (
                                    <Row>
                                        <Col className="pr-md-1" md="6">
                                            <FormGroup>
                                                <label style={{fontSize: "14px"}}>צורת העלה</label>
                                                <ul>
                                                    {identificationDetails.leafShape.map((shape) => {
                                                        return (
                                                            <li key={shape._id}>{shape.characteristic}</li>
                                                        );
                                                    })}
                                                </ul>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                )}

                                {identificationDetails.leafTips.length > 0 && (
                                    <Row>
                                        <Col className="pr-md-1" md="6">
                                            <FormGroup>
                                                <label style={{fontSize: "14px"}}>קצה העלה</label>
                                                <ul>
                                                    {identificationDetails.leafTips.map((tip) => {
                                                        return <li key={tip._id}>{tip.characteristic}</li>;
                                                    })}
                                                </ul>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                )}

                                {identificationDetails.leafBase.length > 0 && (
                                    <Row>
                                        <Col className="pr-md-1" md="6">
                                            <FormGroup>
                                                <label style={{fontSize: "14px"}}>בסיס העלה</label>
                                                <ul>
                                                    {identificationDetails.leafBase.map((base) => {
                                                        return (
                                                            <li key={base._id}>{base.characteristic}</li>
                                                        );
                                                    })}
                                                </ul>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                )}

                                {identificationDetails.leafContour.length > 0 && (
                                    <Row>
                                        <Col className="pr-md-1" md="6">
                                            <FormGroup>
                                                <label style={{fontSize: "14px"}}>מתאר העלה</label>
                                                <ul>
                                                    {identificationDetails.leafContour.map((contour) => {
                                                        return (
                                                            <li key={contour._id}>
                                                                {contour.characteristic}
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                )}

                                {identificationDetails.leafSurface.length > 0 && (
                                    <Row>
                                        <Col className="pr-md-1" md="6">
                                            <FormGroup>
                                                <label style={{fontSize: "14px"}}>משטח העלה</label>
                                                <ul>
                                                    {identificationDetails.leafSurface.map((surface) => {
                                                        return (
                                                            <li key={surface._id}>
                                                                {surface.characteristic}
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                )}

                                {identificationDetails.leafUniqueFeatures.length > 0 && (
                                    <Row>
                                        <Col className="pr-md-1" md="6">
                                            <FormGroup>
                                                <label style={{fontSize: "14px"}}>
                                                    מאפיינים מיוחדים
                                                </label>
                                                <ul>
                                                    {identificationDetails.leafUniqueFeatures.map(
                                                        (feature) => {
                                                            return (
                                                                <li key={feature._id}>
                                                                    {feature.characteristic}
                                                                </li>
                                                            );
                                                        }
                                                    )}
                                                </ul>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                )}

                                {identificationDetails.leafArrangement.length > 0 && (
                                    <Row>
                                        <Col className="pr-md-1" md="6">
                                            <FormGroup>
                                                <label style={{fontSize: "14px"}}>סידור העלים</label>
                                                <ul>
                                                    {identificationDetails.leafArrangement.map(
                                                        (arrangement) => {
                                                            return (
                                                                <li key={arrangement._id}>
                                                                    {arrangement.characteristic}
                                                                </li>
                                                            );
                                                        }
                                                    )}
                                                </ul>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                )}
                                {identificationDetails.inflorescence.length > 0 && (
                                    <Row>
                                        <Col className="pr-md-1" md="6">
                                            <FormGroup>
                                                <label style={{fontSize: "14px"}}>תפרחת</label>
                                                <ul>
                                                    {identificationDetails.inflorescence.map(
                                                        (inflorescence) => {
                                                            return (
                                                                <li key={inflorescence._id}>
                                                                    {inflorescence.characteristic}
                                                                </li>
                                                            );
                                                        }
                                                    )}
                                                </ul>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                )}
                                <br/>

                                {(identificationDetails.fruitType.length > 0 ||
                                    identificationDetails.fruitColor.length > 0) && (
                                    <Row>
                                        <Col className="pr-md-1" md="12">
                                            <FormGroup>
                                                <h4>פרטים לזיהוי הפרי</h4>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                )}

                                {identificationDetails.fruitType.length > 0 && (
                                    <Row>
                                        <Col className="pr-md-1" md="6">
                                            <FormGroup>
                                                <label style={{fontSize: "14px"}}>סוג הפרי</label>
                                                <ul>
                                                    {identificationDetails.fruitType.map((type) => {
                                                        return (
                                                            <li key={type._id}>{type.characteristic}</li>
                                                        );
                                                    })}
                                                </ul>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                )}

                                {identificationDetails.fruitColor.length > 0 && (
                                    <Row>
                                        <Col className="pr-md-1" md="6">
                                            <FormGroup>
                                                <label style={{fontSize: "14px"}}>צבע הפרי</label>
                                                <ul>
                                                    {identificationDetails.fruitColor.map((color) => {
                                                        return (
                                                            <li key={color._id}>
                                                                <span
                                                                    style={{
                                                                        paddingRight: 10,
                                                                        paddingLeft: 10,
                                                                        backgroundColor: `${color.characteristic}`
                                                                    }}/>
                                                                {`${colorNameMap[color.characteristic]}`}
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                )}
                            </CardBody>
                            <CardFooter>
                                <Row>
                                    <Col className="pr-md-1 d-flex justify-content-end" md="12">
                                        <Button
                                            onClick={handleDeleteImage}
                                            className="btn-fill mx-1"
                                            type="submit"
                                            style={{width: "7cm"}}
                                        >
                                            מחיקת תמונה
                                        </Button>
                                    </Col>
                                </Row>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    identificationDetails: state.plants.identificationDetails,
    sendingRequest: state.plants.sendingRequest,
    failedIdentImage: state.plants.failedIdentImage,
});

export default connect(mapStateToProps, {
    deletePlantIdentificationImage,
    getPlantIdentificationImageDetails,
})(IdentificationImageDetails);
