import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Link } from "react-router-dom";
import StyledDropzone from "../../components/Dropzone";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { getCategories } from "redux/actions/shared";
import { createPlant } from "redux/actions/plants";
import TextareaAutosize from "react-textarea-autosize";
import LoadingModal from "views/components/LoadingModal";
import YoutubePlayer from "react-player/youtube";
import VimeoPlayer from "react-player/vimeo";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  FormGroup,
  Input,
  Row,
  Col,
  Modal,
  ModalBody,
} from "reactstrap";
import ToastError, {
  ERROR_10,
  TITLE_ERROR,
  REQUIRED_FIELD,
  ERROR_INFORMATION,
  ERROR_BENEFITS,
} from "../../components/Errors";
import {Vimeo, YouTube} from "../../constants";

/**
 * Component for creating an new plant
 */
const CreatePlant = (props) => {
  const animatedComponents = makeAnimated();

  const [newPlant, setNewPlant] = useState({
    name: "",
    scientificName: "",
    family: "",
    description: "",
    endangered: false,
    toxic: false,
    information: {
      whereToFind: "",
      identificationDetails: "",
      warning: "",
      preparation: "",
      composition: "",
      trivia: "",
    },
    video: {
      url: "",
      source: "",
    },
    secondaryImages: [],
    primaryImage: null,
    categories: [],
  });

  const videoOptions = [
    {value: "youtube", label: "Youtube"},
    {value: "vimeo", label: "Vimeo"},
  ];

  const [alert, setAlert] = useState(null);
  const [imageList] = useState(["", "", "", ""]);
  const [errorName, setErrorName] = useState(null);
  const [errorDescription, setErrorDescription] = useState(null);
  const [errorScientific, setErrorScientific] = useState(null);
  const [errorFamily, setErrorFamily] = useState(null);
  const [errorInformation, setErrorInformation] = useState(null);
  const [error, setError] = useState(null);
  const [errorBenefits, setErrorBenefits] = useState(null);
  const [errorPrimary, setErrorPrimary] = useState(null);
  const [benefitsList, setBenefitsList] = useState([{ type: "", details: "" }]);
  const [videoUrlId, setVideoUrlId] = useState("");
  const [videoSource, setVideoSource] = useState("");
  const [errorUrl, setErrorUrl] = useState(null);

  const [categories, setCategories] = useState();
  const [subCategories, setSubCategories] = useState([]);
  const [selectedParentCategory, setSelectedParentCategory] = useState(false);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [subCategoriesOptions, setSubCategoriesOptions] = useState([]);
  const [modalFail, setModalFail] = useState(false);

  /**
   * function that handles selecting video source
   */
  const handleVideoSelect = (selectedOption) => {
    setErrorUrl(null);

    if (selectedOption !== null) {
      setVideoSource(selectedOption.value);
      setNewPlant({
        ...newPlant,
        video: {...newPlant.video, source: selectedOption.value},
      });
    } else {
      setVideoSource("");
      setVideoUrlId("");
    }
  };

  /**
   * function that handles video url input
   */
  const handleVideoUrlId = (e) => {
    let urlID = e.target.value;
    setVideoUrlId(urlID);

    if (urlID !== "") {
      setErrorUrl(null);
      setNewPlant({
        ...newPlant,
        video: {...newPlant.video, url: urlID},
      });
    } else {
      setErrorUrl(<ToastError error={REQUIRED_FIELD}/>);
    }
  };

  /**
   * function that handles selecting sub categories
   */
  const handleSelectCategories = (selected) => {
    if (selected) {
      console.log(selected);
      setSelectedSubCategories(selected);
    } else {
      setSelectedSubCategories([]);
    }
  };

  /**
   * function that handles selecting parent categories
   */
  const handleSelectParentCategories = (selected) => {
    if (selected !== null) {
      setNewPlant({
        ...newPlant,
        categories: selected,
      });

      let subByParent = [];
      selected.forEach((parent) => {
        let currentSubs = subCategories.filter(
          (sub) => sub.parentCategory === parent._id
        );
        subByParent = [...subByParent, ...currentSubs];
      });

      setSubCategoriesOptions(subByParent);
      if (subByParent.length > 0) {
        setSelectedParentCategory(true);
      }
    } else {
      setNewPlant({ ...newPlant, categories: [] });
      setSelectedParentCategory(false);
    }
  };

  const handleOnChange = (e) => {
    setNewPlant({ ...newPlant, [e.target.name]: e.target.value });
  };

  /**
   * function that handles selecting if plant is toxic and/or endangered
   */
  const handleOnCheck = (e, value) => {
    setNewPlant({ ...newPlant, [e.target.name]: !value });
  };

  /**
   * function that handles plant's information
   */
  const handleOnChangeInformation = (e) => {
    setNewPlant({
      ...newPlant,
      information: {
        ...newPlant.information,
        [e.target.name]: e.target.value,
      },
    });
  };

  /**
   * function that handles if aticle is premium, default is false
   */
  const handleChangePrimary = (primary) => {
    setNewPlant({ ...newPlant, primaryImage: primary[0] });
  };

  const handleChangeSecondary = (secondary) => {
    setNewPlant({
      ...newPlant,
      secondaryImages: [...newPlant.secondaryImages, secondary[0]],
    });
  };

  const handleDeletePrimary = () => {
    setNewPlant({ ...newPlant, primaryImage: null });
  };

  const handleDeleteSecondary = (secondary) => {
    let updatedList = newPlant.secondaryImages.filter(
      (val) => val.preview === secondary[0].preview
    );
    setNewPlant({
      ...newPlant,
      secondaryImages: updatedList,
    });
  };

  /**
   * functions that handles plant's benefits
   */
  const handleInputBenefit = (e, index) => {
    const list = [...benefitsList];
    const benefitObj = { ...list[index], [e.target.name]: e.target.value };
    list[index] = benefitObj;
    setBenefitsList(list);
  };

  const handleAddBenefit = () => {
    let size = benefitsList.length;
    if (
      benefitsList[size - 1].type !== "" &&
      benefitsList[size - 1].details !== ""
    ) {
      setBenefitsList([...benefitsList, { type: "", details: "" }]);
    }
  };

  const handleRemoveBenefit = (index) => {
    const list = [...benefitsList];
    list.splice(index, 1);
    setBenefitsList(list);
  };

  /**
   * function to reset all error messages
   */
  const resetErrorMessages = () => {
    setErrorName(null);
    setErrorScientific(null);
    setErrorFamily(null);
    setErrorDescription(null);
    setErrorInformation(null);
    setError(null);
    setErrorPrimary(null);
    setErrorBenefits(null);
  };

  /**
   * function that handles creating a new plant
   */
  const handleCreatePlant = () => {
    let toCreate = {};
    let toCreateFlag = true;

    resetErrorMessages();

    if (newPlant.name === "") {
      toCreateFlag = false;
      setErrorName(<ToastError error={REQUIRED_FIELD} />);
    }

    if (newPlant.scientificName === "") {
      toCreateFlag = false;
      setErrorScientific(<ToastError error={REQUIRED_FIELD} />);
    }

    if (newPlant.family === "") {
      toCreateFlag = false;
      setErrorFamily(<ToastError error={REQUIRED_FIELD} />);
    }

    if (newPlant.description.length < 10) {
      toCreateFlag = false;
      setErrorDescription(<ToastError error={ERROR_10} />);
    }

    if (videoSource !== "" && videoUrlId !== "" && errorUrl === null) {
      toCreate = {
        ...toCreate,
        video: {source: videoSource, url: videoUrlId},
      };
    }

    Object.keys(newPlant.information).forEach((key) => {
      if (newPlant.information[key].length < 10) {
        toCreateFlag = false;
        setErrorInformation(<ToastError error={ERROR_INFORMATION} />);
        setError(<ToastError error={ERROR_10} />);
      }
    });

    benefitsList.forEach((benefit) => {
      if (benefit.type === "" || benefit.details === "") {
        toCreateFlag = false;
        setErrorBenefits(<ToastError error={ERROR_BENEFITS} />);
      }
    });

    if (newPlant.primaryImage === null) {
      toCreateFlag = false;
      setErrorPrimary(<ToastError error={REQUIRED_FIELD} />);
    }

    if (toCreateFlag) {
      toCreate = { ...toCreate, name: newPlant.name };
      toCreate = { ...toCreate, scientificName: newPlant.scientificName };
      toCreate = { ...toCreate, family: newPlant.family };
      toCreate = { ...toCreate, description: newPlant.description };
      toCreate = { ...toCreate, primaryImage: newPlant.primaryImage };
      let informationObject = {
        ...newPlant.information,
        benefits: benefitsList,
      };
      //console.log(informationObject);
      toCreate = { ...toCreate, information: informationObject };

      if (newPlant.toxic) {
        toCreate = { ...toCreate, toxic: newPlant.toxic };
      }

      if (newPlant.endangered) {
        toCreate = { ...toCreate, endangered: newPlant.endangered };
      }

      // if (newPlant.categories !== null) {
      //   toCreate = { ...toCreate, categories: newPlant.categories };
      // }

      if (newPlant.secondaryImages.length > 0) {
        toCreate = { ...toCreate, secondaryImages: newPlant.secondaryImages };
      }

      if (selectedParentCategory) {
        let parentCategoriesIds = newPlant.categories.map((category) => {
          return category._id;
        });

        let subCategoriesIds = selectedSubCategories.map((category) => {
          return category._id;
        });

        toCreate = {
          ...toCreate,
          categories: [...parentCategoriesIds, ...subCategoriesIds],
        };
      }

      console.log(toCreate.categories);

      resetErrorMessages();

      showAlert(toCreate);
    } else {
      showErrorAlert();
    }
  };

  const showAlert = (toCreate) => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="האם את/ה בטוח/ה?"
        onConfirm={() => {
          props.createPlant(toCreate, props);
          setAlert(null);
        }}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText="אישור"
        cancelBtnText="ביטול"
        showCancel
        btnSize=""
      >
        הפעולה הבאה תיצור צמח חדש במערכת
      </ReactBSAlert>
    );
  };

  const showErrorAlert = () => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="נא למלא את כל שדות החובה"
        onConfirm={() => {
          setAlert(null);
        }}
        confirmBtnBsStyle="error"
        confirmBtnText="אישור"
        btnSize=""
      >
        שדות חובה מסומנים בכוכבית
      </ReactBSAlert>
    );
  };

  /**
   * useEffect for getting plant's categories
   */
  useEffect(() => {
    if (props.plantCategories === null) {
      props.getCategories("plant");
    }
  }, []);

  /**
   * useEffect for mapping parent and sub categories
   * needed for react-select component
   */
  useEffect(() => {
    if (props.plantCategories !== null) {
      let parent = [],
        sub = [];
      props.plantCategories.forEach((category) => {
        if (category.hasOwnProperty("parentCategory")) {
          sub.push(category);
        } else {
          parent.push(category);
        }
      });

      setCategories(
        parent.map((category) => {
          return {
            _id: category._id,
            value: category.name,
            label: category.name,
          };
        })
      );

      setSubCategories(
        sub.map((category) => {
          return {
            _id: category._id,
            parentCategory: category.parentCategory,
            value: category.name,
            label: category.name,
          };
        })
      );
    }
  }, [props.plantCategories]);

  useEffect(() => {
    if (props.failed) {
      setModalFail(props.failed);
    }
  }, [props.failed]);

  return (
    <>
      <div className="content">
        {alert}

        <LoadingModal loading={props.sendingRequest} />

        <Modal isOpen={modalFail} className="text-center">
          <ModalBody>
            <h4>הפעולה נכשלה</h4>
            <Row>
              <Col md="12" className="d-flex justify-content-center">
                <Button
                  color="primary"
                  onClick={() => {
                    setModalFail(!modalFail);
                  }}
                >
                  אישור
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>

        <h2 className="text-center">הוספת צמח חדש</h2>
        <Row>
          <Col xs={12} className="text-right">
            <Link to={"/plants"}>
              <Button
                className="btn-fill mx-1"
                color="primary"
                type="submit"
                style={{ width: "250px" }}
              >
                חזרה{" "}
              </Button>
            </Link>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="text-right">
            <Card style={{ padding: "10px" }}>
              <CardBody>
                <Row>
                  <Col className="pr-md-1" md="5">
                    <FormGroup>
                      <h4>שם הצמח *</h4>
                      <Input
                        name="name"
                        value={newPlant.name}
                        type="text"
                        required
                        maxLength="60"
                        onChange={handleOnChange}
                      />
                      {errorName}
                    </FormGroup>
                  </Col>
                  <Col className="pr-md-1" md="5">
                    <FormGroup>
                      <h4>שם מדעי *</h4>
                      <Input
                        name="scientificName"
                        type="text"
                        value={newPlant.scientificName}
                        required
                        onChange={handleOnChange}
                      />
                      {errorScientific}
                    </FormGroup>
                  </Col>

                  <Col className="pr-md-1 d-flex justify-content-center" md="1">
                    <FormGroup>
                      <h4>רעיל</h4>
                      <Input
                        name="toxic"
                        type="checkbox"
                        checked={newPlant.toxic}
                        onChange={(e) => {
                          handleOnCheck(e, newPlant.toxic);
                        }}
                        style={{
                          height: "35px",
                          width: "35px",
                          marginTop: "0px",
                        }}
                      />
                      <br />
                    </FormGroup>
                  </Col>
                  <Col className="pr-md-1 d-flex justify-content-center" md="1">
                    <FormGroup>
                      <h4>בהכחדה</h4>
                      <Input
                        name="endangered"
                        type="checkbox"
                        checked={newPlant.endangered}
                        onChange={(e) => {
                          handleOnCheck(e, newPlant.endangered);
                        }}
                        style={{
                          height: "35px",
                          width: "35px",
                          marginTop: "0px",
                        }}
                      />
                      <br />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col className="pr-md-1" md="12">
                    <FormGroup>
                      <h4>משפחה *</h4>
                      <TextareaAutosize
                        className="autosize-textarea"
                        required
                        value={newPlant.family}
                        name="family"
                        maxLength="1000"
                        onChange={handleOnChange}
                      />
                      {errorFamily}
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col className="pr-md-1" md="12">
                    <FormGroup>
                      <h4>תיאור הצמח *</h4>
                      <TextareaAutosize
                        className="autosize-textarea"
                        required
                        value={newPlant.description}
                        name="description"
                        minLength="10"
                        maxLength="1000"
                        onChange={handleOnChange}
                      />
                      {errorDescription}
                    </FormGroup>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col className="pr-md-1" md="6">
                    <FormGroup>
                      <h4>סוג וידאו</h4>
                      <Select
                          isClearable={true}
                          isRtl={true}
                          isSearchable={true}
                          options={videoOptions}
                          onChange={handleVideoSelect}
                      />
                    </FormGroup>
                  </Col>

                  {videoSource !== "" && (
                      <Col className="px-md-1" md="6">
                        <FormGroup>
                          <h4>וידאו ID</h4>
                          <Input
                              name="videoLink"
                              type="text"
                              onChange={handleVideoUrlId}
                              value={videoUrlId}
                          />
                          {errorUrl}
                        </FormGroup>
                      </Col>
                  )}
                </Row>
                <br />

                {errorUrl === null && (
                    <Row>
                      <Col className="pr-md-1" md="12">
                        {videoSource === "youtube" && videoUrlId !== "" && (
                            <YoutubePlayer url={`${YouTube}${videoUrlId}`}/>
                        )}

                        {videoSource === "vimeo" && videoUrlId !== "" && (
                            <VimeoPlayer
                                controls={true}
                                url={`${Vimeo}${videoUrlId}`}
                            />
                        )}
                      </Col>
                    </Row>
                )}

                <br />

                <Row>
                  <Col className="pr-md-1" md="12">
                    <FormGroup>
                      <h4 style={{ margin: "0" }}>מידע על הצמח *</h4>
                      {errorInformation}
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col className="pr-md-1" md="12">
                    <FormGroup>
                      <label>איפה למצוא *</label>
                      <TextareaAutosize
                        className="autosize-textarea"
                        required
                        value={newPlant.information.whereToFind}
                        name="whereToFind"
                        minLength="10"
                        maxLength="1000"
                        onChange={handleOnChangeInformation}
                      />
                      {newPlant.information.whereToFind.length < 10 && error}
                    </FormGroup>
                  </Col>
                  <Col className="pr-md-1" md="12">
                    <FormGroup>
                      <label>פרטי זיהוי *</label>
                      <TextareaAutosize
                        className="autosize-textarea"
                        required
                        value={newPlant.information.identificationDetails}
                        name="identificationDetails"
                        minLength="10"
                        maxLength="1000"
                        onChange={handleOnChangeInformation}
                      />
                      {newPlant.information.identificationDetails.length < 10 &&
                        error}
                    </FormGroup>
                  </Col>
                  <Col className="pr-md-1" md="12">
                    <FormGroup>
                      <label>אזהרות *</label>
                      <TextareaAutosize
                        className="autosize-textarea"
                        required
                        value={newPlant.information.warning}
                        name="warning"
                        minLength="10"
                        maxLength="1000"
                        onChange={handleOnChangeInformation}
                      />
                      {newPlant.information.warning.length < 10 && error}
                    </FormGroup>
                  </Col>
                  <Col className="pr-md-1" md="12">
                    <FormGroup>
                      <label>הכנות *</label>
                      <TextareaAutosize
                        className="autosize-textarea"
                        required
                        value={newPlant.information.preparation}
                        name="preparation"
                        minLength="10"
                        maxLength="1000"
                        onChange={handleOnChangeInformation}
                      />
                      {newPlant.information.preparation.length < 10 && error}
                    </FormGroup>
                  </Col>
                  <Col className="pr-md-1" md="12">
                    <FormGroup>
                      <label>הרכב *</label>
                      <TextareaAutosize
                        className="autosize-textarea"
                        required
                        value={newPlant.information.composition}
                        name="composition"
                        minLength="10"
                        maxLength="1000"
                        onChange={handleOnChangeInformation}
                      />
                      {newPlant.information.composition.length < 10 && error}
                    </FormGroup>
                  </Col>

                  <Col className="pr-md-1" md="12">
                    <FormGroup>
                      <label>טריויה *</label>
                      <TextareaAutosize
                        className="autosize-textarea"
                        required
                        value={newPlant.information.trivia}
                        name="trivia"
                        minLength="10"
                        maxLength="1000"
                        onChange={handleOnChangeInformation}
                      />
                      {newPlant.information.trivia.length < 10 && error}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-md-1" md="12">
                    <FormGroup>
                      <label>יתרונות *</label>
                      {/* <TextareaAutosize
                        className="autosize-textarea"
                        required
                        value={newPlant.information.benefits}
                        name="benefits"
                        minLength="10"
                        maxLength="1000"
                        onChange={handleOnChangeInformation}
                      /> */}
                      <ol>
                        {benefitsList.map((benefit, index) => {
                          return (
                            <li
                              key={index}
                              style={{
                                paddingRight: "10px",
                              }}
                            >
                              <Row
                                style={{
                                  marginBottom: "10px",
                                }}
                              >
                                <Col md="3">
                                  <Input
                                    name="type"
                                    placeholder="שם האיבר בגוף"
                                    type="text"
                                    required
                                    minLength="10"
                                    maxLength="1000"
                                    value={benefit.type}
                                    onChange={(e) =>
                                      handleInputBenefit(e, index)
                                    }
                                  />
                                </Col>

                                <Col md="8">
                                  <TextareaAutosize
                                    className="autosize-textarea"
                                    placeholder="תיאור היתרון"
                                    required
                                    value={benefit.details}
                                    name="details"
                                    minLength="10"
                                    onChange={(e) =>
                                      handleInputBenefit(e, index)
                                    }
                                  />
                                  {/* <Input
                                    name="details"
                                    placeholder="תיאור היתרון"
                                    type="text"
                                    required
                                    minLength="10"
                                    maxLength="1000"
                                    value={benefit.details}
                                    onChange={(e) =>
                                      handleInputBenefit(e, index)
                                    }
                                  /> */}
                                </Col>

                                <Col md="1">
                                  {index !== 0 && (
                                    <label
                                      className="label-secondary"
                                      style={{
                                        cursor: "pointer",
                                        fontWeight: "bold",
                                        fontSize: "16px",
                                        marginTop: "8px",
                                      }}
                                      onClick={() => handleRemoveBenefit(index)}
                                    >
                                      X
                                    </label>
                                  )}
                                </Col>
                              </Row>
                            </li>
                          );
                        })}
                      </ol>
                      {errorBenefits}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <label
                    onClick={handleAddBenefit}
                    style={{ cursor: "pointer", fontWeight: "bold" }}
                  >
                    הוספת יתרונות
                  </label>
                </Row>
                <br />
                <Row>
                  <Col className="pr-md-1" md="6">
                    <FormGroup>
                      <h4>קטגוריות</h4>
                      <Select
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        isMulti
                        options={categories}
                        placeholder="בחר/י קטגוריות"
                        onChange={handleSelectParentCategories}
                      />
                    </FormGroup>
                  </Col>
                  {selectedParentCategory && (
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <h4>תת קטגוריה</h4>
                        <Select
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          isMulti
                          options={subCategoriesOptions}
                          placeholder="בחר/י תת קטגוריות"
                          onChange={handleSelectCategories}
                        />
                      </FormGroup>
                    </Col>
                  )}
                </Row>

                <br />
                <Row>
                  <Col className="pr-md-1 d-flex justify-content-start" md="12">
                    <FormGroup>
                      <h4 style={{ margin: "0" }}>תמונה ראשית *</h4>
                      {errorPrimary}
                      <label>ניתן להעלות קבצים מסוג: png, jpg, jpeg</label>
                      <br />
                      <label>גודל קובץ עד 5MB</label>
                      <StyledDropzone
                        handleFileSave={handleChangePrimary}
                        handleRemoveFile={handleDeletePrimary}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col className="pr-md-1" md="12">
                    <FormGroup>
                      <h4 style={{ margin: "0" }}>תמונות משניות</h4>
                      <label>ניתן להעלות קבצים מסוג: png, jpg, jpeg</label>
                      <br />
                      <label>גודל כל קובץ עד 5MB</label>
                      <h5>התמונות ישמרו ויוצגו לפי הסדר הנ"ל מימין לשמאל</h5>
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  {imageList.map((val, i) => {
                    return (
                      <Col className="pr-md-1" md="3" key={i}>
                        <FormGroup>
                          <StyledDropzone
                            handleFileSave={handleChangeSecondary}
                            handleRemoveFile={handleDeleteSecondary}
                          />
                        </FormGroup>
                      </Col>
                    );
                  })}
                </Row>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col className="pr-md-1" md="12">
                    <Button
                      onClick={handleCreatePlant}
                      className="btn-fill mx-1"
                      color="primary"
                      type="submit"
                      style={{ width: "250px" }}
                    >
                      צור צמח
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  plantCategories: state.shared.plantCategories,
  sendingRequest: state.plants.sendingRequest,
  failed: state.plants.failed,
});

export default connect(mapStateToProps, { createPlant, getCategories })(
  CreatePlant
);
