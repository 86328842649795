import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import PaginationComponent from "react-reactstrap-pagination";
import {Link} from "react-router-dom";
import ReactBSAlert from "react-bootstrap-sweetalert";
import ToastError, {TITLE_ERROR} from "../../components/Errors";
import Spinner from "components/Spinner";
import TextTruncate from "react-text-truncate";
import {verifySession} from "../../../redux/actions/auth";
import noImage from "../../../assets/img/no-image.jpg";
import {URL_API} from "../../../path";
import {
    getPlantSpots,
    approvePlantSpotSubmission,
    rejectPlantSpotSubmission,
} from "../../../redux/actions/plants-spots";
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Row,
    Col,
    CardImg,
    CardText,
    Input,
    CustomInput,
    CardFooter,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";

/**
 * PlantsSpotList Component for viewing all existing plants spots
 */
const PlantsSpotList = (props) => {
    // const {} = props;

    const [plantSpotsList, setPlantSpotsList] = useState([
        {
            address: "",
            created_at: "",
            creator: {name: "", userType: ""},
            expired: false,
            image: {
                path: "",
            },
            location: {coordinates: []},
            plant: {_id: "", name: ""},
            _id: "",
        },
    ]);

    const [offset, setOffset] = useState(0);
    const [limit] = useState(50);
    const [pending, setPending] = useState(props.pending);
    const [alert, setAlert] = useState(null);

    const [errorMessage, setErrorMessage] = useState(null);
    const [rejectMessage, setRejectMessage] = useState("");
    const [spotId, setSpotId] = useState("");

    const [modalSuccess, setModalSuccess] = useState(false);
    const [modalMessage, setModalMessage] = useState("הפעולה בוצעה בהצלחה");
    const [modal, setModal] = useState(false);

    const [approveModal, setApproveModal] = useState(false);
    const [approveLocationWithoutImage, setApproveLocationWithoutImage] = useState(false);

    const toggle = () => {
        setModal(!modal);
        setErrorMessage(null);
    };

    /**
     * function for handling reject message
     */
    const handleRejectMessageInput = (e) => {
        setRejectMessage(e.target.value);
    };

    const handleActiveSwitch = () => {
        setOffset(0);
        props.getPlantSpots(offset, limit, !pending);
        setPending(!pending);
    };

    const handlePageSelect = (selectedPage) => {
        setOffset(selectedPage - 1);
        props.getPlantSpots(selectedPage - 1, limit, pending);
    };

    /**
     * function for handling approving plant spot
     */
    const handleApproveSpot = () => {
        setApproveModal(!approveModal);
        // need to add action to search if this plant spot already exists
        showAlert("הפעולה הבאה תאשר את הספוט", "approve");
    };

    /**
     * function for handling rejecting plant spot
     */
    const handleRejectSpot = () => {
        if (rejectMessage.length > 4) {
            setModal(!modal);
            setErrorMessage(null);
            showAlert("הפעולה הבאה תדחה את הספוט", "reject");
        } else {
            setErrorMessage(<ToastError error={TITLE_ERROR}/>);
        }
    };

    /**
     * function for alert message
     */
    const showAlert = (message, actionType) => {
        setAlert(
            <ReactBSAlert
                warning
                style={{display: "block", marginTop: "-100px"}}
                title="האם את/ה בטוח/ה?"
                onConfirm={() => {
                    if (actionType === "approve") {
                        // not completed
                        props.approvePlantSpotSubmission(spotId, approveLocationWithoutImage, props);
                    } else {
                        props.rejectPlantSpotSubmission(spotId, rejectMessage, props);
                    }
                    setAlert(null);
                }}
                onCancel={() => setAlert(null)}
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                confirmBtnText="אישור"
                cancelBtnText="ביטול"
                showCancel
                btnSize=""
            >
                {message}
            </ReactBSAlert>
        );
    };

    /**
     * useEffect that gets plant spots
     * offset starts at 0 and number of plant spots per page is limited is 50
     */
    useEffect(() => {
        props.verifySession();
        props.getPlantSpots(offset, limit, pending);
    }, []);

    /**
     * useEffect for setting plant spots list state
     */
    useEffect(() => {
        if (props.plantSpotsList !== null) {
            setPlantSpotsList(props.plantSpotsList);
        }
    }, [props.plantSpotsList]);

    /**
     * useEffect for checking if plant spot was created
     */
    useEffect(() => {
        if (props.createdPlantSpot) {
            setModalSuccess(!modalSuccess);
            // setPending(false);
            //props.getPlantSpots(offset, limit, false);
        }
    }, [props.createdPlantSpot]);

    useEffect(() => {
        if (props.failed) {
            setModalSuccess(props.failed);
            setModalMessage("הפעולה נכשלה");
        }
    }, [props.failed]);

    return (
        <>
            {alert}

            <Modal isOpen={modalSuccess} className="text-center">
                <ModalBody>
                    <h4>{modalMessage}</h4>
                    <Row>
                        <Col md="12" className="d-flex justify-content-center">
                            <Button
                                color="primary"
                                onClick={() => {
                                    setModalSuccess(!modalSuccess);
                                    setModalMessage("הפעולה בוצעה בהצלחה");
                                }}
                            >
                                אישור
                            </Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>

            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader>דחיית ספוט של צמח</ModalHeader>
                <ModalBody>
                    <Input
                        type="textarea"
                        placeholder="הודעת דחייה ללקוח"
                        onChange={handleRejectMessageInput}
                        maxLength="200"
                        value={rejectMessage}
                    />
                    <div className="text-right">{errorMessage}</div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>
                        ביטול
                    </Button>
                    <Button color="primary" onClick={handleRejectSpot}>
                        אישור
                    </Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={approveModal}>
                <ModalBody>
                    <div className="text-right">
                        <Input
                            name="withImage"
                            type="checkbox"
                            style={{height: "30px", width: "30px"}}
                            checked={approveLocationWithoutImage}
                            onChange={() => {
                                setApproveLocationWithoutImage(!approveLocationWithoutImage);
                            }}
                        />
                        <label
                            style={{
                                marginRight: "45px",
                                fontSize: "16px",
                                marginTop: "6px",
                            }}
                        >
                            {" "}
                            אשר ספוט ללא תמונה
                        </label>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="secondary"
                        onClick={() => {
                            setApproveModal(false);
                        }}
                    >
                        ביטול
                    </Button>
                    <Button color="primary" onClick={handleApproveSpot}>
                        אישור
                    </Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={props.sendingRequest} className="text-center">
                <ModalBody>
                    <Spinner/>
                    <h3 style={{color: "#3ec7af"}}>אנא המתן...</h3>
                </ModalBody>
            </Modal>

            <div className="content rtl">
                <Row>
                    <Col md="12">
                        <h1 className="text-center">רשימת ספוטים של צמחים</h1>
                    </Col>
                </Row>

                <Row>
                    <Col md="8" className="d-flex justify-content-start">
                        <Link to={"/plant-spots/create"}>
                            <Button
                                className="btn-fill mx-1"
                                color="primary"
                                type="submit"
                                style={{width: "250px"}}
                            >
                                צור ספוט חדש{" "}
                            </Button>
                        </Link>
                    </Col>

                    <Col
                        md="4"
                        className="d-flex justify-content-end"
                        style={{alignSelf: "center"}}
                    >
                        <CustomInput
                            id="switchInput"
                            type="switch"
                            label={pending ? "ספוטים שממתינים לאישור" : "ספוטים שאושרו"}
                            onChange={handleActiveSwitch}
                            checked={pending ? true : false}
                        ></CustomInput>
                    </Col>
                </Row>

                <hr/>

                {plantSpotsList.length === 0 && !props.loading && pending && (
                    <div className="text-center">
                        <br/>
                        <h3 style={{color: "#3ec7af"}}>אין ספוטים שממתינים לאישור</h3>
                    </div>
                )}

                {plantSpotsList.length === 0 && !props.loading && !pending && (
                    <div className="text-center">
                        <br/>
                        <h3 style={{color: "#3ec7af"}}>לא נמצאו ספוטים</h3>
                    </div>
                )}

                {props.loading ? (
                    <div className="text-center">
                        <br/>
                        <Spinner
                            style={{borderColor: "#3ec7af", width: "50px", height: "50px"}}
                        />
                        <h3 style={{color: "#3ec7af"}}>טוען נתונים...</h3>
                    </div>
                ) : (
                    <>
                        <Row className="text-right">
                            {plantSpotsList.map((spot, key) => {
                                let date = new Date(spot.created_at).toLocaleDateString(
                                    "en-GB"
                                );

                                return (
                                    <Col md="3" key={key}>
                                        <Card>
                                            <CardImg
                                                top
                                                width="100%"
                                                style={{height: "320px", objectFit: "cover"}}
                                                src={
                                                    spot.image !== null
                                                        ? `${URL_API}${spot.image.path}`
                                                        : noImage
                                                }
                                                alt="Card Plant Image"
                                            />
                                            <CardBody>
                                                <CardTitle
                                                    style={{fontSize: "16px", fontWeight: "bold"}}
                                                >
                                                    <TextTruncate line={1} text={spot.plant.name}/>
                                                </CardTitle>

                                                {spot.expired ? (
                                                    <CardTitle style={{color: "red"}}>
                                                        {"ספוט לא פעיל"} | {date}
                                                    </CardTitle>
                                                ) : (
                                                    <CardTitle>
                                                        {"ספוט פעיל"} | {date}
                                                    </CardTitle>
                                                )}

                                                {spot.address && (
                                                    <>
                                                        <CardText>{spot.address}</CardText>
                                                        <CardText>
                                                            קו רוחב: {spot.location.coordinates[1]}, קו אורך:{" "}
                                                            {spot.location.coordinates[0]}
                                                        </CardText>

                                                        {spot.location.coordinates && (
                                                            <CardText>
                                                                <a
                                                                    id="mylink"
                                                                    target="_blank"
                                                                    href={`https://maps.google.com/?q=${spot.location.coordinates[1]},${spot.location.coordinates[0]}`}
                                                                    // href={`https://www.google.com/maps/@${spot.location.coordinates[1]}+${spot.location.coordinates[0]}`}
                                                                >
                                                                    לחצו כאן לצפיה על המפה
                                                                </a>
                                                            </CardText>
                                                        )}
                                                    </>
                                                )}

                                                {spot.creator && (
                                                    <>
                                                        <CardText>
                                                            נוצר על ידי: {spot.creator.name}
                                                        </CardText>
                                                    </>
                                                )}
                                            </CardBody>
                                            {props.pending && (
                                                <CardFooter>
                                                    <Button
                                                        className="btn-fill mx-1"
                                                        color="primary"
                                                        type="submit"
                                                        onClick={() => {
                                                            setSpotId(spot._id);
                                                            setApproveModal(!approveModal);
                                                        }}
                                                    >
                                                        אישור
                                                    </Button>

                                                    <Button
                                                        className="btn-fill mx-1"
                                                        color="primary"
                                                        type="submit"
                                                        onClick={() => {
                                                            setSpotId(spot._id);
                                                            toggle();
                                                        }}
                                                    >
                                                        דחייה
                                                    </Button>
                                                </CardFooter>
                                            )}
                                        </Card>
                                    </Col>
                                );
                            })}
                        </Row>
                    </>
                )}
            </div>

            <div
                style={{
                    position: "absolute",
                    left: "0",
                    right: "0",
                    overflow: "hidden",
                }}
            >
                {plantSpotsList.length > 0 && (
                    <Row>
                        <Card
                            style={{
                                backgroundColor: "transparent",
                                boxShadow: "none",
                            }}
                        >
                            <Col md="12" className="d-flex justify-content-center">
                                <PaginationComponent
                                    totalItems={props.totalPlantSpots}
                                    className="pagination"
                                    // totalItems={500}
                                    pageSize={props.limit}
                                    onSelect={handlePageSelect}
                                    firstPageText="ראשון"
                                    previousPageText="הקודם"
                                    nextPageText="הבא"
                                    lastPageText="אחרון"
                                />
                            </Col>
                        </Card>
                    </Row>
                )}
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    plantSpotsList: state.plantSpots.plantSpotsList,
    totalPlantSpots: state.plantSpots.totalPlantSpots,
    limit: state.plantSpots.limit,
    pending: state.plantSpots.pending,
    loading: state.plantSpots.loading,
    sendingRequest: state.plantSpots.sendingRequest,
    createdPlantSpot: state.plantSpots.createdPlantSpot,
    failed: state.plantSpots.failed,
});

export default connect(mapStateToProps, {
    getPlantSpots,
    approvePlantSpotSubmission,
    rejectPlantSpotSubmission,
    verifySession,
})(PlantsSpotList);
