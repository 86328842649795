import React from "react";
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Spinner,
  Row,
} from "reactstrap";

import logo from "assets/img/custom/logo.png";
import { connect } from "react-redux";
import { sendMail, login, resetEmail } from "../../redux/actions/auth";
import Timer from "react-compound-timer";

/**
 * Component for user login
 */
class Login extends React.Component {
  state = { email: "", otp: "", visibility: true };

  componentDidMount() {
    document.body.classList.toggle("login-page");
  }

  componentWillUnmount() {
    document.body.classList.toggle("login-page");
  }

  componentDidUpdate() {
    if (this.props.auth.authenticated) {
      // this.props.getAdminProfile(); // check if admin is crowned
      this.props.history.push("/dashboard");
    }
  }

  /**
   * function for handling email
   */
  handleOTP = (e) => {
    e.preventDefault();
    const { email } = this.state;
    if (email !== "") {
      this.props.sendMail(email);
      this.setState({ visibility: true, otp: "" });
    }
  };

  /**
   * function for handling login with email and otp
   */
  handleLogin = (e) => {
    e.preventDefault();
    const { email, otp } = this.state;
    if (otp !== "") {
      this.props.login(email, otp);
    }
  };

  handleInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    // if (e.target.name === "otp") {
    //   this.setState({ [e.target.name]: e.target.value.replace(/\D/, "") });
  };

  handleTimerStop = () => {
    this.setState({ visibility: false });
    //this.props.returnError("OTP Timer is up, please request another OTP.");
  };

  render() {
    if (this.props.auth.verifyingSession) {
      return (
        <div className="text-center">
          <br />
          {/* <h3 style={{ color: "#3ec7af" }}>טוען נתונים...</h3> */}
          <Spinner
            style={{ borderColor: "#3ec7af", width: "50px", height: "50px" }}
          />
        </div>
      );
    }

    return (
      <div className="content">
        <Container className="login-container">
          <Col className="ml-auto mr-auto" lg="4" md="6">
            <Form className="form">
              <Card className="card-login card-white">
                <CardHeader>
                  <img alt="Lukit logo" src={logo} />
                  {this.props.auth.otpSent ? (
                    <>
                      <CardBody>
                        <Row
                          style={{
                            display: this.state.visibility ? "flex" : "none",
                          }}
                        >
                          <Col
                            style={{
                              fontSize: "40px",
                              color: "var(--lukit-primary-color)",
                            }}
                          >
                            {this.state.visibility && (
                              <Timer
                                formatValue={(value) =>
                                  `${value < 10 ? `0${value}` : value}`
                                }
                                initialTime={120000}
                                direction="backward"
                                startImmediately={true}
                                checkpoints={[
                                  {
                                    time: 0,
                                    callback: this.handleTimerStop,
                                  },
                                ]}
                              >
                                {() => (
                                  <React.Fragment>
                                    <Timer.Minutes />:
                                    <Timer.Seconds />
                                  </React.Fragment>
                                )}
                              </Timer>
                            )}
                          </Col>
                        </Row>

                        <InputGroup
                          className={classnames({
                            "input-group-focus": false,
                          })}
                        >
                          <Input
                            placeholder="קוד חד-פעמי"
                            value={this.state.otp}
                            type="text"
                            name="otp"
                            onChange={this.handleInput}
                            // onFocus={(e) => this.setState({ passFocus: true })}
                            // onBlur={(e) => this.setState({ passFocus: false })}
                          />
                          <InputGroupAddon addonType="append">
                            <InputGroupText>
                              <i className="tim-icons icon-key-25" />
                            </InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </CardBody>
                      <CardFooter>
                        {this.state.visibility && (
                          <Row>
                            <Col>
                              <Button
                                className="btn-round"
                                color="primary"
                                href="#pablo"
                                onClick={this.handleLogin}
                                size="md"
                              >
                                התחברות
                              </Button>
                            </Col>
                          </Row>
                        )}

                        <Row>
                          <Col>
                            <Button
                              className="btn-round"
                              color="primary"
                              size="md"
                              onClick={this.handleOTP}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              שלח קוד שוב
                            </Button>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Button
                              className="btn-round"
                              color="primary"
                              size="md"
                              onClick={() => {
                                this.props.resetEmail();
                                this.setState({ visibility: true });
                              }}
                            >
                              חזרה
                            </Button>
                          </Col>
                        </Row>
                      </CardFooter>
                    </>
                  ) : (
                    <>
                      <CardBody>
                        <InputGroup
                        // className={classnames({
                        //   "input-group-focus": false,
                        // })}
                        >
                          <Input
                            placeholder="אימייל"
                            value={this.state.email}
                            type="email"
                            name="email"
                            onChange={this.handleInput}
                            // onFocus={(e) => this.setState({ emailFocus: true })}
                            // onBlur={(e) => this.setState({ emailFocus: false })}
                          />
                          <InputGroupAddon addonType="append">
                            <InputGroupText>
                              <i className="tim-icons icon-email-85" />
                            </InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </CardBody>
                      <CardFooter>
                        <Button
                          block
                          className="btn-round"
                          color="primary"
                          href="#pablo"
                          onClick={this.handleOTP}
                          size="lg"
                        >
                          שלח קוד חד-פעמי
                        </Button>
                      </CardFooter>
                    </>
                  )}
                </CardHeader>
              </Card>
            </Form>
          </Col>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  sendMail,
  resetEmail,
  login,
})(Login);
