import React, { useEffect, useState } from "react";
import premiumIcon from "../../../assets/img/premium.png";
import { getRecipes } from "../../../redux/actions/recipes";
import PaginationComponent from "react-reactstrap-pagination";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// import noImage from "../../../assets/img/no-image-found.png";
import { URL_API } from "../../../path";
import Spinner from "components/Spinner";
import TextTruncate from "react-text-truncate";
import noImage from "../../../assets/img/no-image.jpg";
import { verifySession } from "../../../redux/actions/auth";

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  CardImg,
  CardText,
  CardImgOverlay,
  Input,
  CustomInput,
  CardFooter,
  Modal,
  ModalBody,
} from "reactstrap";

/**
 * RecipesList Component for viewing all existing recipes
 */
const RecipesList = (props) => {
  // const {} = props;

  const [offset, setOffset] = useState(props.offset);
  const [limit] = useState(50);
  const [active, setActive] = useState(props.active);
  const [searchRecipe, setSearchRecipe] = useState("");
  const [modalSuccess, setModalSuccess] = useState(false);
  const [timer, setTimer] = useState(null);
  const [recipesList, setRecipesList] = useState([
    {
      _id: "",
      title: "",
      servings: "",
      preparationTime: "",
      premium: false,
      primaryImage: {
        path: "",
      },
    },
  ]);

  /**
   * function that handles selecting a page
   * gets recipes from backend with new offset
   */
  const handlePageSelect = (selectedPage) => {
    setOffset(selectedPage - 1);
    props.getRecipes(selectedPage - 1, limit, active, searchRecipe);
  };

  /**
   * function that handles getting active / not active recipes
   */
  const handleActiveSwitch = () => {
    setOffset(0);
    props.getRecipes(offset, limit, !active, searchRecipe);
    setActive(!active);
  };

  /**
   * useEffect that gets active recipes
   * offset starts at 0 and number of recipes per page is limited is 50
   */
  useEffect(() => {
    props.verifySession();
    props.getRecipes(offset, limit, active, searchRecipe);
  }, []);

  /**
   * useEffect for setting recipes list state
   */
  useEffect(() => {
    if (props.recipesList !== null) {
      setRecipesList(props.recipesList);
    }
  }, [props.recipesList]);

  useEffect(() => {
    if (props.createdRecipe) {
      setModalSuccess(!modalSuccess);
    }
  }, [props.createdRecipe]);

  /**
   * function that handles search recipes
   * using timeout for optimization, sending request with search value only after user stops typing
   */
  const handleSearchInput = (e) => {
    let inputVal = e.target.value;
    setSearchRecipe(inputVal);
    if (timer) {
      clearTimeout(timer);
    }

    setTimer(
      setTimeout(() => {
        setTimer(null);
        props.getRecipes(offset, limit, active, inputVal);
      }, 500)
    );
  };

  return (
    <>
      <Modal isOpen={modalSuccess} className="text-center">
        <ModalBody>
          <h4>הפעולה בוצעה בהצלחה</h4>
          <Row>
            <Col md="12" className="d-flex justify-content-center">
              <Button
                color="primary"
                onClick={() => {
                  setModalSuccess(!modalSuccess);
                }}
              >
                אישור
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>

      <div className="content rtl">
        <Row>
          <Col xs={12} className="text-right"></Col>
        </Row>
        <Row>
          <Col md="12">
            <h1 className="text-center">רשימת מתכונים</h1>
          </Col>
        </Row>

        <Row>
          <Col md="8" className="d-flex justify-content-start">
            <Link to={"/recipes/create"}>
              <Button
                className="btn-fill mx-1"
                color="primary"
                type="submit"
                style={{ width: "250px" }}
              >
                צור מתכון חדש{" "}
              </Button>
            </Link>
            <Input
              style={{
                height: "40px",
                alignSelf: "center",
                marginRight: "10px",
              }}
              placeholder="סינון לפי מתכון"
              value={searchRecipe}
              onChange={handleSearchInput}
            ></Input>
          </Col>
          <Col
            md="4"
            className="d-flex justify-content-end"
            style={{ alignSelf: "center" }}
          >
            <CustomInput
              id={1234}
              type="switch"
              label={active ? "מתכונים פעילים" : "מתכונים לא פעילים"}
              // label="מאמרים פעילים"
              onChange={handleActiveSwitch}
              checked={active ? true : false}
            ></CustomInput>
          </Col>
        </Row>

        <hr />

        {recipesList.length === 0 && timer === null && !props.loading && (
          <div className="text-center">
            <br />
            <h3 style={{ color: "#3ec7af" }}>לא נמצאו מתכונים</h3>
          </div>
        )}

        {props.loading || timer ? (
          <div className="text-center">
            <br />
            <Spinner />
            <h3 style={{ color: "#3ec7af" }}>טוען נתונים...</h3>
          </div>
        ) : (
          <>
            <Row className="text-right">
              {recipesList.map((recipe, key) => {
                return (
                  <Col md="3" key={key}>
                    <Card>
                      <Link
                        to={{
                          pathname: `/recipes/${recipe._id}`,
                        }}
                      >
                        <CardImg
                          top
                          width="100%"
                          style={{ height: "320px", objectFit: "cover" }}
                          src={
                            recipe.primaryImage.path !== ""
                              ? `${URL_API}${recipe.primaryImage.path}`
                              : noImage
                          }
                          alt="תמונה ראשית"
                        />
                        {recipe.premium && (
                          <CardImgOverlay>
                            <CardImg
                              src={premiumIcon}
                              alt="premium"
                              style={{
                                width: "25px",
                                height: "20px",
                              }}
                            />
                          </CardImgOverlay>
                        )}
                      </Link>

                      <CardBody>
                        <CardTitle
                          style={{ fontSize: "16px", fontWeight: "bold" }}
                        >
                          <TextTruncate line={1} text={recipe.title} />
                        </CardTitle>
                        <CardText>זמן הכנה: {recipe.preparationTime}</CardText>
                        <CardText>מספר מנות: {recipe.servings}</CardText>
                      </CardBody>
                      <CardFooter>
                        <Link
                          style={{
                            color: "rgb(62,199,175)",
                            fontWeight: "bold",
                          }}
                          to={{
                            pathname: `/recipes/${recipe._id}`,
                            recipe: recipe,
                          }}
                        >
                          <Button className="lukit-button">מידע נוסף</Button>
                        </Link>
                      </CardFooter>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </>
        )}
      </div>

      <div
        style={{
          position: "absolute",
          left: "0",
          right: "0",
          overflow: "hidden",
        }}
      >
        {recipesList.length > 0 && (
          <Row>
            <Card
              style={{
                backgroundColor: "transparent",
                boxShadow: "none",
              }}
            >
              <Col md="12" className="d-flex justify-content-center">
                <PaginationComponent
                  totalItems={props.totalRecipes}
                  className="pagination"
                  // totalItems={500}
                  pageSize={limit}
                  onSelect={handlePageSelect}
                  firstPageText="ראשון"
                  previousPageText="הקודם"
                  nextPageText="הבא"
                  lastPageText="אחרון"
                />
              </Col>
            </Card>
          </Row>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  recipesList: state.recipes.recipesList,
  totalRecipes: state.recipes.totalRecipes,
  active: state.recipes.active,
  offset: state.recipes.offset,
  createdRecipe: state.recipes.createdRecipe,
  loading: state.recipes.loading,
});

export default connect(mapStateToProps, { getRecipes, verifySession })(
  RecipesList
);
