import { requestWrapper, supportedRequestMethods } from "../../API";
import {
  DATA_LOADING,
  STATISTICS_LOADED,
  STATISTICS_LOAD_FAIL, RECEIPTS_EXPORTED, RECEIPTS_EXPORT_FAIL,
} from "../types/dashboard";

import { createMessage } from "./message";
import {SENDING_DATA} from "../types/admins";

export const getStatistics = () => async (dispatch) => {
  dispatch({ type: DATA_LOADING });

  let requestParams = {
    method: supportedRequestMethods.GET,
    endpoint: "/admin/statistics",
  };

  let response = await requestWrapper(requestParams);
  if (response && response.success) {
    dispatch({
      type: STATISTICS_LOADED,
      payload: response.data.data,
    });
  } else {
    if (response) dispatch(createMessage(response.data.message));
    dispatch({
      type: STATISTICS_LOAD_FAIL,
    });
  }
};

export const exportReceipts = () => async (dispatch) => {
  dispatch({ type: DATA_LOADING });

  let requestParams = {
    method: supportedRequestMethods.GET,
    endpoint: `/admin/export-receipts`,
  };

  dispatch({ type: SENDING_DATA, payload: true });
  let response = await requestWrapper(requestParams);
  dispatch({ type: SENDING_DATA, payload: false });

  if (response && response.success) {
    dispatch({
      type: RECEIPTS_EXPORTED,
      payload: response.data,
    });
  } else {
    if (response) dispatch(createMessage(response.data.message));
    dispatch({
      type: RECEIPTS_EXPORT_FAIL,
    });
  }
};
