import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Layout from "Layout";
import PrivateRoute from "components/PrivateRoute";
import Login from "views/pages/Login";
import { useDispatch, useSelector } from "react-redux";
import { verifySession } from "./redux/actions/auth";

const App = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    dispatch(verifySession());
  }, [dispatch]);

  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <PrivateRoute path="/" component={Layout} />
      {/* <Route path="/" component={Layout} /> */}
      <Redirect from="*" to="/" />
    </Switch>
  );
};

export default App;
