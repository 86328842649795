import { requestWrapper, supportedRequestMethods } from "../../API";
import {
  DATA_LOADING,
  PLANT_SPOTS_LOADED,
  PLANT_SPOTS_LOAD_FAIL,
  PLANT_SPOT_APPROVED,
  PLANT_SPOT_APPROVE_FAIL,
  PLANT_SPOT_REJECTED,
  PLANT_SPOT_REJECT_FAIL,
  PLANT_SPOT_CREATED,
  PLANT_SPOT_CREATE_FAIL,
  SENDING_DATA,
  PLANT_SPOTS_EXPORTED,
  PLANT_SPOTS_EXPORT_FAIL,
} from "../types/plants-spots";

import { createMessage } from "./message";

export const getPlantSpots = (offset, limit, pending) => async (dispatch) => {
  dispatch({ type: DATA_LOADING });

  let requestParams = {
    method: supportedRequestMethods.GET,
    endpoint: `/admin/plants/spots?offset=${offset}&limit=${limit}&pending=${pending}`,
  };
  let response = await requestWrapper(requestParams);
  if (response && response.success) {
    dispatch({
      type: PLANT_SPOTS_LOADED,
      payload: {
        data: response.data.data,
        offsetVal: offset,
        pending: pending,
      },
    });
  } else {
    if (response) dispatch(createMessage(response.data.message));
    dispatch({
      type: PLANT_SPOTS_LOAD_FAIL,
    });
  }
};

export const createPlantSpot = (plantSpotObj, props) => async (dispatch) => {
  dispatch({ type: DATA_LOADING });

  const formData = new FormData();

  Object.keys(plantSpotObj).forEach((key) => {
    if (key && Array.isArray(plantSpotObj[key])) {
      formData.append(key, JSON.stringify(plantSpotObj[key]));
    } else if (key) {
      formData.append(key, plantSpotObj[key]);
    }
  });

  let requestParams = {
    method: supportedRequestMethods.POST,
    endpoint: `/admin/plants/spots/`,
    body: formData,
  };

  dispatch({ type: SENDING_DATA, payload: true });
  let response = await requestWrapper(requestParams);
  dispatch({ type: SENDING_DATA, payload: false });

  if (response && response.success) {
    dispatch({
      type: PLANT_SPOT_CREATED,
    });
    props.history.push("/plant-spots");
  } else {
    if (response) dispatch(createMessage(response.data.message));
    dispatch({
      type: PLANT_SPOT_CREATE_FAIL,
    });
  }
};

export const approvePlantSpotSubmission = (id, withImage, props) => async (
  dispatch
) => {
  dispatch({ type: DATA_LOADING });
  let requestParams = {
    method: supportedRequestMethods.PATCH,
    endpoint: `/admin/plants/spots/${id}/approve`,
    body: { approveLocationWithoutImage: withImage },
  };

  dispatch({ type: SENDING_DATA, payload: true });
  let response = await requestWrapper(requestParams);
  dispatch({ type: SENDING_DATA, payload: false });

  if (response && response.success) {
    dispatch({
      type: PLANT_SPOT_APPROVED,
      payload: id,
    });
    props.history.push("/plant-spots");
  } else {
    if (response) dispatch(createMessage(response.data.message));
    dispatch({
      type: PLANT_SPOT_APPROVE_FAIL,
    });
  }
};

export const rejectPlantSpotSubmission = (id, rejectMessage, props) => async (
  dispatch
) => {
  dispatch({ type: DATA_LOADING });
  let requestParams = {
    method: supportedRequestMethods.PATCH,
    endpoint: `/admin/plants/spots/${id}/reject`,
    body: { message: rejectMessage },
  };

  dispatch({ type: SENDING_DATA, payload: true });
  let response = await requestWrapper(requestParams);
  dispatch({ type: SENDING_DATA, payload: false });

  if (response && response.success) {
    dispatch({
      type: PLANT_SPOT_REJECTED,
    });
    props.history.push("/plant-spots");
  } else {
    if (response) dispatch(createMessage(response.data.message));
    dispatch({
      type: PLANT_SPOT_REJECT_FAIL,
    });
  }
};

export const exportPlantSpots = () => async (dispatch) => {
  dispatch({ type: DATA_LOADING });

  let requestParams = {
    method: supportedRequestMethods.GET,
    endpoint: `/admin/plants/spots/export-csv`,
  };

  dispatch({ type: SENDING_DATA, payload: true });
  let response = await requestWrapper(requestParams);
  dispatch({ type: SENDING_DATA, payload: false });

  if (response && response.success) {
    dispatch({
      type: PLANT_SPOTS_EXPORTED,
      payload: response.data,
    });
  } else {
    if (response) dispatch(createMessage(response.data.message));
    dispatch({
      type: PLANT_SPOTS_EXPORT_FAIL,
    });
  }
};
