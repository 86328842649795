import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import StyledDropzone from "../../components/Dropzone";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { getPlants } from "redux/actions/shared";
import AsyncSelect from "react-select/async";
import makeAnimated from "react-select/animated";
import { connect } from "react-redux";
import { createPlantSpot } from "redux/actions/plants-spots";
import LoadingModal from "views/components/LoadingModal";
import { getPlantSpots } from "../../../redux/actions/plants-spots";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  FormGroup,
  Input,
  Row,
  Col,
  Modal,
  ModalBody,
} from "reactstrap";
import ToastError, { REQUIRED_FIELD } from "../../components/Errors";

let timer = null;

/**
 * Component for creating an new plant spot
 */
const CreatePlantSpot = (props) => {
  const [alert, setAlert] = useState(null);

  const [newPlantSpot, setNewPlantSpot] = useState({
    plant: "",
    address: "",
    coordinates: "",
    image: null,
  });

  const animatedComponents = makeAnimated();

  const [errorPlant, setErrorPlant] = useState(null);
  const [errorAddress, setErrorAddress] = useState(null);
  const [errorCoordinates, setErrorCoordinates] = useState(null);
  const [modalFail, setModalFail] = useState(false);
  const [created, setCreated] = useState(false);

  const handleOnChange = (e) => {
    setNewPlantSpot({ ...newPlantSpot, [e.target.name]: e.target.value });
  };

  /**
   * function that handles selecting an image
   */
  const handleSelectImage = (file) => {
    setNewPlantSpot({ ...newPlantSpot, image: file[0] });
  };

  /**
   * function that handles deleting an image
   */
  const handleDeleteImage = () => {
    setNewPlantSpot({ ...newPlantSpot, image: null });
  };

  /**
   * function to reset all error messages
   */
  const resetErrorMessages = () => {
    setErrorPlant(null);
    setErrorAddress(null);
    setErrorCoordinates(null);
  };

  /**
   * function that handles creating a new plant spot
   */
  const handleCreatePlantSpot = () => {
    let toCreateFlag = true;
    let createSpot = {};
    resetErrorMessages();

    //console.log(newPlantSpot.coordinates);

    if (newPlantSpot.plant === "") {
      toCreateFlag = false;
      setErrorPlant(<ToastError error={REQUIRED_FIELD} />);
    }

    if (newPlantSpot.address === "") {
      toCreateFlag = false;
      setErrorAddress(<ToastError error={REQUIRED_FIELD} />);
    }

    if (newPlantSpot.coordinates === "") {
      toCreateFlag = false;
      setErrorCoordinates(<ToastError error={REQUIRED_FIELD} />);
    }

    if (toCreateFlag) {
      createSpot = { ...createSpot, plant: newPlantSpot.plant };
      createSpot = { ...createSpot, address: newPlantSpot.address };
      createSpot = { ...createSpot, coordinates: newPlantSpot.coordinates };

      if (newPlantSpot.image !== null) {
        createSpot = { ...createSpot, image: newPlantSpot.image };
      }

      resetErrorMessages();
      showAlert(createSpot);
    } else {
      showErrorAlert();
    }
  };

  /*
   * function for getting options from backend
   * using setTimeout for optimization - does not send request to backend until user stops typing
   */
  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      clearTimeout(timer);

      timer = setTimeout(async () => {
        let list = [];

        const data = await props.getPlants(0, 10, inputValue);
        if (data) {
          list = data.plants.map((plant) => {
            return {
              id: plant._id,
              value: plant.name,
              label: plant.name,
            };
          });
        }

        resolve(list);
      }, 500);
    });

  /**
   * function that handles selecting a plant
   */
  const handleSearchPlants = (selected) => {
    if (selected) {
      setNewPlantSpot({ ...newPlantSpot, plant: selected.id });
    } else {
      setNewPlantSpot({ ...newPlantSpot, plant: "" });
    }
  };

  /**
   * function for alert, calling action for creating new plant spot
   */
  const showAlert = (createSpot) => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="האם את/ה בטוח/ה?"
        onConfirm={() => {
          props.createPlantSpot(createSpot, props);
          setCreated(true);
          setAlert(null);
        }}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText="אישור"
        cancelBtnText="ביטול"
        showCancel
        btnSize=""
      >
        הפעולה הבאה תיצור ספוט חדש במערכת
      </ReactBSAlert>
    );
  };

  /**
   * function for error alert
   */
  const showErrorAlert = () => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="נא למלא את כל שדות החובה"
        onConfirm={() => {
          setAlert(null);
        }}
        confirmBtnBsStyle="error"
        confirmBtnText="אישור"
        btnSize=""
      >
        שדות חובה מסומנים בכוכבית
      </ReactBSAlert>
    );
  };

  useEffect(() => {
    if (props.failed && created) {
      setModalFail(props.failed);
    }
  }, [props.failed, created]);

  return (
    <>
      <div className="content">
        {alert}

        <LoadingModal loading={props.sendingRequest} />

        <Modal isOpen={modalFail} className="text-center">
          <ModalBody>
            <h4>הפעולה נכשלה</h4>
            <Row>
              <Col md="12" className="d-flex justify-content-center">
                <Button
                  color="primary"
                  onClick={() => {
                    setModalFail(!modalFail);
                  }}
                >
                  אישור
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>

        <h2 className="text-center">הוספת ספוט חדש</h2>
        <Row>
          <Col xs={12} className="text-right">
            <Link to={"/plant-spots/"}>
              <Button
                className="btn-fill mx-1"
                color="primary"
                type="submit"
                style={{ width: "250px" }}
              >
                חזרה{" "}
              </Button>
            </Link>
          </Col>
        </Row>

        <Row>
          <Col xs={12} className="text-right">
            <Card style={{ padding: "10px" }}>
              <CardBody>
                <Row>
                  <Col className="pr-md-1" md="6">
                    <FormGroup>
                      <h4>שם הצמח *</h4>

                      <AsyncSelect
                        components={animatedComponents}
                        placeholder="הקלידו שם צמח"
                        loadOptions={promiseOptions}
                        onChange={handleSearchPlants}
                      />
                      {errorPlant}
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col className="pr-md-1" md="6">
                    <FormGroup>
                      <h4>כתובת *</h4>
                      <Input
                        name="address"
                        type="text"
                        required
                        value={newPlantSpot.address}
                        onChange={handleOnChange}
                        placeholder="כתובת"
                      />
                      {errorAddress}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-md-1" md="6">
                    <FormGroup>
                      <h4>קואורדינטות *</h4>
                      <Input
                        name="coordinates"
                        type="text"
                        required
                        value={newPlantSpot.coordinates}
                        onChange={handleOnChange}
                        placeholder="latitude,longitude"
                      />
                      {errorCoordinates}
                    </FormGroup>
                  </Col>
                </Row>

                <br />
                <Row>
                  <Col className="pr-md-1 d-flex justify-content-start" md="12">
                    <FormGroup>
                      <h4 style={{ margin: "0" }}>תמונה</h4>
                      <label>ניתן להעלות קבצים מסוג: png, jpg, jpeg</label>
                      <br />
                      <label>גודל קובץ עד 5MB</label>
                      <StyledDropzone
                        handleFileSave={handleSelectImage}
                        handleRemoveFile={handleDeleteImage}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col className="pr-md-1" md="12">
                    <Button
                      onClick={handleCreatePlantSpot}
                      className="btn-fill mx-1"
                      color="primary"
                      type="submit"
                      style={{ width: "250px" }}
                    >
                      הוספת ספוט
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  sendingRequest: state.plantSpots.sendingRequest,
  plantsList: state.shared.plantsList,
  failed: state.plantSpots.failed,
});

export default connect(mapStateToProps, {
  createPlantSpot,
  getPlants,
  getPlantSpots,
})(CreatePlantSpot);
