import {
  DATA_LOADING,
  PLANTS_LOADED,
  PLANTS_LOAD_FAIL,
  PLANT_LOADED,
  PLANT_LOAD_FAIL,
  PLANT_CREATED,
  PLANT_CREATE_FAIL,
  PLANT_UPDATED,
  PLANT_UPDATE_FAIL,
  PLANT_ACTIVATED,
  PLANT_ACTIVATE_FAIL,
  PLANT_DEACTIVATED,
  PLANT_DEACTIVATE_FAIL,
  SENDING_DATA,
  GALLERY_DELETED,
  GALLERY_DELETE_FAIL,
  PLANTS_EXPORTED,
  PLANTS_EXPORT_FAIL,
  //
  IDENTIFICATION_IMAGES_LOADED,
  IDENTIFICATION_IMAGES_LOAD_FAIL,
  IDENTIFICATION_IMAGE_DELETED,
  IDENTIFICATION_IMAGE_DELETE_FAIL,
  IDENTIFICATION_IMAGE_UPLOADED,
  IDENTIFICATION_IMAGE_UPLOAD_FAIL,
  IDENTIFICATION_IMAGE_DETAILS_LOADED,
  IDENTIFICATION_IMAGE_DETAILS_LOAD_FAIL,
} from "../types/plants";

const initialState = {
  stats: null,
  plantsList: [],
  loading: false,
  singlePlant: null,
  statsLoading: false,
  csvPlantsData: null,
  offset: 0,
  limit: 50,
  active: true,
  totalPlants: 0,
  updatedPlant: false,
  createdPlant: false,
  sendingRequest: false,
  deleteGallery: false,
  deleteVideo: false,
  identificationImages: [],
  totalImages: 0,
  identificationDetails: null,
  imageUpdated: false,
  failed: false,
  failedIdentImage: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case DATA_LOADING:
      return {
        ...state,
        loading: true,
        updatedPlant: false,
        createdPlant: false,
        imageUpdated: false,
        failed: false,
        ailedIdentImage: false,
      };
    case SENDING_DATA:
      return {
        ...state,
        sendingRequest: action.payload,
      };
    case PLANTS_EXPORTED:
      return {
        ...state,
        csvPlantsData: action.payload,
      };
    case PLANTS_LOADED:
      return {
        ...state,
        loading: false,
        failed: false,
        plantsList: action.payload.data.plants,
        totalPlants: action.payload.data.entityCount,
        offset: action.payload.offsetVal,
        active: action.payload.active,
        // limit: action.payload.limitVal,
      };
    case PLANT_LOADED:
      return {
        ...state,
        loading: false,
        failed: false,
        singlePlant: action.payload,
      };
    case PLANT_UPDATED:
    case PLANT_DEACTIVATED:
    case PLANT_ACTIVATED:
      return {
        ...state,
        loading: false,
        updatedPlant: true,
      };
    case PLANT_CREATED:
      return {
        ...state,
        loading: false,
        createdPlant: true,
      };
    case GALLERY_DELETED:
      return {
        ...state,
        loading: false,
        deleteGallery: true,
      };
    case IDENTIFICATION_IMAGES_LOADED:

      return {
        ...state,
        loading: false,
        failed: false,
        identificationImages: action.payload.images,
        totalImages: action.payload.entityCount,
      };
    case IDENTIFICATION_IMAGE_DETAILS_LOADED:
      return {
        ...state,
        loading: false,
        failed: false,
        identificationDetails: action.payload,
      };
    case IDENTIFICATION_IMAGE_UPLOADED:
    case IDENTIFICATION_IMAGE_DELETED:
      return {
        ...state,
        loading: false,
        imageUpdated: true,
      };
    case PLANT_CREATE_FAIL:
    case PLANT_UPDATE_FAIL:
    case PLANT_ACTIVATE_FAIL:
    case PLANT_DEACTIVATE_FAIL:
    case GALLERY_DELETE_FAIL:
      return {
        ...state,
        loading: false,
        statsLoading: false,
        failed: true,
      };
    case IDENTIFICATION_IMAGE_UPLOAD_FAIL:
    case IDENTIFICATION_IMAGE_DELETE_FAIL:
      return {
        ...state,
        loading: false,
        statsLoading: false,
        failedIdentImage: true,
      };
    case PLANTS_LOAD_FAIL:
    case PLANT_LOAD_FAIL:
    case PLANTS_EXPORT_FAIL:
    case IDENTIFICATION_IMAGES_LOAD_FAIL:
    case IDENTIFICATION_IMAGE_DETAILS_LOAD_FAIL:
      return {
        ...state,
        loading: false,
        statsLoading: false,
      };
    default:
      return state;
  }
}
