import { requestWrapper, supportedRequestMethods } from "../../API";
import {
  DATA_LOADING,
  CLIENTS_LOADED,
  CLIENTS_LOAD_FAIL,
  CLIENT_LOADED,
  CLIENT_LOAD_FAIL,
  DELETED_CLIENT,
  DELETE_CLIENT_FAIL,
  MANUAL_SUBSCRIPTION_CREATED,
  MANUAL_SUBSCRIPTION_FAIL,
  DELETED_SUBSCRIPTION,
  DELETE_SUBSCRIPTION_FAIL,
  SENDING_DATA,
  CLIENTS_EXPORTED,
  CLIENTS_EXPORT_FAIL,
} from "../types/clients";

import { createMessage } from "./message";

export const getClients = (offset, limit,query) => async (dispatch) => {
  dispatch({ type: DATA_LOADING });
  let requestParams = {
    method: supportedRequestMethods.GET,
    endpoint: `/admin/clients?offset=${offset}&limit=${limit}${query?`&query=${query}`:''}`,
  };
  let response = await requestWrapper(requestParams);
  if (response && response.success) {
    dispatch({
      type: CLIENTS_LOADED,
      payload: { data: response.data.data, offsetVal: offset },
    });
  } else {
    if (response) dispatch(createMessage(response.data.message));
    dispatch({
      type: CLIENTS_LOAD_FAIL,
    });
  }
};

export const getClient = (id) => async (dispatch) => {
  dispatch({ type: DATA_LOADING });

  let requestParams = {
    method: supportedRequestMethods.GET,
    endpoint: `/admin/clients/${id}`,
  };
  let response = await requestWrapper(requestParams);

  if (response && response.success) {
    dispatch({
      type: CLIENT_LOADED,
      payload: response.data.data,
    });
  } else {
    if (response) dispatch(createMessage(response.data.message));
    dispatch({
      type: CLIENT_LOAD_FAIL,
    });
  }
};

export const deactivateClient = (id, props) => async (dispatch) => {
  dispatch({ type: DATA_LOADING });

  let requestParams = {
    method: supportedRequestMethods.DELETE,
    endpoint: `/admin/clients/${id}`,
  };

  dispatch({ type: SENDING_DATA, payload: true });
  let response = await requestWrapper(requestParams);
  dispatch({ type: SENDING_DATA, payload: false });

  if (response && response.success) {
    dispatch({
      type: DELETED_CLIENT,
      payload: id,
    });
    props.history.push(`/clients`);
  } else {
    if (response) dispatch(createMessage(response.message));
    dispatch({
      type: DELETE_CLIENT_FAIL,
    });
  }
};

export const createManualSubscription = (id, expirationDate, props) => async (
  dispatch
) => {
  dispatch({ type: DATA_LOADING });

  let requestParams = {
    method: supportedRequestMethods.POST,
    endpoint: `/admin/clients/${id}/subscription`,
    body: { expirationDate },
  };

  dispatch({ type: SENDING_DATA, payload: true });
  let response = await requestWrapper(requestParams);
  dispatch({ type: SENDING_DATA, payload: false });

  if (response && response.success) {
    dispatch({
      type: MANUAL_SUBSCRIPTION_CREATED,
      payload: id,
    });
  } else {
    if (response) dispatch(createMessage(response.message));
    dispatch({
      type: MANUAL_SUBSCRIPTION_FAIL,
    });
  }
};

export const deactivateSubscription = (id, props) => async (dispatch) => {
  dispatch({ type: DATA_LOADING });

  let requestParams = {
    method: supportedRequestMethods.DELETE,
    endpoint: `/admin/clients/${id}/subscription`,
  };

  dispatch({ type: SENDING_DATA, payload: true });
  let response = await requestWrapper(requestParams);
  dispatch({ type: SENDING_DATA, payload: false });

  if (response && response.success) {
    dispatch({
      type: DELETED_SUBSCRIPTION,
      payload: id,
    });
  } else {
    if (response) dispatch(createMessage(response.message));
    dispatch({
      type: DELETE_SUBSCRIPTION_FAIL,
    });
  }
};

export const exportClients = () => async (dispatch) => {
  dispatch({ type: DATA_LOADING });

  let requestParams = {
    method: supportedRequestMethods.GET,
    endpoint: `/admin/clients/export-csv`,
  };

  dispatch({ type: SENDING_DATA, payload: true });
  let response = await requestWrapper(requestParams);
  dispatch({ type: SENDING_DATA, payload: false });

  if (response && response.success) {
    dispatch({
      type: CLIENTS_EXPORTED,
      payload: response.data,
    });
  } else {
    if (response) dispatch(createMessage(response.data.message));
    dispatch({
      type: CLIENTS_EXPORT_FAIL,
    });
  }
};
