import {
    DATA_LOADING,
    STATISTICS_LOADED,
    STATISTICS_LOAD_FAIL,
    RECEIPTS_EXPORTED
} from "../types/dashboard";
import {CLIENTS_EXPORT_FAIL} from "../types/clients";

const initialState = {
    stats: null,
    statistics: null,
    csvReceipts: null,
    loading: false,
    statsLoading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case DATA_LOADING:
            return {
                ...state,
                loading: true,
            };
        case STATISTICS_LOADED:
            return {
                ...state,
                loading: false,
                statistics: action.payload,
            };
        case RECEIPTS_EXPORTED:
            return {
                ...state,
                csvReceipts: action.payload,
            };
        case CLIENTS_EXPORT_FAIL:
        case STATISTICS_LOAD_FAIL:
            return {
                ...state,
                loading: false,
                statsLoading: false,
            };
        default:
            return state;
    }
}
