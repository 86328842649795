export const DATA_LOADING = "DATA_LOADING";
export const CATEGORIES_LOAD_FAIL = "CATEGORIES_LOAD_FAIL";

export const ARTICLE_CATEGORIES_LOADED = "ARTICLE_CATEGORIES_LOADED";
export const ARTICLE_CATEGORIES_LOAD_FAIL = "ARTICLE_CATEGORIES_LOAD_FAIL";

export const RECIPE_CATEGORIES_LOADED = "RECIPE_CATEGORIES_LOADED";
export const RECIPE_CATEGORIES_LOAD_FAIL = "RECIPE_CATEGORIES_LOAD_FAIL";

export const PLANT_CATEGORIES_LOADED = "PLANT_CATEGORIES_LOADED";
export const PLANT_CATEGORIES_LOAD_FAIL = "PLANT_CATEGORIES_LOAD_FAIL";

export const PLANTS_LOADED = "PLANTS_LOADED";
export const PLANTS_LOAD_FAIL = "PLANTS_LOAD_FAIL";

export const PLANT_CHARASTERISTICS_LOADED = "PLANT_CHARASTERISTICS_LOADED";
export const PLANT_CHARASTERISTICS_LOAD_FAIL =
  "PLANT_CHARASTERISTICS_LOAD_FAIL";
