import { createMessage } from "./message";

import { requestWrapper, supportedRequestMethods } from "../../API";

import {
  USER_LOADING,
  SEND_OTP_SUCCESS,
  SEND_OTP_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  RESET_EMAIL,
  LOGOUT,
  AUTH_VERIFIED_SUCCESS,
  AUTH_VERIFIED_FAIL,
} from "../types/auth";

/*
 * action creators
 */

export const sendMail = (email) => async (dispatch) => {
  dispatch({ type: USER_LOADING });

  let requestParams = {
    method: supportedRequestMethods.POST,
    endpoint: "/admin/login",
    body: { email },
  };

  let response = await requestWrapper(requestParams);

  if (response && response.success) {
    dispatch({
      type: SEND_OTP_SUCCESS,
    });
  } else {
    if (response) dispatch(createMessage(response.data.errors[0].message));
    // dispatch(createMessage("Error"));
    dispatch({
      type: SEND_OTP_FAIL,
    });
  }
};

export const resetEmail = () => async (dispatch) => {
  dispatch({ type: RESET_EMAIL });
};

export const login = (email, otp) => async (dispatch) => {
  dispatch({ type: USER_LOADING });

  let requestParams = {
    method: supportedRequestMethods.POST,
    endpoint: `admin/login/`,
    body: { email, otp },
  };

  let response = await requestWrapper(requestParams);

  if (response && response.success) {
    dispatch({
      type: LOGIN_SUCCESS,
    });
  } else {
    if (response) dispatch(createMessage(response.data.errors[0].message));
    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

export const logout = () => async (dispatch) => {
  let requestParams = {
    method: supportedRequestMethods.POST,
    endpoint: `admin/logout/`,
    body: {},
  };

  await requestWrapper(requestParams);

  dispatch({ type: LOGOUT });
};

export const verifySession = () => async (dispatch) => {
  dispatch({ type: USER_LOADING });

  let requestParams = {
    method: supportedRequestMethods.POST,
    endpoint: `admin/verify-authentication/`,
    body: {},
  };

  let response = await requestWrapper(requestParams);

  if (response && response.success) {
    dispatch({
      type: AUTH_VERIFIED_SUCCESS,
    });
  } else {
    if (response) dispatch(createMessage(response.message));
    dispatch({
      type: AUTH_VERIFIED_FAIL,
    });
  }
};
