export const DATA_LOADING = "DATA_LOADING";

export const SENDING_DATA = "SENDING_DATA";

export const CLIENTS_LOADED = "CLIENTS_LOADED";
export const CLIENTS_LOAD_FAIL = "CLIENTS_LOAD_FAIL";

export const CLIENT_LOADED = "CLIENT_LOADED";
export const CLIENT_LOAD_FAIL = "CLIENT_LOAD_FAIL";

export const DELETED_CLIENT = "DELETED_CLIENT";
export const DELETE_CLIENT_FAIL = "DELETE_CLIENT_FAIL";

export const MANUAL_SUBSCRIPTION_CREATED = "MANUAL_SUBSCRIPTION_CREATED";
export const MANUAL_SUBSCRIPTION_FAIL = "MANUAL_SUBSCRIPTION_FAIL";

export const DELETED_SUBSCRIPTION = "DELETED_SUBSCRIPTION";
export const DELETE_SUBSCRIPTION_FAIL = "DELETE_SUBSCRIPTION_FAIL";

export const CLIENTS_EXPORTED = "CLIENTS_EXPORTED";
export const CLIENTS_EXPORT_FAIL = "CLIENTS_EXPORT_FAIL";
