import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import YoutubePlayer1 from "react-player/youtube";
import VimeoPlayer1 from "react-player/vimeo";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import TextareaAutosize from "react-textarea-autosize";
import LoadingModal from "views/components/LoadingModal";
import makeAnimated from "react-select/animated";
import { connect } from "react-redux";
import { createRecipe } from "redux/actions/recipes";
import ReactBSAlert from "react-bootstrap-sweetalert";
import StyledDropzone from "../../components/Dropzone";
import { getCategories, getPlants } from "redux/actions/shared";
import { YouTube, Vimeo } from "../../constants";
import ToastError, { ERROR_10, REQUIRED_FIELD } from "../../components/Errors";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  FormGroup,
  Input,
  Row,
  Col,
  Modal,
  ModalBody,
  CustomInput,
} from "reactstrap";

let timer = null;

/**
 * Component for creating an new recipe
 */
const CreateRecipe = (props) => {
  const animatedComponents = makeAnimated();
  const [newRecipe, setNewRecipe] = useState({
    title: "",
    description: "",
    servings: "",
    preparationTime: "",
    secondaryImages: [],
    video: {},
    premium: false,
    primaryImage: null,
    categories: null,
    generateNotification: false,
    relatedPlants: null,
  });

  const [errorTitle, setErrorTitle] = useState(null);
  const [errorDescription, setErrorDescription] = useState(null);
  const [errorServings, setErrorServings] = useState(null);
  const [errorPreparation, setErrorPreparation] = useState(null);
  const [errorIngredients, setErrorIngredients] = useState(null);
  const [errorInstructions, setErrorInstructions] = useState(null);
  const [errorPrimary, setErrorPrimary] = useState(null);
  const [errorUrl, setErrorUrl] = useState(null);
  const [modalFail, setModalFail] = useState(false);

  const videoOptions = [
    { value: "Youtube", label: "Youtube" },
    { value: "Vimeo", label: "Vimeo" },
  ];

  const [alert, setAlert] = useState(null);
  const [ingredientsList, setIngredientsList] = useState([""]);
  const [instructionsList, setInstructionsList] = useState([""]);
  const [videoUrlId, setVideoUrlId] = useState("");
  const [videoSource, setVideoSource] = useState("");
  const [videoUrlInput, setVideoUrlInput] = useState("");
  const [categories, setCategories] = useState(props.recipeCategories);
  const [imageList] = useState(["", "", "", ""]);
  const [notification, setNotification] = useState(false);

  /**
   * function that handles generate notification to clients when creating new recipe
   */
  const handleNotificationSwitch = () => {
    setNewRecipe({ ...newRecipe, generateNotification: !notification });
    setNotification(!notification);
  };

  /**
   * function that handles selecting video source
   */
  const handleVideoSelect = (selectedOption) => {
    setErrorUrl(null);

    if (selectedOption !== null) {
      if (videoSource.toLowerCase() !== selectedOption.value.toLowerCase()) {
        setVideoUrlId("");
        setVideoUrlInput("");
      }

      setVideoSource(selectedOption.value);
    } else {
      setVideoSource("");
      setVideoUrlId("");
    }
  };

  /**
   * function that handles video url input
   */
  const handleVideoUrlId = (e) => {
    let urlId = e.target.value;
    setVideoUrlInput(urlId);

    if (urlId !== "") {
      let isValidId = validateUrlID(urlId);

      if (isValidId) {
        setVideoUrlId(urlId);
      }

      setErrorUrl(null);
    } else {
      setVideoUrlId("");
      setErrorUrl(<ToastError error={REQUIRED_FIELD} />);
    }
  };

  /**
   * function that validates url id
   */
  const validateUrlID = (urlId) => {
    let regex = /^[0-9a-zA-Z]+$/;
    if (urlId.match(regex)) {
      return true;
    }
    return false;
  };

  /**
   * function that handles selecting categories
   */
  const handleSelectCategories = (selected) => {
    if (selected) {
      let categoriesIds = selected.map((category) => {
        return category.id;
      });

      setNewRecipe({ ...newRecipe, categories: categoriesIds });
    } else {
      setNewRecipe({ ...newRecipe, categories: null });
    }
  };

  const handleOnChange = (e) => {
    setNewRecipe({ ...newRecipe, [e.target.name]: e.target.value });
  };

  /**
   * functions that handles adding ingredients
   */
  const handleInputIngredient = (e, index) => {
    const list = [...ingredientsList];
    list[index] = e.target.value;
    //setNewRecipe({ ...newRecipe, ingredients: list });
    setIngredientsList(list);
  };

  const handleAddIngredient = () => {
    let size = ingredientsList.length;
    if (ingredientsList[size - 1] !== "") {
      setIngredientsList([...ingredientsList, ""]);
    }
  };

  const handleRemoveIngredient = (index) => {
    const list = [...ingredientsList];
    list.splice(index, 1);
    setIngredientsList(list);
    //setNewRecipe({ ...newRecipe, ingredients: list });
  };

  /**
   * functions that handles adding instructions
   */
  const handleInputInstruction = (e, index) => {
    const list = [...instructionsList];
    list[index] = e.target.value;
    //setNewRecipe({ ...newRecipe, instructions: list });
    setInstructionsList(list);
  };

  const handleAddInstruction = () => {
    let size = instructionsList.length;
    if (instructionsList[size - 1] !== "") {
      setInstructionsList([...instructionsList, ""]);
    }
  };

  const handleRemoveInstruction = (index) => {
    const list = [...instructionsList];
    list.splice(index, 1);
    setInstructionsList(list);
    //setNewRecipe({ ...newRecipe, instructions: list });
  };

  /**
   * function that handles primary image
   */
  const handleChangePrimary = (primary) => {
    setNewRecipe({ ...newRecipe, primaryImage: primary[0] });
  };

  /**
   * function that handles secondary image
   */
  const handleChangeSecondary = (secondary) => {
    setNewRecipe({
      ...newRecipe,
      secondaryImages: [...newRecipe.secondaryImages, secondary[0]],
    });
  };

  /**
   * function that handles delete primary image
   */
  const handleDeletePrimary = () => {
    setNewRecipe({ ...newRecipe, primaryImage: null });
  };

  /**
   * function that handles delete secondary image
   */
  const handleDeleteSecondary = (secondary) => {
    let updatedList = newRecipe.secondaryImages.filter(
      (val) => val.preview === secondary[0].preview
    );
    setNewRecipe({
      ...newRecipe,
      secondaryImages: updatedList,
    });
  };

  /**
   * function that handles if aticle is premium, default is false
   */
  const handlePremium = () => {
    setNewRecipe({ ...newRecipe, premium: !newRecipe.premium });
  };

  /*
   * function for getting options from backend
   * using setTimeout for optimization - does not send request to backend until user stops typing
   */
  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      clearTimeout(timer);

      timer = setTimeout(async () => {
        let list = [];

        const data = await props.getPlants(0, 10, inputValue);
        list = data.plants.map((plant) => {
          return {
            id: plant._id,
            value: plant.name,
            label: plant.name,
          };
        });

        resolve(list);
      }, 500);
    });

  /**
   * function that handles selecting related plants
   */
  const handleSelectPlants = (selected) => {
    if (selected) {
      let plantsIds = selected.map((plant) => {
        return plant.id;
      });

      setNewRecipe({ ...newRecipe, relatedPlants: plantsIds });
    } else {
      setNewRecipe({ ...newRecipe, relatedPlants: null });
    }
  };

  /**
   * functions for alerts
   */
  const showErrorAlert = () => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="נא למלא את כל שדות החובה"
        onConfirm={() => {
          setAlert(null);
        }}
        confirmBtnBsStyle="error"
        confirmBtnText="אישור"
        btnSize=""
      >
        שדות חובה מסומנים בכוכבית
      </ReactBSAlert>
    );
  };

  const showAlert = (toCreate) => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="האם את/ה בטוח/ה?"
        onConfirm={() => {
          props.createRecipe(toCreate, props);
          setAlert(null);
        }}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText="אישור"
        cancelBtnText="ביטול"
        showCancel
        btnSize=""
      >
        הפעולה הבאה תיצור מתכון חדש במערכת
      </ReactBSAlert>
    );
  };

  /**
   * function to reset all error messages
   */
  const resetErrorMessages = () => {
    setErrorTitle(null);
    setErrorDescription(null);
    setErrorPreparation(null);
    setErrorServings(null);
    setErrorIngredients(null);
    setErrorInstructions(null);
    setErrorPrimary(null);
  };

  /**
   * function thats handles creating a new recipe
   */
  const handleCreateRecipe = () => {
    let toCreate = {};
    let createFlag = true;
    let size1 = ingredientsList.length;
    let size2 = instructionsList.length;

    resetErrorMessages();

    if (newRecipe.title === "") {
      createFlag = false;
      setErrorTitle(<ToastError error={REQUIRED_FIELD} />);
    }

    if (newRecipe.description === "" || newRecipe.description < 10) {
      createFlag = false;
      setErrorDescription(<ToastError error={ERROR_10} />);
    }

    if (newRecipe.preparationTime === "" || newRecipe.preparationTime < 1) {
      createFlag = false;
      setErrorPreparation(<ToastError error={REQUIRED_FIELD} />);
    }

    if (newRecipe.servings === "" || newRecipe.servings < 1) {
      createFlag = false;
      setErrorServings(<ToastError error={REQUIRED_FIELD} />);
    }

    if (ingredientsList[size1 - 1] === "" || ingredientsList.length === 0) {
      createFlag = false;
      setErrorIngredients(<ToastError error={REQUIRED_FIELD} />);
    }

    if (instructionsList[size2 - 1] === "" || instructionsList.length === 0) {
      createFlag = false;
      setErrorInstructions(<ToastError error={REQUIRED_FIELD} />);
    }

    if (newRecipe.primaryImage === null) {
      createFlag = false;
      setErrorPrimary(<ToastError error={REQUIRED_FIELD} />);
    }

    if (createFlag) {
      toCreate = { ...toCreate, title: newRecipe.title };
      toCreate = { ...toCreate, description: newRecipe.description };
      toCreate = {
        ...toCreate,
        preparationTime: Number(newRecipe.preparationTime),
      };
      toCreate = { ...toCreate, servings: Number(newRecipe.servings) };
      toCreate = { ...toCreate, ingredients: ingredientsList };
      toCreate = { ...toCreate, instructions: instructionsList };
      toCreate = { ...toCreate, primaryImage: newRecipe.primaryImage };

      if (newRecipe.premium) {
        toCreate = { ...toCreate, premium: newRecipe.premium };
      }

      if (newRecipe.categories !== null) {
        toCreate = { ...toCreate, categories: newRecipe.categories };
      }

      if (newRecipe.relatedPlants !== null) {
        toCreate = { ...toCreate, relatedPlants: newRecipe.relatedPlants };
      }

      if (videoSource !== "" && videoUrlId !== "" && errorUrl === null) {
        toCreate = {
          ...toCreate,
          video: { source: videoSource.toLowerCase(), url: videoUrlId },
        };
      }

      if (newRecipe.secondaryImages.length > 0) {
        toCreate = { ...toCreate, secondaryImages: newRecipe.secondaryImages };
      }

      toCreate = {
        ...toCreate,
        generateNotification: newRecipe.generateNotification,
      };

      resetErrorMessages();

      console.log(toCreate);

      showAlert(toCreate);
    } else {
      showErrorAlert();
    }
  };

  /**
   * useEffect for getting recipe's categories
   */
  useEffect(() => {
    if (props.recipeCategories === null) {
      props.getCategories("recipe");
    }
  }, []);

  /**
   * useEffect for mapping the categories to id, value, label
   * needed for react-select component
   */
  useEffect(() => {
    if (props.recipeCategories !== null) {
      setCategories(
        props.recipeCategories.map((category) => {
          return {
            id: category._id,
            value: category.name,
            label: category.name,
          };
        })
      );
    }
  }, [props.recipeCategories]);

  useEffect(() => {
    if (props.failed) {
      setModalFail(props.failed);
    }
  }, [props.failed]);

  return (
    <>
      <div className="content">
        {alert}

        <LoadingModal loading={props.sendingRequest} />

        <Modal isOpen={modalFail} className="text-center">
          <ModalBody>
            <h4>הפעולה נכשלה</h4>
            <Row>
              <Col md="12" className="d-flex justify-content-center">
                <Button
                  color="primary"
                  onClick={() => {
                    setModalFail(!modalFail);
                  }}
                >
                  אישור
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>

        <h2 className="text-center">הוספת מתכון חדש</h2>
        <Row>
          <Col xs={12} className="text-right">
            <Link to={"/recipes"}>
              <Button
                className="btn-fill mx-1"
                color="primary"
                type="submit"
                style={{ width: "250px" }}
              >
                חזרה{" "}
              </Button>
            </Link>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="text-right">
            <Card style={{ padding: "10px" }}>
              <CardBody>
                <Row>
                  <Col className="pr-md-1" md="6">
                    <FormGroup>
                      <h4>כותרת המתכון *</h4>
                      <Input
                        name="title"
                        value={newRecipe.title}
                        type="text"
                        required
                        maxLength="60"
                        onChange={handleOnChange}
                      />
                      {errorTitle}
                    </FormGroup>
                  </Col>

                  <Col className="pr-md-1 d-flex justify-content-center" md="2">
                    <FormGroup>
                      <h4>זמן הכנה בדקות *</h4>
                      <Input
                        name="preparationTime"
                        type="number"
                        value={newRecipe.preparationTime}
                        max="6000"
                        required
                        onChange={handleOnChange}
                      />
                      {errorPreparation}
                    </FormGroup>
                  </Col>

                  <Col className="pr-md-1 d-flex justify-content-center" md="2">
                    <FormGroup>
                      <h4>מספר מנות *</h4>
                      <Input
                        name="servings"
                        type="number"
                        value={newRecipe.servings}
                        required
                        onChange={handleOnChange}
                      />
                      {errorServings}
                    </FormGroup>
                  </Col>

                  <Col className="pr-md-1 d-flex justify-content-center" md="1">
                    <FormGroup>
                      <h4 style={{ marginBottom: "8px" }}>פרימיום</h4>
                      <Input
                        name="premium"
                        type="checkbox"
                        style={{
                          height: "40px",
                          width: "40px",
                        }}
                        checked={newRecipe.premium}
                        onChange={handlePremium}
                      />
                      <br />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col className="pr-md-1" md="11">
                    <FormGroup>
                      <h4>תיאור המתכון *</h4>
                      <TextareaAutosize
                        className="autosize-textarea"
                        name="description"
                        value={newRecipe.description}
                        required
                        minLength="10"
                        maxLength="1000"
                        onChange={handleOnChange}
                      />

                      {errorDescription}
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col className="pr-md-1" md="12">
                    <FormGroup>
                      <h4 style={{ margin: "0" }}>מצרכים *</h4>
                      <ul>
                        {ingredientsList.map((ingredient, index) => {
                          return (
                            <div key={index}>
                              <Col md="11">
                                <li
                                  style={{
                                    margin: "0px 0px 10px 10px",
                                    paddingRight: "10px",
                                    color: "#3ec7af",
                                    fontSize: "25px",
                                  }}
                                >
                                  <Input
                                    name="ingredients"
                                    type="text"
                                    required
                                    maxLength="1000"
                                    value={ingredient}
                                    onChange={(e) =>
                                      handleInputIngredient(e, index)
                                    }
                                  />
                                </li>
                              </Col>
                              <Col md="1">
                                {index !== 0 && (
                                  <label
                                    className="label-secondary"
                                    style={{
                                      cursor: "pointer",
                                      fontWeight: "bold",
                                      fontSize: "16px",
                                      marginTop: "8px",
                                    }}
                                    onClick={() =>
                                      handleRemoveIngredient(index)
                                    }
                                  >
                                    X
                                  </label>
                                )}
                              </Col>
                            </div>
                          );
                        })}
                      </ul>
                    </FormGroup>
                  </Col>
                </Row>

                <Row>{errorIngredients}</Row>

                <Row>
                  <label
                    onClick={handleAddIngredient}
                    style={{ cursor: "pointer", fontWeight: "bold" }}
                  >
                    הוספת מצרכים
                  </label>
                </Row>
                <br />

                <Row>
                  <Col className="pr-md-1" md="12">
                    <FormGroup>
                      <h4 style={{ margin: "0" }}>אופן הכנה *</h4>
                      <ul>
                        {instructionsList.map((instruction, index) => {
                          return (
                            <div key={index}>
                              <Col md="11">
                                <li
                                  style={{
                                    margin: "0px 0px 10px 10px",
                                    paddingRight: "10px",
                                    color: "#3ec7af",
                                    fontSize: "25px",
                                  }}
                                >
                                  <Input
                                    name="instructions"
                                    type="text"
                                    required
                                    maxLength="1000"
                                    value={instruction}
                                    onChange={(e) =>
                                      handleInputInstruction(e, index)
                                    }
                                  />
                                </li>
                              </Col>
                              <Col md="1">
                                {index !== 0 && (
                                  <label
                                    className="label-secondary"
                                    style={{
                                      cursor: "pointer",
                                      fontWeight: "bold",
                                      fontSize: "16px",
                                      marginTop: "8px",
                                    }}
                                    onClick={() =>
                                      handleRemoveInstruction(index)
                                    }
                                  >
                                    X
                                  </label>
                                )}
                              </Col>
                            </div>
                          );
                        })}
                      </ul>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>{errorInstructions}</Row>
                <Row>
                  <label
                    onClick={handleAddInstruction}
                    style={{ cursor: "pointer", fontWeight: "bold" }}
                  >
                    הוספת הוראות
                  </label>
                </Row>
                <br />

                <Row>
                  <Col className="pr-md-1" md="11">
                    <FormGroup>
                      <h4>קטגוריות</h4>
                      <Select
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        isMulti
                        options={categories}
                        placeholder="בחר/י קטגוריות"
                        onChange={handleSelectCategories}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col className="pr-md-1" md="11">
                    <FormGroup>
                      <h4>צמחים</h4>
                      <AsyncSelect
                        isMulti
                        components={animatedComponents}
                        placeholder="הקלידו שמות צמחים"
                        loadOptions={promiseOptions}
                        onChange={handleSelectPlants}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col className="pr-md-1" md="5">
                    <FormGroup>
                      <h4>סוג וידאו</h4>
                      <Select
                        isClearable={true}
                        isRtl={true}
                        isSearchable={true}
                        options={videoOptions}
                        onChange={handleVideoSelect}
                      />
                    </FormGroup>
                  </Col>

                  {videoSource !== "" && (
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <h4>וידאו ID</h4>
                        <Input
                          name="videoLink"
                          type="text"
                          onChange={handleVideoUrlId}
                          value={videoUrlInput}
                        />
                        {errorUrl}
                      </FormGroup>
                    </Col>
                  )}
                </Row>

                {errorUrl === null && (
                  <Row>
                    <Col className="pr-md-1" md="12">
                      {videoSource === "Youtube" && videoUrlId !== "" && (
                        <YoutubePlayer1 url={`${YouTube}${videoUrlId}`} />
                      )}

                      {videoSource === "Vimeo" && videoUrlId !== "" && (
                        <VimeoPlayer1
                          controls={true}
                          url={`${Vimeo}${videoUrlId}`}
                        />
                      )}
                    </Col>
                  </Row>
                )}
                <br />

                <Row>
                  <Col className="pr-md-1" md="12">
                    <FormGroup>
                      <h4 style={{ margin: "0" }}>תמונה ראשית *</h4>
                      {errorPrimary}
                      <label>ניתן להעלות קבצים מסוג: png, jpg, jpeg</label>
                      <br />
                      <label>גודל קובץ עד 5MB</label>
                      <StyledDropzone
                        handleFileSave={handleChangePrimary}
                        handleRemoveFile={handleDeletePrimary}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col className="pr-md-1" md="12">
                    <FormGroup>
                      <h4 style={{ margin: "0" }}>תמונות משניות</h4>
                      <label>ניתן להעלות קבצים מסוג: png, jpg, jpeg</label>
                      <br />
                      <label>גודל כל קובץ עד 5MB</label>
                      <h5>התמונות ישמרו ויוצגו לפי הסדר הנ"ל מימין לשמאל</h5>
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  {imageList.map((val, i) => {
                    return (
                      <Col className="pr-md-1" md="3" key={i}>
                        <FormGroup>
                          <StyledDropzone
                            handleFileSave={handleChangeSecondary}
                            handleRemoveFile={handleDeleteSecondary}
                          />
                        </FormGroup>
                      </Col>
                    );
                  })}
                </Row>

                <br />
                <Row>
                  <Col className="pr-md-1" md="12">
                    <h4>התראת לקוחות על מתכון חדש</h4>
                    <CustomInput
                      id="switchInput"
                      type="switch"
                      label={notification ? "כן" : "לא"}
                      onChange={handleNotificationSwitch}
                      checked={notification ? true : false}
                    />
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col className="pr-md-1" md="12">
                    <Button
                      onClick={handleCreateRecipe}
                      className="btn-fill mx-1"
                      color="primary"
                      type="submit"
                      style={{ width: "250px" }}
                    >
                      צור מתכון
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  recipeCategories: state.shared.recipeCategories,
  sendingRequest: state.recipes.sendingRequest,
  plantsList: state.shared.plantsList,
  failed: state.recipes.failed,
});

export default connect(mapStateToProps, {
  createRecipe,
  getCategories,
  getPlants,
})(CreateRecipe);
