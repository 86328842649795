import {requestWrapper, supportedRequestMethods} from "../../API";
import {
    ADMINS_LOADED,
    ADMINS_LOAD_FAIL,
    DATA_LOADING,
    ADMIN_CREATED,
    ADMIN_CREATE_FAIL,
    ADMIN_DELETED,
    ADMIN_DELETE_FAIL,
    ADMIN_CROWNED,
    ADMIN_CROWN_FAIL,
    ADMIN_PROFILE_SUCCESS,
    ADMIN_PROFILE_FAIL,
    SENDING_DATA,
} from "../types/admins";
import {createMessage} from "./message";

/**
 * async function that sends a request to get admins
 */
export const getAdmins = (offset, limit, query) => async (dispatch) => {
    dispatch({type: DATA_LOADING});

    let requestParams = {
        method: supportedRequestMethods.GET,
        endpoint: `/admin?offset=${offset}&limit=${limit}${query ? `&query=${query}` : ''}`,
    };
    let response = await requestWrapper(requestParams);
    if (response && response.success) {
        dispatch({
            type: ADMINS_LOADED,
            payload: {data: response.data.data, offsetVal: offset},
        });
    } else {
        if (response) dispatch(createMessage(response.data.message));
        dispatch({
            type: ADMINS_LOAD_FAIL,
        });
    }
};

/**
 * async function that sends a request to create a new admin
 */
export const createAdmin = (name, email, props) => async (dispatch) => {
    let requestParams = {
        method: supportedRequestMethods.POST,
        endpoint: "/admin",
        body: {name, email},
    };

    dispatch({type: SENDING_DATA, payload: true});
    let response = await requestWrapper(requestParams);
    dispatch({type: SENDING_DATA, payload: false});

    if (response && response.success) {
        dispatch({
            type: ADMIN_CREATED,
            payload: true,
        });
        props.history.push("/admins");
    } else {
        if (response) {
            dispatch(createMessage(response.data.errors[0].message));
        }
        dispatch({
            type: ADMIN_CREATE_FAIL,
        });
    }
};

/**
 * async function that sends a request to delete an admin
 */
export const deleteAdmin = (id, props) => async (dispatch) => {
    let requestParams = {
        method: supportedRequestMethods.DELETE,
        endpoint: `/admin/${id}`,
    };

    dispatch({type: SENDING_DATA, payload: true});
    let response = await requestWrapper(requestParams);
    dispatch({type: SENDING_DATA, payload: false});

    if (response && response.success) {
        dispatch({
            type: ADMIN_DELETED,
            payload: id,
        });
        props.history.push("/admins");
    } else {
        if (response) dispatch(createMessage(response.data.message));
        dispatch({
            type: ADMIN_DELETE_FAIL,
        });
    }
};

/**
 * async function that sends a request to crown an admin
 */
export const crownAdmin = (id, props) => async (dispatch) => {
    let requestParams = {
        method: supportedRequestMethods.PATCH,
        endpoint: `/admin/${id}/crown`,
    };

    dispatch({type: SENDING_DATA, payload: true});
    let response = await requestWrapper(requestParams);
    dispatch({type: SENDING_DATA, payload: false});

    if (response && response.success) {
        dispatch({
            type: ADMIN_CROWNED,
        });
        props.history.push("/dashboard");
    } else {
        if (response) dispatch(createMessage(response.data.message));
        dispatch({
            type: ADMIN_CROWN_FAIL,
        });
    }
};

/**
 * async function that sends a request to get current admin's profile to check if crowned
 */
export const getAdminProfile = () => async (dispatch) => {
    dispatch({type: DATA_LOADING});

    let requestParams = {
        method: supportedRequestMethods.GET,
        endpoint: `admin/profile`,
    };

    let response = await requestWrapper(requestParams);

    if (response && response.success) {
        dispatch({
            type: ADMIN_PROFILE_SUCCESS,
            payload: response.data.data,
        });
    } else {
        if (response) dispatch(createMessage(response.message));
        dispatch({
            type: ADMIN_PROFILE_FAIL,
        });
    }
};
