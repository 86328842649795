import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PaginationComponent from "react-reactstrap-pagination";
import { getPlants } from "../../../redux/actions/plants";
import premiumIcon from "../../../assets/img/premium.png";
import { URL_API } from "../../../path";
import Spinner from "components/Spinner";
import TextTruncate from "react-text-truncate";
import noImage from "../../../assets/img/no-image.jpg";
import { verifySession } from "../../../redux/actions/auth";

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  CardImg,
  CardImgOverlay,
  Input,
  CustomInput,
  CardFooter,
  Modal,
  ModalBody,
} from "reactstrap";
import { Link } from "react-router-dom";

/*
 * PlantsList Component for viewing all existing plants
 */
const PlantsList = (props) => {
  const {} = props;

  const [plantsList, setPlantsList] = useState([
    {
      _id: "",
      name: "",
      primaryImage: {
        path: "",
      },
    },
  ]);
  const [offset, setOffset] = useState(props.offset);
  const [limit] = useState(50);
  const [active, setActive] = useState(props.active);
  const [searchPlant, setSearchplant] = useState("");
  const [modalSuccess, setModalSuccess] = useState(false);
  const [timer, setTimer] = useState(null);

  /**
   * function that handles search plants
   * using timeout for optimization, sending request with search value only after user stops typing
   */
  const handleSearchInput = (e) => {
    setSearchplant(e.target.value);

    let inputVal = e.target.value;
    setSearchplant(inputVal);
    if (timer) {
      clearTimeout(timer);
    }

    setTimer(
      setTimeout(() => {
        setTimer(null);
        props.getPlants(offset, limit, active, inputVal);
      }, 500)
    );
  };

  /**
   * function that handles selecting a page
   * gets plants from backend with new offset
   */
  const handlePageSelect = (selectedPage) => {
    setOffset(selectedPage - 1);
    props.getPlants(selectedPage - 1, limit, active, searchPlant);
  };

  /**
   * function that handles getting active / not active plants
   */
  const handleActiveSwitch = () => {
    setOffset(0);
    props.getPlants(offset, limit, !active, searchPlant);
    setActive(!active);
  };

  /**
   * useEffect that gets active plants
   * offset starts at 0 and number of recipes per page is limited is 50
   */
  useEffect(() => {
    props.verifySession();
    props.getPlants(offset, limit, active, searchPlant);
  }, []);

  useEffect(() => {
    if (props.plantsList !== null) {
      setPlantsList(props.plantsList);
    }
  }, [props.plantsList]);

  useEffect(() => {
    if (props.createdPlant) {
      setModalSuccess(!modalSuccess);
    }
  }, [props.createdPlant]);

  return (
    <>
      <Modal isOpen={modalSuccess} className="text-center">
        <ModalBody>
          <h4>הפעולה בוצעה בהצלחה</h4>
          <Row>
            <Col md="12" className="d-flex justify-content-center">
              <Button
                color="primary"
                onClick={() => {
                  setModalSuccess(!modalSuccess);
                }}
              >
                אישור
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>

      <div className="content rtl">
        <Row>
          <Col xs={12} className="text-right"></Col>
        </Row>
        <Row>
          <Col md="12">
            <h1 className="text-center">רשימת צמחים</h1>
          </Col>
        </Row>

        <Row>
          <Col md="8" className="d-flex justify-content-start">
            <Link to={"/plants/create"}>
              <Button
                className="btn-fill mx-1"
                color="primary"
                type="submit"
                style={{ width: "250px" }}
              >
                צור צמח חדש{" "}
              </Button>
            </Link>
            <Input
              style={{
                height: "40px",
                alignSelf: "center",
                marginRight: "10px",
              }}
              placeholder="סינון לפי צמח"
              value={searchPlant}
              onChange={handleSearchInput}
            ></Input>
          </Col>
          <Col
            md="4"
            className="d-flex justify-content-end"
            style={{ alignSelf: "center" }}
          >
            <CustomInput
              id={1234}
              type="switch"
              label={active ? "צמחים פעילים" : "צמחים לא פעילים"}
              onChange={handleActiveSwitch}
              checked={active ? true : false}
            ></CustomInput>
          </Col>
        </Row>

        <hr />

        {plantsList.length === 0 && timer === null && !props.loading && (
          <div className="text-center">
            <br />
            <h3 style={{ color: "#3ec7af" }}>לא נמצאו צמחים</h3>
          </div>
        )}

        {props.loading || timer ? (
          <div className="text-center">
            <br />
            <Spinner />
            <h3 style={{ color: "#3ec7af" }}>טוען נתונים...</h3>
          </div>
        ) : (
          <>
            <Row className="text-right">
              {plantsList.map((plant, key) => {
                return (
                  <Col md="3" key={key}>
                    <Card>
                      <Link
                        to={{
                          pathname: `/plants/${plant._id}`,
                        }}
                      >
                        <CardImg
                          top
                          width="100%"
                          style={{ height: "320px", objectFit: "cover" }}
                          // src={`${URL_API}${plant.primaryImage.path}`}
                          src={
                            plant.primaryImage.path !== ""
                              ? `${URL_API}${plant.primaryImage.path}`
                              : noImage
                          }
                          alt="תמונה ראשית"
                        />
                        {plant.premium && (
                          <CardImgOverlay>
                            <CardImg
                              src={premiumIcon}
                              alt="premium"
                              style={{
                                width: "25px",
                                height: "20px",
                              }}
                            />
                          </CardImgOverlay>
                        )}
                      </Link>

                      <CardBody>
                        <CardTitle
                          style={{ fontSize: "16px", fontWeight: "bold" }}
                        >
                          <TextTruncate line={1} text={plant.name} />
                        </CardTitle>
                      </CardBody>
                      <CardFooter>
                        <Link
                          style={{
                            color: "rgb(62,199,175)",
                            fontWeight: "bold",
                          }}
                          to={{
                            pathname: `/plants/${plant._id}`,
                            plant: plant,
                          }}
                        >
                          <Button className="lukit-button">מידע נוסף</Button>
                        </Link>
                      </CardFooter>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </>
        )}
      </div>
      <div
        style={{
          position: "absolute",
          left: "0",
          right: "0",
          overflow: "hidden",
        }}
      >
        {plantsList.length > 0 && (
          <Row>
            <Card
              style={{
                backgroundColor: "transparent",
                boxShadow: "none",
              }}
            >
              <Col md="12" className="d-flex justify-content-center">
                <PaginationComponent
                  totalItems={props.totalPlants}
                  className="pagination"
                  // totalItems={500}
                  pageSize={limit}
                  onSelect={handlePageSelect}
                  firstPageText="ראשון"
                  previousPageText="הקודם"
                  nextPageText="הבא"
                  lastPageText="אחרון"
                />
              </Col>
            </Card>
          </Row>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  plantsList: state.plants.plantsList,
  offset: state.plants.offset,
  limit: state.plants.limit,
  totalPlants: state.plants.totalPlants,
  active: state.plants.active,
  createdPlant: state.plants.createdPlant,
  loading: state.plants.loading,
});

export default connect(mapStateToProps, { getPlants, verifySession })(
  PlantsList
);
