import { requestWrapper, supportedRequestMethods } from "../../API";
import { createMessage } from "./message";
import {
  DATA_LOADING,
  PLANTS_LOADED,
  PLANTS_LOAD_FAIL,
  PLANT_LOADED,
  PLANT_LOAD_FAIL,
  PLANT_CREATED,
  PLANT_CREATE_FAIL,
  PLANT_UPDATED,
  PLANT_UPDATE_FAIL,
  PLANT_ACTIVATED,
  PLANT_ACTIVATE_FAIL,
  PLANT_DEACTIVATED,
  PLANT_DEACTIVATE_FAIL,
  SENDING_DATA,
  GALLERY_DELETED,
  GALLERY_DELETE_FAIL,
  PLANTS_EXPORTED,
  PLANTS_EXPORT_FAIL,
  IDENTIFICATION_IMAGES_LOADED,
  IDENTIFICATION_IMAGES_LOAD_FAIL,
  IDENTIFICATION_IMAGE_DELETED,
  IDENTIFICATION_IMAGE_DELETE_FAIL,
  IDENTIFICATION_IMAGE_UPLOADED,
  IDENTIFICATION_IMAGE_UPLOAD_FAIL,
  IDENTIFICATION_IMAGE_DETAILS_LOADED,
  IDENTIFICATION_IMAGE_DETAILS_LOAD_FAIL,
} from "../types/plants";

/**
 * async function that sends a request to get plants
 */
export const getPlants = (offset, limit, active, query) => async (dispatch) => {
  dispatch({ type: DATA_LOADING });
  let endpointString = "";
  if (query === "") {
    endpointString = `/admin/plants?offset=${offset}&limit=${limit}&active=${active}`;
  } else {
    endpointString = `/admin/plants?offset=${offset}&limit=${limit}&active=${active}&query=${query}`;
  }
  let requestParams = {
    method: supportedRequestMethods.GET,
    endpoint: endpointString,
  };
  let response = await requestWrapper(requestParams);
  if (response && response.success) {
    dispatch({
      type: PLANTS_LOADED,
      payload: {
        data: response.data.data,
        offsetVal: offset,
        limitVal: limit,
        active: active,
      },
    });
  } else {
    if (response) dispatch(createMessage(response.data.message));
    dispatch({
      type: PLANTS_LOAD_FAIL,
    });
  }
};

/**
 * async function that sends a request to get an plant's details with the id
 */
export const getPlantDetails = (id) => async (dispatch) => {
  dispatch({ type: DATA_LOADING });
  let requestParams = {
    method: supportedRequestMethods.GET,
    endpoint: `/admin/plants/${id}`,
  };
  let response = await requestWrapper(requestParams);

  if (response && response.success) {
    dispatch({
      type: PLANT_LOADED,
      payload: response.data.data,
    });
  } else {
    if (response) dispatch(createMessage(response.data.message));
    dispatch({
      type: PLANT_LOAD_FAIL,
    });
  }
};

/**
 * async function that sends a request to create a new plant
 */
export const createPlant = (plant, props) => async (dispatch) => {
  dispatch({ type: DATA_LOADING });

  const formData = new FormData();

  Object.keys(plant).forEach((key) => {
    if (
      key !== "primaryImage" &&
      key !== "secondaryImages" &&
      typeof plant[key] !== "string"
    ) {
      formData.append(key, JSON.stringify(plant[key]));
    } else if (key === "primaryImage" || typeof plant[key] === "string") {
      formData.append(key, plant[key]);
    } else if (key === "secondaryImages") {
      plant[key].forEach((file) => {
        formData.append(key, file);
      });
    }
  });

  let requestParams = {
    method: supportedRequestMethods.POST,
    endpoint: `/admin/plants/`,
    body: formData,
  };

  dispatch({ type: SENDING_DATA, payload: true });
  let response = await requestWrapper(requestParams);
  dispatch({ type: SENDING_DATA, payload: false });

  if (response && response.success) {
    dispatch({
      type: PLANT_CREATED,
    });
    props.history.push("/plants");
  } else {
    if (response) dispatch(createMessage(response.data.message));
    dispatch({
      type: PLANT_CREATE_FAIL,
    });
  }
};

/**
 * async function that sends a request to deactivate a plant
 */
export const deactivatePlant = (activeObj, id, props) => async (dispatch) => {
  dispatch({ type: DATA_LOADING });

  const formData = new FormData();

  Object.keys(activeObj).forEach((key) => {
    if (key && Array.isArray(activeObj[key])) {
      formData.append(key, JSON.stringify(activeObj[key]));
    } else if (key) {
      formData.append(key, activeObj[key]);
    }
  });

  let requestParams = {
    method: supportedRequestMethods.PATCH,
    endpoint: `/admin/plants/${id}`,
    body: formData,
  };

  dispatch({ type: SENDING_DATA, payload: true });
  let response = await requestWrapper(requestParams);
  dispatch({ type: SENDING_DATA, payload: false });

  if (response && response.success) {
    dispatch({
      type: PLANT_DEACTIVATED,
    });
  } else {
    if (response) dispatch(createMessage(response.data.message));
    dispatch({
      type: PLANT_DEACTIVATE_FAIL,
    });
  }
};

/**
 * async function that sends a request to activate a plant
 */
export const activatePlant = (activeObj, id, props) => async (dispatch) => {
  dispatch({ type: DATA_LOADING });

  const formData = new FormData();

  Object.keys(activeObj).forEach((key) => {
    if (key && Array.isArray(activeObj[key])) {
      formData.append(key, JSON.stringify(activeObj[key]));
    } else if (key) {
      formData.append(key, activeObj[key]);
    }
  });

  let requestParams = {
    method: supportedRequestMethods.PATCH,
    endpoint: `/admin/plants/${id}`,
    body: formData,
  };

  dispatch({ type: SENDING_DATA, payload: true });
  let response = await requestWrapper(requestParams);
  dispatch({ type: SENDING_DATA, payload: false });

  if (response && response.success) {
    dispatch({
      type: PLANT_ACTIVATED,
    });
  } else {
    if (response) dispatch(createMessage(response.data.message));
    dispatch({
      type: PLANT_ACTIVATE_FAIL,
    });
  }
};

/**
 * async function that sends a request to update a plant
 */
export const updatePlant = (plant, id, props) => async (dispatch) => {
  dispatch({ type: DATA_LOADING });

  const formData = new FormData();

  Object.keys(plant).forEach((key) => {
    if (
      key !== "primaryImage" &&
      key !== "secondaryImages" &&
      typeof plant[key] !== "string"
    ) {
      formData.append(key, JSON.stringify(plant[key]));
    } else if (key === "primaryImage" || typeof plant[key] === "string") {
      formData.append(key, plant[key]);
    } else if (key === "secondaryImages") {
      plant[key].forEach((file) => {
        formData.append(key, file);
      });
    }
  });

  let requestParams = {
    method: supportedRequestMethods.PATCH,
    endpoint: `/admin/plants/${id}`,
    body: formData,
  };

  dispatch({ type: SENDING_DATA, payload: true });
  let response = await requestWrapper(requestParams);
  dispatch({ type: SENDING_DATA, payload: false });

  if (response && response.success) {
    dispatch({
      type: PLANT_UPDATED,
    });
  } else {
    if (response) dispatch(createMessage(response.data.message));
    dispatch({
      type: PLANT_UPDATE_FAIL,
    });
  }
};

/**
 * async function that sends a request to delete the plant's gallery
 */
export const deleteGallery = (id) => async (dispatch) => {
  dispatch({ type: DATA_LOADING });

  let requestParams = {
    method: supportedRequestMethods.DELETE,
    endpoint: `/admin/plants/${id}/gallery`,
  };

  dispatch({ type: SENDING_DATA, payload: true });
  let response = await requestWrapper(requestParams);
  dispatch({ type: SENDING_DATA, payload: false });

  if (response && response.success) {
    dispatch({
      type: GALLERY_DELETED,
    });
  } else {
    if (response) dispatch(createMessage(response.data.message));
    dispatch({
      type: GALLERY_DELETE_FAIL,
    });
  }
};

/**
 * async function that sends a request to get plants data
 */
export const exportPlants = () => async (dispatch) => {
  dispatch({ type: DATA_LOADING });

  let requestParams = {
    method: supportedRequestMethods.GET,
    endpoint: `/admin/plants/export-csv`,
  };

  dispatch({ type: SENDING_DATA, payload: true });
  let response = await requestWrapper(requestParams);
  dispatch({ type: SENDING_DATA, payload: false });

  if (response && response.success) {
    dispatch({
      type: PLANTS_EXPORTED,
      payload: response.data,
    });
  } else {
    if (response) dispatch(createMessage(response.data.message));
    dispatch({
      type: PLANTS_EXPORT_FAIL,
    });
  }
};

/**
 * plant identification images actions
 */

export const getPlantIdentificationImages = (id, offset, limit) => async (
  dispatch
) => {
  dispatch({ type: DATA_LOADING });

  let requestParams = {
    method: supportedRequestMethods.GET,
    endpoint: `/admin/plants/${id}/identification-images?offset=${offset}&limit=${limit}`,
  };

  dispatch({ type: SENDING_DATA, payload: true });
  let response = await requestWrapper(requestParams);
  dispatch({ type: SENDING_DATA, payload: false });

  if (response && response.success) {
    dispatch({
      type: IDENTIFICATION_IMAGES_LOADED,
      payload: response.data.data,
    });
  } else {
    if (response) dispatch(createMessage(response.data.message));
    dispatch({
      type: IDENTIFICATION_IMAGES_LOAD_FAIL,
    });
  }
};

export const getPlantIdentificationImageDetails = (plantId, imageId) => async (
  dispatch
) => {
  dispatch({ type: DATA_LOADING });

  let requestParams = {
    method: supportedRequestMethods.GET,
    endpoint: `/admin/plants/${plantId}/identification-images/${imageId}`,
  };

  dispatch({ type: SENDING_DATA, payload: true });
  let response = await requestWrapper(requestParams);
  dispatch({ type: SENDING_DATA, payload: false });

  if (response && response.success) {
    dispatch({
      type: IDENTIFICATION_IMAGE_DETAILS_LOADED,
      payload: response.data.data,
    });
  } else {
    if (response) dispatch(createMessage(response.data.message));
    dispatch({
      type: IDENTIFICATION_IMAGE_DETAILS_LOAD_FAIL,
    });
  }
};

export const deletePlantIdentificationImage = (
  plantId,
  imageId,
  props
) => async (dispatch) => {
  dispatch({ type: DATA_LOADING });

  let requestParams = {
    method: supportedRequestMethods.DELETE,
    endpoint: `/admin/plants/${plantId}/identification-images/${imageId}`,
  };

  dispatch({ type: SENDING_DATA, payload: true });
  let response = await requestWrapper(requestParams);
  dispatch({ type: SENDING_DATA, payload: false });

  if (response && response.success) {
    dispatch({
      type: IDENTIFICATION_IMAGE_DELETED,
    });
    props.history.push(`/plants/${plantId}`);
  } else {
    if (response) dispatch(createMessage(response.data.message));
    dispatch({
      type: IDENTIFICATION_IMAGE_DELETE_FAIL,
    });
  }
};

export const uploadPlantIdentificationImage = (
  identificationImage,
  id,
  props
) => async (dispatch) => {
  dispatch({ type: DATA_LOADING });

  const formData = new FormData();

  Object.keys(identificationImage).forEach((key) => {
    if (key === "characteristics") {
      formData.append(key, JSON.stringify(identificationImage[key]));
    } else {
      formData.append(key, identificationImage[key]);
    }
  });

  let requestParams = {
    method: supportedRequestMethods.POST,
    endpoint: `/admin/plants/${id}/identification-images`,
    body: formData,
  };

  dispatch({ type: SENDING_DATA, payload: true });
  let response = await requestWrapper(requestParams);
  dispatch({ type: SENDING_DATA, payload: false });

  if (response && response.success) {
    dispatch({
      type: IDENTIFICATION_IMAGE_UPLOADED,
    });
    props.history.push(`/plants/${id}`);
  } else {
    if (response) dispatch(createMessage(response.data.message));
    dispatch({
      type: IDENTIFICATION_IMAGE_UPLOAD_FAIL,
    });
  }
};
