import { CREATE_MESSAGE, REMOVE_MESSAGE } from "../types/message";
const initialState = {
  message: {
    id: "",
    text: "",
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CREATE_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };
    case REMOVE_MESSAGE:
      return {
        ...state,
        message: { id: null, text: "" },
      };
    default:
      return state;
  }
}
