import {
  DATA_LOADING,
  PLANT_SPOTS_LOADED,
  PLANT_SPOTS_LOAD_FAIL,
  PLANT_SPOT_APPROVED,
  PLANT_SPOT_APPROVE_FAIL,
  PLANT_SPOT_REJECTED,
  PLANT_SPOT_REJECT_FAIL,
  PLANT_SPOT_CREATED,
  PLANT_SPOT_CREATE_FAIL,
  SENDING_DATA,
  PLANT_SPOTS_EXPORTED,
  PLANT_SPOTS_EXPORT_FAIL,
} from "../types/plants-spots";

const initialState = {
  stats: null,
  plantSpotsList: [],
  loading: false,
  statsLoading: false,
  csvPlantSpotsData: null,
  offset: 0,
  limit: 50,
  pending: true,
  totalPlantSpots: 0,
  createdPlantSpot: false,
  sendingRequest: false,
  failed: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case DATA_LOADING:
      return {
        ...state,
        loading: true,
        count: 0,
        createdPlantSpot: false,
        failed: false,
      };
    case SENDING_DATA:
      return {
        ...state,
        sendingRequest: action.payload,
      };
    case PLANT_SPOTS_EXPORTED:
      return {
        ...state,
        csvPlantSpotsData: action.payload,
      };
    case PLANT_SPOTS_LOADED:
      return {
        ...state,
        loading: false,
        failed: false,
        plantSpotsList: action.payload.data.plantSpots,
        totalPlantSpots: action.payload.data.entityCount,
        offset: action.payload.offsetVal,
        pending: action.payload.pending,
        // limit: action.payload.limitVal,
      };
    case PLANT_SPOT_REJECTED:
    case PLANT_SPOT_CREATED:
      return {
        ...state,
        loading: false,
        createdPlantSpot: true,
      };
    case PLANT_SPOT_APPROVED:
      return {
        ...state,
        loading: false,
        plantSpotsList: [
          ...state.plantSpotsList.filter((spot) => spot._id !== action.payload),
        ],
        createdPlantSpot: true,
      };
    case PLANT_SPOT_APPROVE_FAIL:
    case PLANT_SPOT_REJECT_FAIL:
    case PLANT_SPOT_CREATE_FAIL:
      return {
        ...state,
        loading: false,
        statsLoading: false,
        failed: true,
      };
    case PLANT_SPOTS_LOAD_FAIL:
    case PLANT_SPOTS_EXPORT_FAIL:
      return {
        ...state,
        loading: false,
        statsLoading: false,
      };
    default:
      return state;
  }
}
