import React from "react";
import Spinner from "../../components/Spinner";
import { Modal, ModalBody } from "reactstrap";

const PRIMARY_COLOR = "#3ec7af";

export default function LoadingModal(props) {
  return (
    <div>
      <Modal isOpen={props.loading} className="text-center">
        <ModalBody>
          <Spinner
            style={{
              borderColor: PRIMARY_COLOR,
              width: "50px",
              height: "50px",
            }}
          />
          <h3 style={{ color: PRIMARY_COLOR }}>אנא המתן...</h3>
        </ModalBody>
      </Modal>
    </div>
  );
}
