import React from "react";
import { Toast, ToastHeader } from "reactstrap";

export default function ToastError(props) {
  return (
    <Toast>
      <ToastHeader style={{ color: "red", fontSize: "12px" }}>
        {props.error}
      </ToastHeader>
    </Toast>
  );
}

export const TITLE_ERROR = "שדה חובה אשר חייב להכיל מינימום 5 תווים";
export const ERROR_10 = "שדה חובה אשר חייב להכיל מינימום 10 תווים";
export const REQUIRED_FIELD = "שדה חובה";
export const ERROR_INFORMATION = "חובה למלא את כל השדות";
// export const ERROR_VIDEO_URL = "לינק הוידאו אינו תואם את סוג הוידאו";
export const ERROR_BENEFITS = "שדה חובה - יש למלא איבר בגוף וגם תיאור היתרון";
