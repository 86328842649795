export const DATA_LOADING = "DATA_LOADING";

export const SENDING_DATA = "SENDING_DATA";

export const PLANT_SPOTS_LOADED = "PLANT_SPOTS_LOADED";
export const PLANT_SPOTS_LOAD_FAIL = "PLANT_SPOTS_LOAD_FAIL";

export const PLANT_SPOT_APPROVED = "PLANT_SPOT_APPROVED";
export const PLANT_SPOT_APPROVE_FAIL = "PLANT_SPOT_APPROVE_FAIL";

export const PLANT_SPOT_REJECTED = "PLANT_SPOT_REJECTED";
export const PLANT_SPOT_REJECT_FAIL = "PLANT_SPOT_REJECT_FAIL";

export const PLANT_SPOT_CREATED = "PLANT_SPOT_CREATED";
export const PLANT_SPOT_CREATE_FAIL = "PLANT_SPOT_CREATE_FAIL";

export const PLANT_SPOTS_EXPORTED = "PLANT_SPOTS_EXPORTED";
export const PLANT_SPOTS_EXPORT_FAIL = "PLANT_SPOTS_EXPORT_FAIL";
