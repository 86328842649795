import {
  DATA_LOADING,
  CLIENTS_LOADED,
  CLIENTS_LOAD_FAIL,
  CLIENT_LOADED,
  CLIENT_LOAD_FAIL,
  DELETED_CLIENT,
  DELETE_CLIENT_FAIL,
  MANUAL_SUBSCRIPTION_CREATED,
  MANUAL_SUBSCRIPTION_FAIL,
  DELETED_SUBSCRIPTION,
  DELETE_SUBSCRIPTION_FAIL,
  SENDING_DATA,
  CLIENTS_EXPORTED,
  CLIENTS_EXPORT_FAIL,
} from "../types/clients";

const initialState = {
  stats: null,
  clients: [],
  loading: false,
  singleClient: null,
  statsLoading: false,
  csvClients: null,
  offset: 0,
  totalClients: 0,
  sendingRequest: false,
  updateClient: false,
  deletedClient: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case DATA_LOADING:
      return {
        ...state,
        loading: true,
        updateClient: false,
        deletedClient: false,
      };
    case SENDING_DATA:
      return {
        ...state,
        sendingRequest: action.payload,
      };
    case CLIENTS_EXPORTED:
      return {
        ...state,
        csvClients: action.payload,
      };

    case DELETED_CLIENT:
      return {
        ...state,
        loading: false,
        clients: [
          ...state.clients.filter((user) => user.id !== action.payload),
        ],
        singleClient: null,
        deletedClient: true,
      };
    case CLIENT_LOADED:
      return {
        ...state,
        loading: false,
        singleClient: action.payload,
      };
    case CLIENTS_LOADED:
      return {
        ...state,
        loading: false,
        clients: action.payload.data.clients,
        totalClients: action.payload.data.entityCount,
        offset: action.payload.offsetVal,
      };
    case MANUAL_SUBSCRIPTION_CREATED:
    case DELETED_SUBSCRIPTION:
      return {
        ...state,
        loading: false,
        updateClient: true,
      };
    case CLIENTS_LOAD_FAIL:
    case CLIENT_LOAD_FAIL:
    case DELETE_CLIENT_FAIL:
    case DELETE_SUBSCRIPTION_FAIL:
    case MANUAL_SUBSCRIPTION_FAIL:
    case CLIENTS_EXPORT_FAIL:
      return {
        ...state,
        loading: false,
        statsLoading: false,
        updateClient: false,
      };
    default:
      return state;
  }
}
