import React, {useEffect, useState} from "react";
// import ReactTable from "react-table";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {getClients, getClient} from "../../../redux/actions/clients";
import {verifySession} from "../../../redux/actions/auth";
import {useTable, usePagination} from 'react-table';
import moment from "moment";

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    Modal,
    ModalBody, Input,
} from "reactstrap";
import Spinner from "components/Spinner";

/*
 * ClientsList Component for viewing all existing clients
 * option to view details for each client
 */
const ClientsList = (props) => {
    const {} = props;
    const [searchQuery, setSearchQuery] = useState('');
    const [state, setState] = useState({data: []});
    const [offset, setOffset] = useState(props.offset);
    const [limit] = useState(20);
    const [modalSuccess, setModalSuccess] = useState(false);
    const [timer, setTimer] = useState(null);

    /**
     * function that handles search recipes
     * using timeout for optimization, sending request with search value only after user stops typing
     */
    const handleSearchInput = (e) => {
        let inputVal = e.target.value;
        setSearchQuery(inputVal);
        if (timer) {
            clearTimeout(timer);
        }
        setOffset(0)
        setTimer(
            setTimeout(() => {
                setTimer(null);
                props.getClients(0, limit, inputVal);
            }, 500)
        );
    };

    /**
     * function that handles selecting a page
     * gets clients from backend with new offset
     */
    const handlePageChange = (pageNumber) => {
        setOffset(pageNumber);
        //get clients with new offset
        props.getClients(pageNumber, limit, searchQuery);
    };

    /**
     * useEffect that gets clients
     * offset starts at 0 and number of clients per page is limited is 50
     */
    useEffect(() => {
        props.verifySession();
        props.getClients(offset, limit);
    }, []);

    /**
     * useEffect that sets clients list state
     * mappping clients list to data - needed for react-table
     */
    useEffect(() => {
        setModalSuccess(false);
        setState({
            data: props.clientsList.map((client, key) => {
                return {
                    id: key,
                    name: client.name,
                    details: (
                        <Link
                            style={{color: "rgb(62,199,175)", fontWeight: "bold"}}
                            to={{
                                pathname: `/clients/${client._id}`,
                                user: client,
                            }}
                        >
                            פרטים נוספים
                        </Link>
                    ),
                    phone: client.phone.replace(/\+972/, "0"),
                    _id: client._id,
                    email: client.clientEmail,
                    created_at: moment(client.created_at).format('DD/MM/YYYY'),
                };
            }),
        });
    }, [props.clientsList]);

    /**
     * useEffect that checks if admin was deleted
     */
    useEffect(() => {
        if (props.deletedClient) {
            setModalSuccess(!modalSuccess);
        }
    }, [props.deletedClient]);

    const columns = React.useMemo(
        () => [
            {
                Header: "שם מלא",
                accessor: "name",
                headerClassName: "text-right",
                className: "text-right",
            },
            {
                Header: "פרטים נוספים",
                accessor: "details",
                headerClassName: "text-right",
                className: "text-right",
                sortable: false,
                filterable: false,
            },
            {
                Header: "טלפון",
                accessor: "phone",
                headerClassName: "text-right",
                className: "text-right",
                sortable: false,
                filterable: false,
            },
            {
                Header: "אימייל",
                accessor: "email",
                headerClassName: "text-right",
                className: "text-right",
                sortable: false,
                filterable: false,
            },
            {
                Header: "תאריך הרשמה",
                accessor: "created_at",
                headerClassName: "text-right",
                className: "text-right",
                sortable: false,
                filterable: false,
            },
        ],
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
    } = useTable(
        {
            columns,
            data: state.data,
            initialState: {pageIndex: 0, pageSize: limit},
        }, usePagination
    )

    return (
        <>
            {props.loading ? (
                <Spinner/>
            ) : (
                <div className="content rtl">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4" className="text-right">
                                        רשימת לקוחות
                                    </CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <div
                                        className="pagination"
                                        style={{justifyContent: 'space-between', alignItems: 'center'}}
                                    >
                                        <button
                                            onClick={() => {
                                                handlePageChange(offset - 1)
                                            }}
                                            disabled={offset === 0}
                                            className="btn-fill mx-1 btn btn-primary"
                                            style={{width: '50%'}}
                                        >
                                            {'הקודם'}
                                        </button>
                                        <span
                                            style={{width: '100%', textAlign: 'center'}}
                                        >
                                        {`עמוד ${offset + 1} מתוך ${Math.ceil(props.totalClients / limit)}`}
                                    </span>
                                        <button
                                            onClick={() => {
                                                handlePageChange(offset + 1)
                                            }}
                                            disabled={offset >= Math.floor(props.totalClients / limit)}
                                            className="btn-fill mx-1 btn btn-primary"
                                            style={{width: '50%'}}
                                        >
                                            {'הבא'}
                                        </button>
                                    </div>
                                    <div
                                        style={{
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        <Input
                                            style={{
                                                height: "40px",
                                                width: '25%'
                                            }}
                                            placeholder="סינון לפי שם משתמש"
                                            value={searchQuery}
                                            onChange={handleSearchInput}
                                        />
                                    </div>
                                    <table {...getTableProps()} style={{width: '100%'}}>
                                        <thead>
                                        {headerGroups.map(headerGroup => (
                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                {headerGroup.headers.map(column => (
                                                    <th
                                                        {...column.getHeaderProps()}
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        {column.render('Header')}
                                                    </th>
                                                ))}
                                            </tr>
                                        ))}
                                        </thead>
                                        <tbody {...getTableBodyProps()}>
                                        {page.map((row, i) => {
                                            prepareRow(row)
                                            return (
                                                <tr {...row.getRowProps()}>
                                                    {row.cells.map(cell => {
                                                        return (
                                                            <td
                                                                {...cell.getCellProps()}
                                                                style={{
                                                                    padding: '10px',
                                                                    border: 'solid 1px gray',
                                                                    textAlign: 'right',
                                                                }}
                                                            >
                                                                {cell.render('Cell')}
                                                            </td>
                                                        )
                                                    })}
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </table>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            )}
        </>
    );
};

const mapStateToProps = (state) => ({
    clientsList: state.clients.clients,
    offset: state.clients.offset,
    totalClients: state.clients.totalClients,
    deletedClient: state.clients.deletedClient,
    loading: state.clients.loading,
});

export default connect(mapStateToProps, {
    getClients,
    getClient,
    verifySession,
})(ClientsList);
