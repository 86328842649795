export const DATA_LOADING = "DATA_LOADING";

export const SENDING_DATA = "SENDING_DATA";

export const ADMINS_LOADED = "ADMINS_LOADED";
export const ADMINS_LOAD_FAIL = "ADMINS_LOAD_FAIL";

export const ADMIN_CREATED = "ADMIN_CREATED";
export const ADMIN_CREATE_FAIL = "ADMIN_CREATE_FAIL";

export const ADMIN_DELETED = "ADMIN_DELETED";
export const ADMIN_DELETE_FAIL = "ADMIN_DELETE_FAIL";

export const ADMIN_CROWNED = "ADMIN_CROWNED";
export const ADMIN_CROWN_FAIL = "ADMIN_CROWN_FAIL";

export const ADMIN_PROFILE_SUCCESS = "ADMIN_PROFILE_SUCCESS";
export const ADMIN_PROFILE_FAIL = "ADMIN_PROFILE_FAIL";
