import { requestWrapper, supportedRequestMethods } from "../../API";
import { createMessage } from "./message";

import {
  DATA_LOADING,
  ARTICLE_CATEGORIES_LOADED,
  RECIPE_CATEGORIES_LOADED,
  PLANT_CATEGORIES_LOADED,
  CATEGORIES_LOAD_FAIL,
  PLANTS_LOADED,
  PLANTS_LOAD_FAIL,
  PLANT_CHARASTERISTICS_LOADED,
  PLANT_CHARASTERISTICS_LOAD_FAIL,
} from "../types/shared";

export const getCategories = (entity) => async (dispatch) => {
  dispatch({ type: DATA_LOADING });
  let requestParams = {
    method: supportedRequestMethods.GET,
    endpoint: `/categories?entity=${entity}`,
  };
  let response = await requestWrapper(requestParams);

  if (response && response.success) {
    if (entity === "article") {
      dispatch({
        type: ARTICLE_CATEGORIES_LOADED,
        payload: response.data.data,
      });
    } else if (entity === "recipe") {
      dispatch({
        type: RECIPE_CATEGORIES_LOADED,
        payload: response.data.data,
      });
    } else {
      // plant
      dispatch({
        type: PLANT_CATEGORIES_LOADED,
        payload: response.data.data,
      });
    }
  } else {
    if (response) dispatch(createMessage(response.data.message));
    dispatch({
      type: CATEGORIES_LOAD_FAIL,
    });
  }
};

export const getPlants = (offset, limit, query) => async (dispatch) => {
  dispatch({ type: DATA_LOADING });

  let requestParams = {
    method: supportedRequestMethods.GET,
    endpoint: `/admin/plants?offset=${offset}&limit=${limit}&active=true&query=${query}`,
  };

  let response = await requestWrapper(requestParams);
  if (response && response.success) {
    dispatch({
      type: PLANTS_LOADED,
      payload: {
        data: response.data.data,
        offsetVal: offset,
        limitVal: limit,
      },
    });
    return response.data.data;
  } else {
    if (response) dispatch(createMessage(response.data.message));
    dispatch({
      type: PLANTS_LOAD_FAIL,
    });
  }
};

export const getPlantIdentificationImageCharacteristics = () => async (
  dispatch
) => {
  dispatch({ type: DATA_LOADING });

  let requestParams = {
    method: supportedRequestMethods.GET,
    endpoint: `/plant-identification-image-characteristics`,
  };
  let response = await requestWrapper(requestParams);
  if (response && response.success) {
    dispatch({
      type: PLANT_CHARASTERISTICS_LOADED,
      payload: response.data.data,
    });
  } else {
    if (response) dispatch(createMessage(response.data.message));
    dispatch({
      type: PLANT_CHARASTERISTICS_LOAD_FAIL,
    });
  }
};
