import {
  USER_LOADING,
  SEND_OTP_SUCCESS,
  SEND_OTP_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  RESET_EMAIL,
  LOGOUT,
  AUTH_VERIFIED_SUCCESS,
  AUTH_VERIFIED_FAIL,
} from "../types/auth";

import Cookie from "js-cookie";

const initialState = {
  authenticated: false,
  verifyingSession: true,
  isLoading: false,
  otpSent: false,
  crowned: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case AUTH_VERIFIED_SUCCESS:
    case LOGIN_SUCCESS:
      return {
        ...state,
        authenticated: true,
        verifyingSession: false,
        isLoading: false,
      };
    case AUTH_VERIFIED_FAIL:
      Cookie.remove("sid-lukit");
      return {
        ...state,
        isLoading: false,
        authenticated: false,
        verifyingSession: false,
      };
    case USER_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case SEND_OTP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        otpSent: true,
      };
    // case LOGIN_SUCCESS:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     authenticated: true,
    //     verifyingSession: false,
    //   };
    case LOGOUT:
      Cookie.remove("sid-lukit");
      return {
        ...state,
        authenticated: false,
        // crowned: false,
        otpSent: false,
      };
    case SEND_OTP_FAIL:
    case LOGIN_FAIL:
      return {
        ...state,
        isLoading: false,
        verifyingSession: false,
      };
    case RESET_EMAIL:
      return {
        ...state,
        otpSent: false,
      };
    default:
      return state;
  }
}
