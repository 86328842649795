import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ReactBSAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import StyledDropzone from "../../components/Dropzone";
import {
  getCategories,
  getPlantIdentificationImageCharacteristics,
} from "redux/actions/shared";
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
// import "react-tabs/style/react-tabs.css";
import TextareaAutosize from "react-textarea-autosize";
import noImage from "../../../assets/img/no-image.jpg";

import {
  getPlantDetails,
  deactivatePlant,
  activatePlant,
  updatePlant,
  deleteGallery,
  getPlantIdentificationImages,
} from "../../../redux/actions/plants";
import { URL_API } from "../../../path";
import IdentificationImages from "./IdentificationImages";
import LoadingModal from "views/components/LoadingModal";

import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  CardFooter,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Badge,
  CardImg,
} from "reactstrap";

import ToastError, {
  ERROR_10,
  REQUIRED_FIELD,
  ERROR_INFORMATION,
  ERROR_BENEFITS,
  TITLE_ERROR,
} from "../../components/Errors";
import {Vimeo, YouTube} from "../../constants";
// import plants from "redux/reducers/plants";
// import plantsSpots from "redux/reducers/plants-spots";
import YoutubePlayer from "react-player/youtube";
import VimeoPlayer from "react-player/vimeo";

/**
 * Plant Details Component
 * Shows all details and allows user to update fields, deactivate and activate the plants
 */
const PlantDetails = (props) => {
  //const {} = props;
  const animatedComponents = makeAnimated();

  const [plant, setPlant] = useState({
    name: "",
    scientificName: "",
    family: "",
    description: "",
    endangered: false,
    toxic: false,
    information: {
      whereToFind: "",
      identificationDetails: "",
      warning: "",
      preparation: "",
      composition: "",
      benefits: [{ type: "", details: "" }],
      trivia: "",
    },
    video: {
      url: "",
      source: "",
    },
    secondaryImages: [{ path: "" }],
    primaryImage: { path: "" },
    categories: [],
    created_at: "",
  });

  const videoOptions = [
    { value: "youtube", label: "Youtube" },
    { value: "vimeo", label: "Vimeo" },
  ];

  const [errorName, setErrorName] = useState(null);
  const [errorDescription, setErrorDescription] = useState(null);
  const [errorScientific, setErrorScientific] = useState(null);
  const [errorFamily, setErrorFamily] = useState(null);
  const [errorInformation, setErrorInformation] = useState(null);
  const [error, setError] = useState(null);
  const [errorBenefits, setErrorBenefits] = useState(null);

  const [alert, setAlert] = useState(null);
  const [showPrimary, setShowPrimary] = useState(false);
  const [showSecondary, setShowSecondary] = useState(false);
  const [showCategory, setShowCategory] = useState(false);
  const [imageList] = useState(["", "", "", ""]);

  const [secondary, setSecondary] = useState([]);
  const [primary, setPrimary] = useState(null);
  const [deleteAllSecondary, setDeleteAllSecondary] = useState(false);

  const [modalGallery, setModalGallery] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);

  const [categories, setCategories] = useState();
  const [subCategories, setSubCategories] = useState([]);
  const [selectedParentCategory, setSelectedParentCategory] = useState(false);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [subCategoriesOptions, setSubCategoriesOptions] = useState([]);
  const [modalMessage, setModalMessage] = useState("הפעולה בוצעה בהצלחה");

  const [showPlantDetails, setShowPlantDetails] = useState(true);
  const [videoUrlId, setVideoUrlId] = useState("");
  const [videoSource, setVideoSource] = useState("");
  const [showVideo, setShowVideo] = useState(false);
  const [modalVideo, setModalVideo] = useState(false);
  const [errorUrl, setErrorUrl] = useState(null);

  const toggleVideo = () => {
    setModalVideo(!modalVideo);
  };

  /**
   * function that deletes video
   */
  const handleDeleteVideo = () => {
    setModalVideo(!modalVideo);
    props.deleteVideo(plant._id);
    if (props.deleteVideo) {
      setModalSuccess(!modalSuccess);

      delete plant.video;
    }
  };

  /**
   * function that handles selecting video source
   */
  const handleVideoSelect = (selectedOption) => {
    setErrorUrl(null);

    if (selectedOption !== null) {
      setVideoSource(selectedOption.value);
    } else {
      setVideoSource("");
      setVideoUrlId("");
    }
  };

  /**
   * function that handles video id input
   */
  const handleVideoUrl = (e) => {
    let urlID = e.target.value;
    setVideoUrlId(urlID);

    if (urlID !== "") {
      setErrorUrl(null);
    } else {
      setErrorUrl(<ToastError error={REQUIRED_FIELD} />);
    }
  };

  const toggleGallery = () => {
    setModalGallery(!modalGallery);
  };

  /**
   * function that deletes all secondary images
   */
  const handleDeleteGallery = () => {
    setModalGallery(!modalGallery);
    props.deleteGallery(plant._id);
    if (props.deleteGallery) {
      setModalSuccess(!modalSuccess);
      setPlant({ ...plant, secondaryImages: [] });
    }
  };

  /**
   * function that handles selecting sub categories
   */
  const handleSelectCategories = (selected) => {
    if (selected.length > 0) {
      setSelectedSubCategories(selected);
    } else {
      setSelectedSubCategories([]);
    }
  };

  /**
   * function that handles selecting parent categories
   */
  const handleSelectParentCategories = (selected) => {
    if (selected !== null) {
      setPlant({
        ...plant,
        categories: selected,
      });

      let subByParent = [];
      selected.forEach((parent) => {
        let currentSubs = subCategories.filter(
          (sub) => sub.parentCategory === parent._id
        );
        subByParent = [...subByParent, ...currentSubs];
      });

      setSubCategoriesOptions(subByParent);
      setSelectedParentCategory(true);
    } else {
      setPlant({ ...plant, categories: [] });
      setSelectedParentCategory(false);
    }
  };

  const handleOnChange = (e) => {
    setPlant({ ...plant, [e.target.name]: e.target.value });
  };

  /**
   * function that handles selecting if plant is toxic and/or endangered
   */
  const handleOnCheck = (e, value) => {
    setPlant({ ...plant, [e.target.name]: !value });
  };

  /**
   * function that handles plant's information
   */
  const handleOnChangeInformation = (e) => {
    setPlant({
      ...plant,
      information: {
        ...plant.information,
        [e.target.name]: e.target.value,
      },
    });
  };

  /**
   * function that handles primary image
   */
  const handleChangePrimary = (image) => {
    setPrimary(image[0]);
  };

  /**
   * function that handles secondary image
   */
  const handleChangeSecondary = (image) => {
    setSecondary([...secondary, image[0]]);
  };

  /**
   * function that handles delete primary image
   */
  const handleDeletePrimary = () => {
    setPlant(null);
  };

  /**
   * function that handles delete secondary image
   */
  const handleDeleteSecondary = (secondary) => {
    let updatedList = secondary.filter(
      (val) => val.preview === secondary[0].preview
    );
    setSecondary(updatedList);
  };

  /**
   * functions that handles plant's benefits
   */
  const handleInputBenefit = (e, index) => {
    const list = [...plant.information.benefits];
    const benefitObj = { ...list[index], [e.target.name]: e.target.value };
    list[index] = benefitObj;
    setPlant({
      ...plant,
      information: {
        ...plant.information,
        benefits: list,
      },
    });
  };

  const handleAddBenefit = () => {
    let size = plant.information.benefits.length;
    let currentBenefit = plant.information.benefits[size - 1];
    if (currentBenefit.type !== "" && currentBenefit.details !== "") {
      setPlant({
        ...plant,
        information: {
          ...plant.information,
          benefits: [...plant.information.benefits, { type: "", details: "" }],
        },
      });
    }
  };

  const handleRemoveBenefit = (index) => {
    const list = [...plant.information.benefits];
    list.splice(index, 1);
    setPlant({
      ...plant,
      information: {
        ...plant.information,
        benefits: list,
      },
    });
  };

  /**
   * show alert functions for update / activate / deactivate
   */
  const showAlert = (message, action, activeObj) => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="האם את/ה בטוח/ה?"
        onConfirm={() => {
          action(activeObj, plant._id, props);
          setAlert(null);
        }}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText="אישור"
        cancelBtnText="ביטול"
        showCancel
        btnSize=""
      >
        {message}
      </ReactBSAlert>
    );
  };

  const showUpdateAlert = (toUpdate) => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="האם את/ה בטוח/ה?"
        onConfirm={() => {
          if (deleteAllSecondary && secondary.length === 0) {
          }
          props.updatePlant(toUpdate, plant._id, props);
          setAlert(null);
        }}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText="אישור"
        cancelBtnText="ביטול"
        showCancel
        btnSize=""
      >
        הפעולה הבאה תעדכן את הצמח
      </ReactBSAlert>
    );
  };

  const handleActivate = () => {
    showAlert("הפעולה הבאה תהפוך את הצמח לפעיל", props.activatePlant, {
      active: true,
    });
  };

  const handleDeactivate = () => {
    showAlert("הפעולה הבאה תהפוך את הצמח ללא פעיל", props.deactivatePlant, {
      active: false,
    });
  };

  /**
   * function to reset all error messages
   */
  const resetErrorMessages = () => {
    setErrorName(null);
    setErrorScientific(null);
    setErrorFamily(null);
    setErrorDescription(null);
    setErrorInformation(null);
    setError(null);
    setErrorBenefits(null);
  };

  /**
   * show alert function for updating a plant
   */
  const handleUpdate = () => {
    let toUpdate = {};
    let toUpdateFlag = true;

    resetErrorMessages();

    if (plant.name === "") {
      toUpdateFlag = false;
      setErrorName(<ToastError error={REQUIRED_FIELD} />);
    }

    if (plant.scientificName === "") {
      toUpdateFlag = false;
      setErrorScientific(<ToastError error={REQUIRED_FIELD} />);
    }

    if (plant.family === "") {
      toUpdateFlag = false;
      setErrorFamily(<ToastError error={REQUIRED_FIELD} />);
    }

    if (plant.description.length < 10) {
      toUpdateFlag = false;
      setErrorDescription(<ToastError error={ERROR_10} />);
    }

    if (videoSource !== "" && videoUrlId !== "" && errorUrl === null) {
      toUpdate = {
        ...toUpdate,
        video: { source: videoSource, url: videoUrlId },
      };
    }

    Object.keys(plant.information).forEach((key) => {
      if (key !== "benefits") {
        if (plant.information[key].length < 10) {
          toUpdateFlag = false;
          setErrorInformation(<ToastError error={ERROR_INFORMATION} />);
          setError(<ToastError error={ERROR_10} />);
        }
      }
    });

    plant.information.benefits.forEach((benefit) => {
      if (benefit.type === "" || benefit.details === "") {
        toUpdateFlag = false;
        setErrorBenefits(<ToastError error={ERROR_BENEFITS} />);
      }
    });

    if (toUpdateFlag) {
      if (plant.name !== props.singlePlant.name) {
        toUpdate = { ...toUpdate, name: plant.name };
      }

      if (plant.scientificName !== props.singlePlant.scientificName) {
        toUpdate = { ...toUpdate, scientificName: plant.scientificName };
      }

      if (plant.family !== props.singlePlant.family) {
        toUpdate = { ...toUpdate, family: plant.family };
      }

      if (plant.description !== props.singlePlant.description) {
        toUpdate = { ...toUpdate, description: plant.description };
      }

      Object.keys(plant.information).forEach((key) => {
        if (
          JSON.stringify(plant.information[key]) !==
          JSON.stringify(props.singlePlant.information[key])
        ) {
          toUpdate = {
            ...toUpdate,
            information: { ...plant.information, key: plant.information[key] },
          };
        }
      });

      if (primary !== null) {
        toUpdate = { ...toUpdate, primaryImage: primary };
      }

      if (plant.toxic !== props.singlePlant.toxic) {
        toUpdate = { ...toUpdate, toxic: plant.toxic };
      }

      if (plant.endangered !== props.singlePlant.endangered) {
        toUpdate = { ...toUpdate, endangered: plant.endangered };
      }

      if (selectedParentCategory) {
        let parentCategoriesIds = plant.categories.map((category) => {
          return category._id;
        });

        let subCategoriesIds = selectedSubCategories.map((category) => {
          return category._id;
        });

        toUpdate = {
          ...toUpdate,
          categories: [...parentCategoriesIds, ...subCategoriesIds],
        };
      }

      if (secondary.length > 0) {
        toUpdate = { ...toUpdate, secondaryImages: secondary };
      }

      if (Object.keys(toUpdate).length !== 0) {
        resetErrorMessages();
        showUpdateAlert(toUpdate);
      } else {
        showErrorAlert();
      }
    } else {
      showUpdateErrorAlert();
    }
  };

  const showErrorAlert = () => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="לא הוזנו נתונים חדשים לעדכון"
        onConfirm={() => {
          setAlert(null);
        }}
        confirmBtnBsStyle="error"
        confirmBtnText="אישור"
        btnSize=""
      >
        נסה/י שנית
      </ReactBSAlert>
    );
  };

  const showUpdateErrorAlert = () => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="נא למלא את כל שדות החובה"
        onConfirm={() => {
          setAlert(null);
        }}
        confirmBtnBsStyle="error"
        confirmBtnText="אישור"
        btnSize=""
      >
        {/* שדות חובה מסומנים בכוכבית */}
      </ReactBSAlert>
    );
  };

  useEffect(() => {
    props.getPlantDetails(props.match.params.id);
    if (props.plantCategories === null) {
      props.getCategories("plant");
    }
    if (props.location.data) {
      setShowPlantDetails(false);
    }
  }, []);

  useEffect(() => {
    setVideoSource("");
    setVideoUrlId("");
    if (props.updatedPlant) {
      setModalSuccess(!modalSuccess);
      setShowCategory(false);
      setSelectedParentCategory(false);
      setShowSecondary(false);
      setShowPrimary(false);
      setShowVideo(false);

      props.getPlantDetails(props.match.params.id);
    }
  }, [props.updatedPlant]);

  /*
   * useEffect for setting current plant
   * Math.random was added to each image path in order to make sure that the image changes after an update
   */
  useEffect(() => {
    if (props.singlePlant !== null) {
      setPlant({
        ...props.singlePlant,
        primaryImage: {
          ...props.singlePlant.primaryImage,
          path: props.singlePlant.primaryImage.path + "?" + Math.random(),
        },
        secondaryImages: [
          ...props.singlePlant.secondaryImages.map((image) => ({
            path: image.path + "?" + Math.random(),
          })),
        ],
      });
    }
  }, [props.singlePlant]);

  useEffect(() => {
    if (props.plantCategories !== null) {
      let parent = [],
        sub = [];
      props.plantCategories.forEach((category) => {
        if (category.hasOwnProperty("parentCategory")) {
          sub.push(category);
        } else {
          parent.push(category);
        }
      });

      setCategories(
        parent.map((category) => {
          return {
            _id: category._id,
            value: category.name,
            label: category.name,
          };
        })
      );

      setSubCategories(
        sub.map((category) => {
          return {
            _id: category._id,
            parentCategory: category.parentCategory,
            value: category.name,
            label: category.name,
          };
        })
      );
    }
  }, [props.plantCategories]);

  useEffect(() => {
    if (props.imageUpdated) {
      setShowPlantDetails(false);
      setModalSuccess(true);
    }
  }, [props.imageUpdated]);

  useEffect(() => {
    if (props.failed) {
      setModalSuccess(props.failed);
      setModalMessage("הפעולה נכשלה");
    }
  }, [props.failed]);

  return (
    <>
      <div className="content">
        {alert}

        <LoadingModal loading={props.sendingRequest} />

        <Modal isOpen={modalSuccess} className="text-center">
          <ModalBody>
            <h4>{modalMessage}</h4>
            <Row>
              <Col md="12" className="d-flex justify-content-center">
                <Button
                  color="primary"
                  onClick={() => {
                    setModalSuccess(!modalSuccess);
                    setModalMessage("הפעולה בוצעה בהצלחה");
                  }}
                >
                  אישור
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>

        <Modal isOpen={modalGallery} className="text-right">
          <ModalBody style={{ fontSize: "16px" }}>
            פעולה זו תמחוק את הגלריה
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleGallery}>
              ביטול
            </Button>
            <Button color="primary" onClick={handleDeleteGallery}>
              אישור
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={modalVideo} className="text-right">
          <ModalBody style={{ fontSize: "16px" }}>
            פעולה זו תמחוק את הוידאו
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleVideo}>
              ביטול
            </Button>
            <Button color="primary" onClick={handleDeleteVideo}>
              אישור
            </Button>
          </ModalFooter>
        </Modal>

        {showPlantDetails ? (
          <h2 className="text-center">פרטי הצמח</h2>
        ) : (
          <h2 className="text-center">תמונות לזיהוי הצמח</h2>
        )}

        <Row>
          <Col xs={6} className="d-flex justify-content-start">
            <Button
              className="btn-fill mx-1"
              type="submit"
              style={{ width: "200px", color: "white" }}
              onClick={() => {
                setShowPlantDetails(!showPlantDetails);
              }}
              disabled={showPlantDetails}
            >
              פרטי הצמח{" "}
            </Button>
            <Button
              className="btn-fill mx-1"
              type="submit"
              style={{ width: "200px", color: "white" }}
              onClick={() => {
                setShowPlantDetails(!showPlantDetails);
              }}
              disabled={!showPlantDetails}
            >
              תמונות לזיהוי הצמח{" "}
            </Button>
          </Col>
          {showPlantDetails ? (
            <Col xs={6} className="d-flex justify-content-end">
              <Link to={"/plants"}>
                <Button
                  className="btn-fill mx-1"
                  color="primary"
                  type="submit"
                  style={{ width: "250px" }}
                >
                  חזרה{" "}
                </Button>
              </Link>
            </Col>
          ) : (
            <Col xs={6} className="d-flex justify-content-end">
              <Link
                to={`/plants/${props.match.params.id}/identification-images/create`}
              >
                <Button
                  className="btn-fill mx-1"
                  color="primary"
                  type="submit"
                  style={{ width: "250px" }}
                >
                  צור תמונה לזיהוי צמח{" "}
                </Button>
              </Link>
            </Col>
          )}
        </Row>

        {showPlantDetails ? (
          <Row>
            <Col xs={12} className="text-right">
              <Card
                style={{
                  padding: "10px",
                }}
              >
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-md-1" md="10">
                        <FormGroup>
                          <h4 style={{ margin: "0px" }}>תמונה ראשית </h4>
                          <label
                            onClick={() => {
                              setShowPrimary(!showPrimary);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            עדכון תמונה ראשית
                          </label>
                        </FormGroup>
                      </Col>
                      <Col
                        className="pr-md-1 d-flex justify-content-end"
                        md="2"
                      >
                        <FormGroup>
                          <label>תאריך יצירת צמח</label>
                          <br />
                          <label>
                            {new Date(plant.created_at).toLocaleDateString(
                              "en-GB"
                            )}
                          </label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        md="4"
                        className="pr-md-1 d-flex justify-content-start"
                      >
                        <CardImg
                          top
                          width="100%"
                          style={{ height: "370px", objectFit: "cover" }}
                          src={
                            plant.primaryImage.path !== ""
                              ? `${URL_API}${plant.primaryImage.path}`
                              : noImage
                          }
                          alt="תמונה ראשית"
                        />
                      </Col>
                    </Row>
                    <br />
                    {showPrimary && (
                      <Row>
                        <Col
                          className="pr-md-1 d-flex justify-content-start"
                          md="12"
                        >
                          <FormGroup>
                            <label>
                              ניתן להעלות קבצים מסוג: png, jpg, jpeg
                            </label>
                            <br />
                            <label>גודל קובץ עד 5MB</label>
                          </FormGroup>
                        </Col>
                        <Col
                          className="pr-md-1 d-flex justify-content-start"
                          md="12"
                        >
                          <FormGroup>
                            <StyledDropzone
                              handleFileSave={handleChangePrimary}
                              handleRemoveFile={handleDeletePrimary}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    )}

                    <Row>
                      <Col className="pr-md-1" md="5">
                        <FormGroup>
                          <h4>שם הצמח *</h4>
                          <Input
                            name="name"
                            value={plant.name}
                            onChange={handleOnChange}
                            type="text"
                            required
                          />
                          {errorName}
                        </FormGroup>
                      </Col>
                      <Col className="pr-md-1" md="5">
                        <FormGroup>
                          <h4>שם מדעי *</h4>
                          <Input
                            name="scientificName"
                            value={plant.scientificName}
                            onChange={handleOnChange}
                            type="text"
                            required
                          />
                          {errorScientific}
                        </FormGroup>
                      </Col>
                      <Col
                        className="pr-md-1 d-flex justify-content-center"
                        md="1"
                      >
                        <FormGroup>
                          <h4>רעיל</h4>
                          <Input
                            name="toxic"
                            type="checkbox"
                            style={{
                              height: "35px",
                              width: "35px",
                              marginTop: "0px",
                            }}
                            checked={plant.toxic}
                            onChange={(e) => {
                              handleOnCheck(e, plant.toxic);
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col
                        className="pr-md-1 d-flex justify-content-center"
                        md="1"
                      >
                        <FormGroup>
                          <h4>בהכחדה</h4>
                          <Input
                            name="endangered"
                            type="checkbox"
                            style={{
                              height: "35px",
                              width: "35px",
                              marginTop: "0px",
                            }}
                            checked={plant.endangered}
                            onChange={(e) => {
                              handleOnCheck(e, plant.endangered);
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col className="pr-md-1" md="12">
                        <FormGroup>
                          <h4>משפחה *</h4>
                          <TextareaAutosize
                            className="autosize-textarea"
                            name="family"
                            maxLength="1000"
                            value={plant.family}
                            onChange={handleOnChange}
                            required
                          />
                          {errorFamily}
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col className="pr-md-1" md="12">
                        <FormGroup>
                          <h4>תיאור הצמח *</h4>
                          <TextareaAutosize
                            className="autosize-textarea"
                            name="description"
                            minLength="10"
                            maxLength="1000"
                            value={plant.description}
                            onChange={handleOnChange}
                            required
                          />
                          {errorDescription}
                        </FormGroup>
                      </Col>
                    </Row>

                    <br />

                    <Row>
                      <Col className="pr-md-1" md="12">
                        <FormGroup>
                          <h4 style={{ margin: "0px" }}>וידאו </h4>
                          <label
                              onClick={() => {
                                setShowVideo(!showVideo);
                              }}
                              style={{ cursor: "pointer" }}
                          >
                            עדכון
                          </label>
                          <label style={{ padding: "0px 10px" }}> / </label>

                          {plant.hasOwnProperty("video") &&
                          plant.video !== null ? (
                              <label
                                  onClick={toggleVideo}
                                  style={{ cursor: "pointer" }}
                              >
                                מחיקה
                              </label>
                          ) : (
                              <label style={{ color: "grey" }}>מחיקה</label>
                          )}

                          {(!plant.hasOwnProperty("video") ||
                              plant.video === null) && (
                              <ul>
                                <li>לא נמצא וידאו במערכת</li>
                              </ul>
                          )}

                          {plant.video && ( // && !showVideo
                              <ul>
                                <li>סוג וידאו: {plant.video.source}</li>
                                <li>
                                  וידאו לינק:{" "}
                                  <a
                                      target="_blank"
                                      href={
                                        plant.video.source === "youtube"
                                            ? `${YouTube}${plant.video.url}`
                                            : `${Vimeo}${plant.video.url}`
                                      }
                                      style={{ color: "blue" }}
                                  >
                                    {plant.video.source === "youtube"
                                        ? `${YouTube}${plant.video.url}`
                                        : `${Vimeo}${plant.video.url}`}
                                  </a>
                                </li>
                              </ul>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>

                    {showVideo && (
                        <>
                          <Row>
                            <Col className="pr-md-1" md="6">
                              <FormGroup>
                                <h4>סוג וידאו</h4>
                                <Select
                                    isClearable={true}
                                    isRtl={true}
                                    isSearchable={true}
                                    options={videoOptions}
                                    onChange={handleVideoSelect}
                                />
                              </FormGroup>
                            </Col>

                            {videoSource !== "" && (
                                <Col className="px-md-1" md="6">
                                  <FormGroup>
                                    <h4>וידאו ID</h4>
                                    <Input
                                        name="videoLink"
                                        type="text"
                                        onChange={handleVideoUrl}
                                        value={videoUrlId}
                                    />
                                    {errorUrl}
                                  </FormGroup>
                                </Col>
                            )}
                          </Row>

                          {errorUrl === null && (
                              <Row>
                                <Col className="pr-md-1" md="12">
                                  {videoSource === "youtube" && videoUrlId !== "" && (
                                      <YoutubePlayer url={`${YouTube}${videoUrlId}`} />
                                  )}

                                  {videoSource === "vimeo" && videoUrlId !== "" && (
                                      <VimeoPlayer
                                          controls={true}
                                          url={`${Vimeo}${videoUrlId}`}
                                      />
                                  )}
                                </Col>
                              </Row>
                          )}
                        </>
                    )}
                    <br />

                    <br />
                    <Row>
                      <Col className="pr-md-1" md="12">
                        <FormGroup>
                          <h4 style={{ margin: "0" }}>מידע על הצמח</h4>
                          {errorInformation}
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col className="pr-md-1" md="12">
                        <FormGroup>
                          <label>איפה למצוא *</label>
                          <TextareaAutosize
                            className="autosize-textarea"
                            required
                            value={plant.information.whereToFind}
                            name="whereToFind"
                            minLength="10"
                            maxLength="1000"
                            onChange={handleOnChangeInformation}
                          />
                          {plant.information.whereToFind.length < 10 && error}
                        </FormGroup>
                      </Col>
                      <Col className="pr-md-1" md="12">
                        <FormGroup>
                          <label>פרטי זיהוי *</label>
                          <TextareaAutosize
                            className="autosize-textarea"
                            required
                            value={plant.information.identificationDetails}
                            name="identificationDetails"
                            minLength="10"
                            maxLength="1000"
                            onChange={handleOnChangeInformation}
                          />
                          {plant.information.identificationDetails.length <
                            10 && error}
                        </FormGroup>
                      </Col>
                      <Col className="pr-md-1" md="12">
                        <FormGroup>
                          <label>אזהרות *</label>
                          <TextareaAutosize
                            className="autosize-textarea"
                            required
                            value={plant.information.warning}
                            name="warning"
                            minLength="10"
                            maxLength="1000"
                            onChange={handleOnChangeInformation}
                          />
                          {plant.information.warning.length < 10 && error}
                        </FormGroup>
                      </Col>
                      <Col className="pr-md-1" md="12">
                        <FormGroup>
                          <label>הכנות *</label>
                          <TextareaAutosize
                            className="autosize-textarea"
                            required
                            value={plant.information.preparation}
                            name="preparation"
                            minLength="10"
                            maxLength="1000"
                            onChange={handleOnChangeInformation}
                          />
                          {plant.information.preparation.length < 10 && error}
                        </FormGroup>
                      </Col>
                      <Col className="pr-md-1" md="12">
                        <FormGroup>
                          <label>הרכב *</label>
                          <TextareaAutosize
                            className="autosize-textarea"
                            required
                            value={plant.information.composition}
                            name="composition"
                            minLength="10"
                            maxLength="1000"
                            onChange={handleOnChangeInformation}
                          />
                          {plant.information.composition.length < 10 && error}
                        </FormGroup>
                      </Col>

                      <Col className="pr-md-1" md="12">
                        <FormGroup>
                          <label>טריויה *</label>
                          <TextareaAutosize
                            className="autosize-textarea"
                            required
                            value={plant.information.trivia}
                            name="trivia"
                            minLength="10"
                            maxLength="1000"
                            onChange={handleOnChangeInformation}
                          />
                          {plant.information.trivia.length < 10 && error}
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col className="pr-md-1" md="12">
                        <FormGroup>
                          <label>יתרונות *</label>
                          <ol>
                            {plant.information.benefits.map(
                              (benefit, index) => {
                                return (
                                  <li
                                    key={index}
                                    style={{
                                      paddingRight: "10px",
                                    }}
                                  >
                                    <Row
                                      style={{
                                        marginBottom: "10px",
                                      }}
                                    >
                                      <Col md="3">
                                        <Input
                                          name="type"
                                          placeholder="שם האיבר בגוף"
                                          type="text"
                                          required
                                          minLength="10"
                                          maxLength="1000"
                                          value={benefit.type}
                                          onChange={(e) =>
                                            handleInputBenefit(e, index)
                                          }
                                        />
                                      </Col>

                                      <Col md="8">
                                        <TextareaAutosize
                                          className="autosize-textarea"
                                          placeholder="תיאור היתרון"
                                          required
                                          value={benefit.details}
                                          name="details"
                                          minLength="10"
                                          onChange={(e) =>
                                            handleInputBenefit(e, index)
                                          }
                                        />
                                      </Col>

                                      <Col md="1">
                                        {index !== 0 && (
                                          <label
                                            className="label-secondary"
                                            style={{
                                              cursor: "pointer",
                                              fontWeight: "bold",
                                              fontSize: "16px",
                                              marginTop: "8px",
                                            }}
                                            onClick={() =>
                                              handleRemoveBenefit(index)
                                            }
                                          >
                                            X
                                          </label>
                                        )}
                                      </Col>
                                    </Row>
                                  </li>
                                );
                              }
                            )}
                          </ol>
                          {errorBenefits}
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <label
                        onClick={handleAddBenefit}
                        style={{ cursor: "pointer", fontWeight: "bold" }}
                      >
                        הוספת יתרונות
                      </label>
                    </Row>
                    <br />

                    <Row>
                      <Col className="pr-md-1" md="12">
                        <FormGroup>
                          <h4 style={{ margin: "0px" }}>קטגוריות</h4>
                          <label
                            onClick={() => {
                              setShowCategory(!showCategory);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            עדכון קטגוריות
                          </label>

                          {!plant.hasOwnProperty("categories") && (
                            <ul>
                              <li>לא נמצאו קטגוריות במערכת</li>
                            </ul>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>

                    {plant.hasOwnProperty("categories") && (
                      <Row>
                        <Col className="pr-md-1" md="12">
                          <FormGroup>
                            {plant.categories.map((category) => {
                              return (
                                <Badge
                                  color="primary"
                                  key={category._id}
                                  style={{
                                    fontSize: "14px",
                                    padding: "16px 18px",
                                    margin: "0px 0px 10px 15px",
                                  }}
                                >
                                  {category.name}
                                </Badge>
                              );
                            })}
                          </FormGroup>
                        </Col>
                      </Row>
                    )}

                    {showCategory && (
                      <>
                        <Row style={{ marginTop: "-10px" }}>
                          <Col className="pr-md-1" md="6">
                            <FormGroup>
                              <Select
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                isMulti
                                options={categories}
                                placeholder="בחר/י קטגוריות"
                                onChange={handleSelectParentCategories}
                              />
                            </FormGroup>
                          </Col>
                          {selectedParentCategory && (
                            <Col className="pr-md-1" md="6">
                              <FormGroup>
                                <Select
                                  closeMenuOnSelect={false}
                                  components={animatedComponents}
                                  isMulti
                                  options={subCategoriesOptions}
                                  placeholder="בחר/י תת קטגוריות"
                                  onChange={handleSelectCategories}
                                />
                              </FormGroup>
                            </Col>
                          )}
                        </Row>
                        <br />
                      </>
                    )}

                    <Row>
                      <Col className="pr-md-1" md="12">
                        <FormGroup>
                          <h4 style={{ margin: "0px" }}>תמונות משניות </h4>
                          <label
                            onClick={() => {
                              setShowSecondary(!showSecondary);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            עדכון
                          </label>
                          <label style={{ padding: "0px 10px" }}> / </label>

                          {plant.secondaryImages.length > 0 ? (
                            <label
                              onClick={toggleGallery}
                              style={{ cursor: "pointer" }}
                            >
                              מחיקה
                            </label>
                          ) : (
                            <label style={{ color: "grey" }}>מחיקה</label>
                          )}

                          {plant.secondaryImages.length === 0 && (
                            <ul>
                              <li>לא נמצאו תמונות במערכת</li>
                            </ul>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>

                    {plant.secondaryImages.length > 0 && (
                      <Row>
                        {plant.secondaryImages.map((image, index) => {
                          return (
                            <Col className="pr-md-1" md="3" key={index}>
                              <FormGroup>
                                <img
                                  src={
                                    image.path !== ""
                                      ? `${URL_API}${image.path}`
                                      : noImage
                                  }
                                  alt="תמונה משנית"
                                  // style={{ width: "15cm", height: "10cm" }}
                                  style={{
                                    height: "8cm",
                                    width: "13cm",
                                    paddingLeft: "10px",
                                    objectFit: "cover",
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          );
                        })}
                      </Row>
                    )}

                    {showSecondary && (
                      <Row>
                        <Col className="pr-md-1" md="12">
                          <FormGroup>
                            <label>
                              ניתן להעלות קבצים מסוג: png, jpg, jpeg
                            </label>
                            <br />
                            <label>כל קובץ תמונה עד 5MB</label>
                            <h5>
                              התמונות ישמרו ויוצגו לפי הסדר הנ"ל מימין לשמאל
                            </h5>
                          </FormGroup>
                        </Col>
                        {imageList.map((val, i) => {
                          return (
                            <Col className="pr-md-1" md="3" key={i}>
                              <FormGroup>
                                <StyledDropzone
                                  handleFileSave={handleChangeSecondary}
                                  handleRemoveFile={handleDeleteSecondary}
                                />
                              </FormGroup>
                            </Col>
                          );
                        })}
                      </Row>
                    )}
                  </Form>

                  <br />
                  <h4>
                    ** שימו לב! עדכון כל אחד מהשדות ידרוס את הערך הקיים במערכת!
                  </h4>
                </CardBody>

                <CardFooter>
                  <Row>
                    {plant.active ? (
                      <Col className="pr-md-1" md="4">
                        <Button
                          onClick={handleDeactivate}
                          className="btn-fill mx-1"
                          color="primary"
                          type="submit"
                          style={{ width: "7cm" }}
                        >
                          ביטול צמח
                        </Button>
                      </Col>
                    ) : (
                      <Col className="pr-md-1" md="4">
                        <Button
                          onClick={handleActivate}
                          className="btn-fill mx-1"
                          color="primary"
                          type="submit"
                          style={{ width: "7cm" }}
                        >
                          הפעלת צמח
                        </Button>
                      </Col>
                    )}
                    <Col className="pr-md-1 d-flex justify-content-end" md="8">
                      <Button
                        onClick={handleUpdate}
                        className="btn-fill mx-1"
                        color="primary"
                        type="submit"
                        style={{ width: "7cm" }}
                      >
                        עדכון צמח
                      </Button>
                    </Col>
                  </Row>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        ) : (
          <>
            <br />
            <IdentificationImages plantId={props.match.params.id} />
          </>
        )}

        {/* <Tabs className="text-right">
          <TabList>

            <Tab>פרטי הצמח</Tab>
            <Tab>תמונות</Tab>
          </TabList>

          <TabPanel>

          </TabPanel>

          <TabPanel>
            <h2>זיהוי צמחים...</h2>
          </TabPanel>
        </Tabs> */}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  plantCategories: state.shared.plantCategories,
  plantCharacteristics: state.shared.plantCharacteristics,
  singlePlant: state.plants.singlePlant,
  active: state.plants.active,
  sendingRequest: state.plants.sendingRequest,
  updatedPlant: state.plants.updatedPlant,
  imageUpdated: state.plants.imageUpdated,
  failed: state.plants.failed,
});

export default connect(mapStateToProps, {
  getCategories,
  getPlantIdentificationImageCharacteristics,
  getPlantDetails,
  deactivatePlant,
  activatePlant,
  updatePlant,
  deleteGallery,
  getPlantIdentificationImages,
})(PlantDetails);
