import {
  DATA_LOADING,
  CATEGORIES_LOAD_FAIL,
  ARTICLE_CATEGORIES_LOADED,
  RECIPE_CATEGORIES_LOADED,
  PLANT_CATEGORIES_LOADED,
  PLANTS_LOADED,
  PLANTS_LOAD_FAIL,
  PLANT_CHARASTERISTICS_LOADED,
  PLANT_CHARASTERISTICS_LOAD_FAIL,
} from "../types/shared";

const initialState = {
  loading: false,
  statsLoading: false,
  plantsList: [],
  totalPlants: 0,
  articleCategories: null,
  recipeCategories: null,
  plantCategories: null,
  plantCharacteristics: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case DATA_LOADING:
      return {
        ...state,
        loading: true,
      };
    case PLANTS_LOADED:
      return {
        ...state,
        loading: false,
        plantsList: action.payload.data.plants,
        totalPlants: action.payload.data.entityCount,
      };
    case ARTICLE_CATEGORIES_LOADED:
      return {
        ...state,
        loading: false,
        articleCategories: action.payload,
      };
    case RECIPE_CATEGORIES_LOADED:
      return {
        ...state,
        loading: false,
        recipeCategories: action.payload,
      };
    case PLANT_CATEGORIES_LOADED:
      return {
        ...state,
        loading: false,
        plantCategories: action.payload,
      };
    case PLANT_CHARASTERISTICS_LOADED:
      return {
        ...state,
        loading: false,
        plantCharacteristics: action.payload,
      };
    case CATEGORIES_LOAD_FAIL:
    case PLANTS_LOAD_FAIL:
    case PLANT_CHARASTERISTICS_LOAD_FAIL:
      return {
        ...state,
        loading: false,
        statsLoading: false,
      };
    default:
      return state;
  }
}
