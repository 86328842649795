import React from "react";
import { Route, Switch } from "react-router-dom";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
// react plugin for creating notifications over the dashboard
// import NotificationAlert from "react-notification-alert";
import { Helmet } from "react-helmet";

// core components
import RTLNavbar from "components/Navbars/RTLNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
// import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import routes from "routes.js";
import { adminRoutes, crownedAdminRoutes } from "routes.js";

import { getAdminProfile } from "./redux/actions/admins";

import logo from "assets/img/custom/logo-white.png";
// import { Button, InputGroup, Nav } from "reactstrap";
import { connect } from "react-redux";

var ps;

const RouteWithTitle = ({ title, ...props }) => (
  <>
    <Helmet>
      <title>
        {title} | {process.env.REACT_APP_PROJECT_NAME}
      </title>
    </Helmet>
    <Route {...props} />
  </>
);

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeColor: "green",
      sidebarMini: true,
      opacity: 0,
      sidebarOpened: false,
    };
  }

  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(this.refs.mainPanel);
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    window.addEventListener("scroll", this.showNavbarButton);
    // on this page, we need on the body tag the classes .rtl and .menu-on-right
    // an on the html tag the classe .rtl-active
    document.body.classList.add("rtl", "menu-on-right");
    document.documentElement.classList.add("rtl-active");
    this.props.getAdminProfile();
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
    window.removeEventListener("scroll", this.showNavbarButton);
    // when we exit this page, we need to delete the classes .rtl and .menu-on-right
    // from the body tag and the class rtl-active from the html tag
    document.body.classList.remove("rtl", "menu-on-right");
    document.documentElement.classList.remove("rtl-active");
  }

  componentDidUpdate(e) {
    if (e.location.pathname !== e.history.location.pathname) {
      if (navigator.platform.indexOf("Win") > -1) {
        let tables = document.querySelectorAll(".table-responsive");
        for (let i = 0; i < tables.length; i++) {
          ps = new PerfectScrollbar(tables[i]);
        }
      }
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }

  showNavbarButton = () => {
    if (
      (document.documentElement.scrollTop > 50 ||
        document.scrollingElement.scrollTop > 50 ||
        this.refs.mainPanel.scrollTop > 50) &&
      this.state.opacity === 0
    ) {
      this.setState({ opacity: 1 });
    }
    if (
      document.documentElement.scrollTop <= 50 &&
      document.scrollingElement.scrollTop <= 50 &&
      this.refs.mainPanel.scrollTop <= 50 &&
      this.state.opacity === 1
    ) {
      this.setState({ opacity: 0 });
    }
  };
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.specific) {
        return (
          <RouteWithTitle
            title={prop.name}
            exact
            path={prop.path + prop.specific}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return (
          <RouteWithTitle
            title={prop.name}
            exact
            path={prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
    });
  };

  getActiveRoute = (routes) => {
    let activeRoute = "Lukit";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = this.getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.pathname.indexOf(
            routes[i].layout + routes[i].path
          ) !== -1
        ) {
          return routes[i].rtlName;
        }
      }
    }
    return activeRoute;
  };

  handleActiveClick = (color) => {
    this.setState({ activeColor: color });
  };

  handleMiniClick = () => {
    let notifyMessage = "Sidebar mini ";
    if (document.body.classList.contains("sidebar-mini")) {
      this.setState({ sidebarMini: false });
      notifyMessage += "deactivated...";
    } else {
      this.setState({ sidebarMini: true });
      notifyMessage += "activated...";
    }
    let options = {};
    options = {
      place: "tc",
      message: notifyMessage,
      type: "primary",
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7,
    };
    document.body.classList.toggle("sidebar-mini");
  };
  toggleSidebar = () => {
    this.setState({
      sidebarOpened: !this.state.sidebarOpened,
    });
    document.documentElement.classList.toggle("nav-open");
  };
  closeSidebar = () => {
    this.setState({
      sidebarOpened: false,
    });
    document.documentElement.classList.remove("nav-open");
  };

  render() {
    return (
      <div className="wrapper">
        <Sidebar
          {...this.props}
          routes={this.props.crowned ? crownedAdminRoutes : adminRoutes}
          activeColor={this.state.activeColor}
          rtlActive
          logo={{
            outterLink: "/dashboard",
            text: "לוקיט",
            imgSrc: logo,
          }}
          closeSidebar={this.closeSidebar}
        />

        <div
          className="main-panel"
          ref="mainPanel"
          data={this.state.activeColor}
        >
          <RTLNavbar
            {...this.props}
            handleMiniClick={this.handleMiniClick}
            brandText={this.getActiveRoute(routes)}
            sidebarOpened={this.state.sidebarOpened}
            toggleSidebar={this.toggleSidebar}
          />

          <Switch>{this.getRoutes(routes)}</Switch>
          <Footer fluid />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  crowned: state.admins.crowned,
});

export default connect(mapStateToProps, { getAdminProfile })(Admin);
