import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
// import Spinner from "../../../components/Spinner";
import { Link } from "react-router-dom";
import ReactBSAlert from "react-bootstrap-sweetalert";
import YoutubePlayer from "react-player/youtube";
import VimeoPlayer from "react-player/vimeo";
import noImage from "../../../assets/img/no-image.jpg";
import { URL_API } from "../../../path";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import makeAnimated from "react-select/animated";
import StyledDropzone from "../../components/Dropzone";
import { getCategories, getPlants } from "redux/actions/shared";
import TextareaAutosize from "react-textarea-autosize";
import LoadingModal from "views/components/LoadingModal";
import { YouTube, Vimeo } from "../../constants";
import ToastError, { ERROR_10, REQUIRED_FIELD } from "../../components/Errors";

import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  CardFooter,
  Form,
  FormGroup,
  Input,
  Badge,
  Modal,
  ModalBody,
  ModalFooter,
  CardImg,
} from "reactstrap";

import {
  getArticleDetails,
  activateArticle,
  deactivateArticle,
  updateArticle,
  deleteGallery,
  deleteVideo,
} from "redux/actions/articles";

let timer = null;

/**
 * Article Details Component
 * Shows all details and allows user to update fields, deactivate and activate the article
 */

const ArticleDetails = (props) => {
  //const {} = props;

  const animatedComponents = makeAnimated();

  const [article, setArticle] = useState({
    title: "",
    body: "",
    snippet: "",
    categories: [],
    relatedPlants: [],
    video: {
      url: "",
      source: "",
    },
    premium: false,
    secondaryImages: [],
    primaryImage: {},
    active: props.active,
  });

  const [articleCategories, setArticleCategories] = useState([
    { value: "", label: "" },
  ]);

  const videoOptions = [
    { value: "youtube", label: "Youtube" },
    { value: "vimeo", label: "Vimeo" },
  ];

  const [errorTitle, setErrorTitle] = useState(null);
  const [errorBody, setErrorBody] = useState(null);
  const [errorSnippet, setErrorSnippet] = useState(null);
  const [errorUrl, setErrorUrl] = useState(null);

  const [alert, setAlert] = useState(null);
  const [videoUrlId, setVideoUrlId] = useState("");
  const [videoSource, setVideoSource] = useState("");
  const [videoUrlInput, setVideoUrlInput] = useState("");

  const [showPrimary, setShowPrimary] = useState(false);
  const [showSecondary, setShowSecondary] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [showCategory, setShowCategory] = useState(false);
  const [showRelated, setShowRelated] = useState(false);
  const [imageList] = useState(["", "", "", ""]);
  const [secondary, setSecondary] = useState([]);
  const [primary, setPrimary] = useState(null);
  const [categories, setCategories] = useState([]);
  const [relatedPlants, setRelatedPlants] = useState([]);
  const [modalGallery, setModalGallery] = useState(false);
  const [modalVideo, setModalVideo] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [modalMessage, setModalMessage] = useState("הפעולה בוצעה בהצלחה");

  const toggleGallery = () => {
    setModalGallery(!modalGallery);
  };

  const toggleVideo = () => {
    setModalVideo(!modalVideo);
  };

  /**
   * function that deletes all secondary images
   */
  const handleDeleteGallery = () => {
    setModalGallery(!modalGallery);
    props.deleteGallery(article._id);
    if (props.deleteGallery) {
      setModalSuccess(!modalSuccess);
      setArticle({ ...article, secondaryImages: [] });
    }
  };

  /**
   * function that deletes video
   */
  const handleDeleteVideo = () => {
    setModalVideo(!modalVideo);
    props.deleteVideo(article._id);
    if (props.deleteVideo) {
      setModalSuccess(!modalSuccess);

      delete article.video;
    }
  };

  /**
   * function that handles selecting video source
   */
  const handleVideoSelect = (selectedOption) => {
    setErrorUrl(null);

    if (selectedOption !== null) {
      if (videoSource.toLowerCase() !== selectedOption.value.toLowerCase()) {
        setVideoUrlId("");
        setVideoUrlInput("");
      }

      setVideoSource(selectedOption.value);
    } else {
      setVideoSource("");
      setVideoUrlId("");
    }
  };

  /**
   * function that handles video id input
   */
  const handleVideoUrl = (e) => {
    let urlId = e.target.value;
    setVideoUrlInput(urlId);

    console.log(urlId);

    if (urlId !== "") {
      // TODO - commented out due to failing on the following ID (3vi-1s1Bjs4)
      // let isValidId = validateUrlID(urlId);
      //
      // console.log(isValidId);
      //
      // if (isValidId) {
      //   setVideoUrlId(urlId);
      // }


      setVideoUrlId(urlId);
      setErrorUrl(null);
    } else {
      setVideoUrlId("");
      setErrorUrl(<ToastError error={REQUIRED_FIELD} />);
    }
  };

  /**
   * function that validates url id
   */
  const validateUrlID = (urlId) => {
    let regex = /^[0-9a-zA-Z]+$/;
    if (urlId.match(regex)) {
      return true;
    }
    return false;
  };

  /*
   * function for getting options from backend
   * using setTimeout for optimization - does not send request to backend until user stops typing
   */
  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      clearTimeout(timer);

      timer = setTimeout(async () => {
        let list = [];

        const data = await props.getPlants(0, 10, inputValue);
        list = data.plants.map((plant) => {
          return {
            id: plant._id,
            value: plant.name,
            label: plant.name,
          };
        });

        resolve(list);
      }, 500);
    });

  /**
   * function that handles selecting related plants
   */
  const handleSelectPlants = (selected) => {
    if (selected) {
      let plantsIds = selected.map((plant) => {
        return plant.id;
      });

      setRelatedPlants(plantsIds);
    } else {
      setRelatedPlants([]);
    }
  };

  /**
   * function that handles selecting categories
   */
  const handleSelectCategories = (selected) => {
    if (selected) {
      let categoriesIds = selected.map((category) => {
        return category.id;
      });

      setCategories(categoriesIds);
    } else {
      setCategories([]);
    }
  };

  /**
   * function that handles primary image
   */
  const handleChangePrimary = (image) => {
    setPrimary(image[0]);
  };

  /**
   * function that handles secondary image
   */
  const handleChangeSecondary = (image) => {
    setSecondary([...secondary, image[0]]);
  };

  /**
   * function that handles delete primary image
   */
  const handleDeletePrimary = () => {
    setPrimary(null);
  };

  /**
   * function that handles delete secondary image
   */
  const handleDeleteSecondary = (image) => {
    let updatedList = secondary.filter(
      (val) => val.preview === image[0].preview
    );
    setSecondary(updatedList);
  };

  const handleOnChange = (e) => {
    setArticle({ ...article, [e.target.name]: e.target.value });
  };

  /**
   * show alert function for activating / deactivating an artcile
   */
  const showAlert = (message, action, activeObj) => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="האם את/ה בטוח/ה?"
        onConfirm={() => {
          action(activeObj, article._id, props);
          setAlert(null);
        }}
        onCancel={() => {
          setAlert(null);
        }}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText="אישור"
        cancelBtnText="ביטול"
        showCancel
        btnSize=""
      >
        {message}
      </ReactBSAlert>
    );
  };

  /**
   * show alert function for updating an artcile
   */
  const showUpdateAlert = (toUpdate) => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="האם את/ה בטוח/ה?"
        onConfirm={() => {
          props.updateArticle(toUpdate, article._id, props);
          setAlert(null);
        }}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText="אישור"
        cancelBtnText="ביטול"
        showCancel
        btnSize=""
      >
        הפעולה הבאה תעדכן את המאמר
      </ReactBSAlert>
    );
  };

  /**
   * functions alert for error
   */
  const showErrorAlert = () => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="לא הוזנו נתונים לעדכון"
        onConfirm={() => {
          setAlert(null);
        }}
        confirmBtnBsStyle="error"
        confirmBtnText="אישור"
        btnSize=""
      >
        אנא נסה/י שנית
      </ReactBSAlert>
    );
  };

  const showUpdateErrorAlert = () => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="נא למלא את כל שדות החובה"
        onConfirm={() => {
          setAlert(null);
        }}
        confirmBtnBsStyle="error"
        confirmBtnText="אישור"
        btnSize=""
      >
        שדות חובה מסומנים בכוכבית
      </ReactBSAlert>
    );
  };

  /**
   * function thats handles article activate
   */
  const handleActivate = () => {
    showAlert("הפעולה הבאה תהפוך את המאמר לפעיל", props.activateArticle, {
      active: true,
    });
  };

  /**
   * function thats handles article deactivate
   */
  const handleDeactivate = () => {
    showAlert("הפעולה הבאה תהפוך את המאמר ללא פעיל", props.deactivateArticle, {
      active: false,
    });
  };

  /**
   * function to reset all error messages
   */
  const resetErrorMessages = () => {
    setErrorTitle(null);
    setErrorBody(null);
    setErrorSnippet(null);
  };

  /**
   * function thats handles article update
   */
  const handleUpdate = () => {
    let toUpdate = {};
    let toUpdateFlag = true;

    resetErrorMessages();

    if (article.title === "") {
      toUpdateFlag = false;
      setErrorTitle(<ToastError error={REQUIRED_FIELD} />);
    }

    if (article.body === "" || article.body.length < 10) {
      toUpdateFlag = false;
      setErrorBody(<ToastError error={ERROR_10} />);
    }

    if (article.snippet === "" && article.snippet.length < 10) {
      toUpdateFlag = false;
      setErrorSnippet(<ToastError error={ERROR_10} />);
    }

    if (toUpdateFlag) {
      if (article.title !== props.singleArticle.title) {
        toUpdate = { ...toUpdate, title: article.title };
      }

      if (article.body !== props.singleArticle.body) {
        toUpdate = { ...toUpdate, body: article.body };
      }

      if (article.snippet !== props.singleArticle.snippet) {
        toUpdate = { ...toUpdate, snippet: article.snippet };
      }

      if (primary !== null) {
        toUpdate = { ...toUpdate, primaryImage: primary };
      }

      if (secondary.length > 0) {
        toUpdate = { ...toUpdate, secondaryImages: secondary };
      }

      if (categories.length > 0) {
        toUpdate = { ...toUpdate, categories: categories };
      }

      console.log(videoSource);
      console.log(videoUrlId);
      console.log(errorUrl);

      if (videoSource !== "" && videoUrlId !== "" && errorUrl === null) {
        toUpdate = {
          ...toUpdate,
          video: { source: videoSource.toLowerCase(), url: videoUrlId },
        };
      }

      if (relatedPlants.length > 0) {
        toUpdate = { ...toUpdate, relatedPlants: relatedPlants };
      }

      //console.log(toUpdate);

      if (Object.keys(toUpdate).length !== 0) {
        resetErrorMessages();

        showUpdateAlert(toUpdate);
      } else {
        showErrorAlert();
      }
    } else {
      showUpdateErrorAlert();
    }
  };

  /*
   * useEffect for getting current article's details and categories
   */
  useEffect(() => {
    props.getArticleDetails(props.match.params.id);

    if (props.articleCategories === null) {
      props.getCategories("article");
    }
  }, []);

  /*
   * useEffect for setting current article
   * Math.random was added to each image path in order to make sure that the image changes after an update
   */
  useEffect(() => {
    if (props.singleArticle !== null) {
      setArticle({
        ...props.singleArticle,
        primaryImage: {
          ...props.singleArticle.primaryImage,
          path: props.singleArticle.primaryImage.path + "?" + Math.random(),
        },
        secondaryImages: [
          ...props.singleArticle.secondaryImages.map((image) => ({
            path: image.path + "?" + Math.random(),
          })),
        ],
      });
    }
  }, [props.singleArticle]);

  /**
   * useEffect for mapping the categories to id, value, label
   * needed for react-select component
   */
  useEffect(() => {
    if (props.articleCategories !== null) {
      setArticleCategories(
        props.articleCategories.map((category) => {
          return {
            id: category._id,
            value: category.name,
            label: category.name,
          };
        })
      );
    }
  }, [props.articleCategories]);

  /*
   * useEffect for checking if article was updated
   */
  useEffect(() => {
    setVideoSource("");
    setVideoUrlId("");
    if (props.updatedArticle) {
      setModalSuccess(props.updatedArticle);
      setShowCategory(false);
      setShowSecondary(false);
      setShowRelated(false);
      setShowPrimary(false);
      setShowVideo(false);

      props.getArticleDetails(props.match.params.id);
    }
  }, [props.updatedArticle]);

  useEffect(() => {
    if (props.failed) {
      setModalSuccess(props.failed);
      setModalMessage("הפעולה נכשלה");
    }
  }, [props.failed]);

  return (
    <>
      <div className="content">
        {alert}

        <LoadingModal loading={props.sendingRequest} />

        <Modal isOpen={modalSuccess} className="text-center">
          <ModalBody>
            <h4>{modalMessage}</h4>
            <Row>
              <Col md="12" className="d-flex justify-content-center">
                <Button
                  color="primary"
                  onClick={() => {
                    setModalSuccess(!modalSuccess);
                    setModalMessage("הפעולה בוצעה בהצלחה");
                  }}
                >
                  אישור
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>

        <Modal isOpen={modalGallery} className="text-right">
          <ModalBody style={{ fontSize: "16px" }}>
            פעולה זו תמחוק את הגלריה
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleGallery}>
              ביטול
            </Button>
            <Button color="primary" onClick={handleDeleteGallery}>
              אישור
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={modalVideo} className="text-right">
          <ModalBody style={{ fontSize: "16px" }}>
            פעולה זו תמחוק את הוידאו
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleVideo}>
              ביטול
            </Button>
            <Button color="primary" onClick={handleDeleteVideo}>
              אישור
            </Button>
          </ModalFooter>
        </Modal>

        <h2 className="text-center">פרטי המאמר</h2>

        <Row>
          <Col xs={12} className="text-right">
            <Link to={"/articles"}>
              <Button
                className="btn-fill mx-1"
                color="primary"
                type="submit"
                style={{ width: "250px" }}
              >
                חזרה{" "}
              </Button>
            </Link>
          </Col>
        </Row>

        <Row>
          <Col xs={12} className="text-right">
            <Card
              style={{
                padding: "10px",
              }}
            >
              <CardBody>
                <Form>
                  <Row>
                    <Col className="pr-md-1" md="10">
                      <FormGroup>
                        <h4 style={{ margin: "0px" }}>תמונה ראשית </h4>
                        <label
                          onClick={() => {
                            setShowPrimary(!showPrimary);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          עדכון תמונה ראשית
                        </label>
                      </FormGroup>
                    </Col>
                    <Col className="pr-md-1 d-flex justify-content-end" md="2">
                      <FormGroup>
                        <label>תאריך יצירת מאמר</label>
                        <br />
                        <label>
                          {new Date(article.created_at).toLocaleDateString(
                            "en-GB"
                          )}
                        </label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      md="4"
                      className="pr-md-1 d-flex justify-content-start"
                    >
                      <CardImg
                        top
                        width="100%"
                        style={{ height: "370px", objectFit: "cover" }}
                        src={
                          article.primaryImage.path !== ""
                            ? `${URL_API}${article.primaryImage.path}`
                            : noImage
                        }
                        alt="תמונה ראשית"
                      />
                      {/* <img
                        src={
                          article.primaryImage.path !== ""
                            ? `${URL_API}${article.primaryImage.path}`
                            : noImage
                        }
                        style={{ width: "15cm", height: "10cm" }}
                        alt="primaryImage"
                      /> */}
                    </Col>
                  </Row>
                  <br />

                  {showPrimary && (
                    <Row>
                      <Col
                        className="pr-md-1 d-flex justify-content-start"
                        md="12"
                      >
                        <FormGroup>
                          <label>ניתן להעלות קבצים מסוג: png, jpg, jpeg</label>
                          <br />
                          <label>גודל קובץ עד 5MB</label>
                        </FormGroup>
                      </Col>
                      <Col
                        className="pr-md-1 d-flex justify-content-start"
                        md="12"
                      >
                        <FormGroup>
                          <StyledDropzone
                            handleFileSave={handleChangePrimary}
                            handleRemoveFile={handleDeletePrimary}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  )}

                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <h4>כותרת המאמר *</h4>
                        <Input
                          name="title"
                          value={article.title}
                          type="text"
                          required
                          maxLength="60"
                          onChange={handleOnChange}
                        />
                        {errorTitle}
                      </FormGroup>
                    </Col>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <h4 style={{ marginBottom: "10px" }}>פרימיום</h4>
                        <Input
                          name="premium"
                          type="checkbox"
                          style={{ height: "35px", width: "35px" }}
                          checked={article.premium}
                          disabled
                        />
                        <br />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="12">
                      <FormGroup>
                        <h4>גוף המאמר *</h4>
                        <TextareaAutosize
                          className="autosize-textarea"
                          name="body"
                          type="textarea"
                          required
                          minLength="10"
                          maxLength="10000"
                          value={article.body}
                          onChange={handleOnChange}
                        />

                        {errorBody}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="12">
                      <FormGroup>
                        <h4>תיאור קצר *</h4>
                        <TextareaAutosize
                          className="autosize-textarea"
                          required
                          name="snippet"
                          type="textarea"
                          minLength="10"
                          maxLength="200"
                          value={article.snippet}
                          onChange={handleOnChange}
                        />
                        {errorSnippet}
                      </FormGroup>
                    </Col>
                  </Row>

                  <br />
                  <Row>
                    <Col className="pr-md-1" md="12">
                      <FormGroup>
                        <h4 style={{ margin: "0px" }}>קטגוריות</h4>
                        <label
                          onClick={() => {
                            setShowCategory(!showCategory);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          עדכון קטגוריות
                        </label>

                        {article.categories.length === 0 && (
                          <ul>
                            <li>לא נמצאו קטגוריות במערכת</li>
                          </ul>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="pr-md-1" md="12">
                      <FormGroup>
                        {article.categories.map((category) => {
                          return (
                            <Badge
                              color="primary"
                              key={category._id}
                              style={{
                                fontSize: "14px",
                                padding: "16px 18px",
                                margin: "0px 0px 10px 15px",
                              }}
                            >
                              {category.name}
                            </Badge>
                          );
                        })}
                      </FormGroup>
                    </Col>
                  </Row>

                  {showCategory && (
                    <Row style={{ marginTop: "-10px" }}>
                      <Col className="pr-md-1" md="12">
                        <FormGroup>
                          <Select
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            isMulti
                            options={articleCategories}
                            placeholder="בחר/י קטגוריות"
                            onChange={handleSelectCategories}
                          />
                          <br />
                        </FormGroup>
                      </Col>
                    </Row>
                  )}

                  <Row>
                    <Col className="pr-md-1" md="12">
                      <FormGroup>
                        <h4 style={{ margin: "0px" }}>צמחים </h4>
                        <label
                          onClick={() => {
                            setShowRelated(!showRelated);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          עדכון צמחים
                        </label>

                        {article.relatedPlants.length === 0 && (
                          <ul>
                            <li>לא נמצאו צמחים במערכת</li>
                          </ul>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="pr-md-1" md="12">
                      <FormGroup>
                        {article.relatedPlants.map((plant) => {
                          return (
                            <Link
                              to={`/plants/${plant._id}`}
                              target="_blank"
                              key={plant._id}
                            >
                              <Badge
                                color="primary"
                                style={{
                                  fontSize: "14px",
                                  padding: "16px 18px",
                                  margin: "0px 0px 10px 15px",
                                }}
                              >
                                {plant.name}
                              </Badge>
                            </Link>
                          );
                        })}
                      </FormGroup>
                    </Col>
                  </Row>

                  {showRelated && (
                    <Row style={{ marginTop: "-10px" }}>
                      <Col className="pr-md-1" md="12">
                        <FormGroup>
                          <AsyncSelect
                            isMulti
                            components={animatedComponents}
                            placeholder="הקלידו שמות צמחים"
                            loadOptions={promiseOptions}
                            onChange={handleSelectPlants}
                          />

                          <br />
                        </FormGroup>
                      </Col>
                    </Row>
                  )}

                  <Row>
                    <Col className="pr-md-1" md="12">
                      <FormGroup>
                        <h4 style={{ margin: "0px" }}>וידאו </h4>
                        <label
                          onClick={() => {
                            setShowVideo(!showVideo);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          עדכון
                        </label>
                        <label style={{ padding: "0px 10px" }}> / </label>

                        {article.hasOwnProperty("video") &&
                        article.video !== null ? (
                          <label
                            onClick={toggleVideo}
                            style={{ cursor: "pointer" }}
                          >
                            מחיקה
                          </label>
                        ) : (
                          <label style={{ color: "grey" }}>מחיקה</label>
                        )}

                        {(!article.hasOwnProperty("video") ||
                          article.video === null) && (
                          <ul>
                            <li>לא נמצא וידאו במערכת</li>
                          </ul>
                        )}

                        {article.video && ( // && !showVideo
                          <ul>
                            <li>סוג וידאו: {article.video.source}</li>
                            <li>
                              וידאו לינק:{" "}
                              <a
                                target="_blank"
                                href={
                                  article.video.source === "youtube"
                                    ? `${YouTube}${article.video.url}`
                                    : `${Vimeo}${article.video.url}`
                                }
                                style={{ color: "blue" }}
                              >
                                {article.video.source === "youtube"
                                  ? `${YouTube}${article.video.url}`
                                  : `${Vimeo}${article.video.url}`}
                              </a>
                            </li>
                          </ul>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>

                  {showVideo && (
                    <>
                      <Row>
                        <Col className="pr-md-1" md="6">
                          <FormGroup>
                            <h4>סוג וידאו</h4>
                            <Select
                              isClearable={true}
                              isRtl={true}
                              isSearchable={true}
                              options={videoOptions}
                              onChange={handleVideoSelect}
                            />
                          </FormGroup>
                        </Col>

                        {videoSource !== "" && (
                          <Col className="px-md-1" md="6">
                            <FormGroup>
                              <h4>וידאו ID</h4>
                              <Input
                                name="videoLink"
                                type="text"
                                onChange={handleVideoUrl}
                                value={videoUrlInput}
                              />
                              {errorUrl}
                            </FormGroup>
                          </Col>
                        )}
                      </Row>

                      {errorUrl === null && (
                        <Row>
                          <Col className="pr-md-1" md="12">
                            {videoSource === "youtube" && videoUrlId !== "" && (
                              <YoutubePlayer url={`${YouTube}${videoUrlId}`} />
                            )}

                            {videoSource === "vimeo" && videoUrlId !== "" && (
                              <VimeoPlayer
                                controls={true}
                                url={`${Vimeo}${videoUrlId}`}
                              />
                            )}
                          </Col>
                        </Row>
                      )}
                    </>
                  )}
                  <br />
                  <Row>
                    <Col className="pr-md-1" md="12">
                      <FormGroup>
                        <h4 style={{ margin: "0px" }}>תמונות משניות </h4>
                        <label
                          onClick={() => {
                            setShowSecondary(!showSecondary);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          עדכון
                        </label>
                        <label style={{ padding: "0px 10px" }}> / </label>

                        {article.secondaryImages.length > 0 ? (
                          <label
                            onClick={toggleGallery}
                            style={{ cursor: "pointer" }}
                          >
                            מחיקה
                          </label>
                        ) : (
                          <label style={{ color: "grey" }}>מחיקה</label>
                        )}

                        {article.secondaryImages.length === 0 && (
                          <ul>
                            <li>לא נמצאו תמונות במערכת</li>
                          </ul>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>

                  {article.secondaryImages.length > 0 && (
                    <Row>
                      {article.secondaryImages.map((image, index) => {
                        return (
                          <Col className="pr-md-1" md="3" key={index}>
                            <FormGroup>
                              <img
                                src={
                                  image.path !== ""
                                    ? `${URL_API}${image.path}`
                                    : noImage
                                }
                                alt="תמונה משנית"
                                style={{
                                  height: "8cm",
                                  width: "13cm",
                                  paddingLeft: "10px",
                                  objectFit: "cover",
                                }}
                              />
                            </FormGroup>
                          </Col>
                        );
                      })}
                    </Row>
                  )}

                  <br />
                  {showSecondary && (
                    <Row>
                      <Col className="pr-md-1" md="12">
                        <FormGroup>
                          <label>ניתן להעלות קבצים מסוג: png, jpg, jpeg</label>
                          <br />
                          <label>גודל כל קובץ עד 5MB</label>
                          <h5>
                            התמונות ישמרו ויוצגו לפי הסדר הנ"ל מימין לשמאל
                          </h5>
                        </FormGroup>
                      </Col>
                      {imageList.map((val, i) => {
                        return (
                          <Col className="pr-md-1" md="3" key={i}>
                            <FormGroup>
                              <StyledDropzone
                                handleFileSave={handleChangeSecondary}
                                handleRemoveFile={handleDeleteSecondary}
                              />
                            </FormGroup>
                          </Col>
                        );
                      })}
                    </Row>
                  )}
                </Form>
                <br />
                <h4>
                  ** שימו לב! עדכון כל אחד מהשדות ידרוס את הערך הקיים במערכת!
                </h4>
              </CardBody>
              <CardFooter>
                <Row>
                  {article.active ? (
                    <Col className="pr-md-1" md="4">
                      <Button
                        onClick={handleDeactivate}
                        className="btn-fill mx-1"
                        color="primary"
                        type="submit"
                        style={{ width: "7cm" }}
                      >
                        ביטול מאמר
                      </Button>
                    </Col>
                  ) : (
                    <Col className="pr-md-1" md="4">
                      <Button
                        onClick={handleActivate}
                        className="btn-fill mx-1"
                        color="primary"
                        type="submit"
                        style={{ width: "7cm" }}
                      >
                        הפעלת מאמר
                      </Button>
                    </Col>
                  )}
                  <Col className="pr-md-1 d-flex justify-content-end" md="8">
                    <Button
                      onClick={handleUpdate}
                      className="btn-fill mx-1"
                      color="primary"
                      type="submit"
                      style={{ width: "7cm" }}
                    >
                      עדכון מאמר
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  articleCategories: state.shared.articleCategories,

  singleArticle: state.articles.singleArticle,
  active: state.articles.active,
  sendingRequest: state.articles.sendingRequest,
  deleteGallery: state.articles.deleteGallery,
  deleteVideo: state.articles.deleteVideo,
  plantsList: state.shared.plantsList,
  updatedArticle: state.articles.updatedArticle,
  failed: state.articles.failed,
});

export default connect(mapStateToProps, {
  getArticleDetails,
  getCategories,
  deactivateArticle,
  activateArticle,
  updateArticle,
  deleteGallery,
  deleteVideo,
  getPlants,
})(ArticleDetails);
